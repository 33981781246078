<template>
  <section class="backend-asid-single">
    <b-notification
      v-if="codeNotFound && !isLoading"
      type="is-danger"
      aria-close-label="Close notification"
      :closable="false"
      role="alert"
    >
      Code
      <b>{{ $props.asid }}</b> could not be found. Make sure the code is valid and not assigned to other User already.
      <router-link class="button" :to="{ name: 'activate-asid' }">Scan again</router-link>
    </b-notification>

    <b-modal
      v-if="isInitialLoaded"
      full-screen
      :active="selectedWorkflow !== null"
      :width="640"
      scroll="keep"
      has-modal-card
      trap-focus
      class="workflow-wrapper"
      :can-cancel="['escape', 'outside']"
      @close="selectedWorkflow = null"
    >
      <div v-if="selectedWorkflowWithPages" class="selected-workflow modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Workflow: {{ selectedWorkflowWithPages.title }}</p>
          <button class="delete" aria-label="close" @click="selectedWorkflow = null" />
        </header>
        <section class="modal-card-body">
          <!-- display all steps of the current page -->
          <template v-if="currentPageIndex !== -1">
            <template v-for="(step, stepIndex) in selectedWorkflowWithPages.pages[currentPageIndex]">
              <div :key="stepIndex + 'step'" class="step box">
                <label class="step-title label">{{ step.title }}</label>
                <div v-if="step.description" class="step-description">
                  <VEditorHtml :html="step.description" />
                </div>
                <VFormDataEntry
                  v-if="step.action.type === 'workflowStepType_setIdentifier'"
                  ref="validate-1"
                  :data-value-object.sync="asidDB.identifierValue"
                  :data-definition-object="$backendConfig.asid.identifierDefinition"
                  :data-definition-keys="[step.action.value]"
                  :upload-path="uploadPath"
                  :document-path="docPath"
                  :hide-box="true"
                  label-on-border
                />
                <VFormDataEntry
                  v-else-if="step.action.type === 'workflowStepType_setAttribute'"
                  ref="validate-2"
                  :data-value-object.sync="asidDB.assetAttributeValue"
                  :data-definition-object="$backendConfig.asid.assetAttributeDefinitions"
                  :data-definition-keys="[step.action.value]"
                  :upload-path="uploadPath"
                  :document-path="docPath"
                  :hide-box="true"
                  label-on-border
                />
                <VInputMultiCategoryEntry
                  v-else-if="step.action.type === 'workflowStepType_setCategory'"
                  ref="validate-category-1"
                  v-model="asidDB.categoryIDs"
                  :data-definition-keys="[step.action.value]"
                  :category-entry-definitions="$backendConfig.asid.categoryDefinitions"
                  label-on-border
                />
              </div>
            </template>
          </template>

          <!-- show workflow start page -->
          <template v-else>
            <div class="workflow-start">
              <div class="label workflow-title">{{ selectedWorkflowWithPages.title }}</div>
              <div class="workflow-description">
                <VEditorHtml :html="selectedWorkflowWithPages.description" />
              </div>
            </div>
          </template>

        </section>
        <!-- navigation buttons / next/prev page -->
        <footer class="workflow-navigation modal-card-foot">
          <b-button
            v-shortkey="['arrowleft']"
            type="is-primary"
            :disabled="currentPageIndex === -1"
            @click="currentPageIndex = currentPageIndex - 1"
            @shortkey="currentPageIndex = currentPageIndex > - 1 ? currentPageIndex - 1 : currentPageIndex"
          >Previous Page</b-button>

          <div class="page-errors">
            <b-field class="step-count">
              <b-tag
                v-if="selectedWorkflowWithPages.statusPerPage[currentPageIndex]?.errorStepCount > 0"
                type="is-danger"
                icon="exclamation-triangle"
              >{{ selectedWorkflowWithPages.statusPerPage[currentPageIndex].errorStepCount }}/{{ selectedWorkflowWithPages.statusPerPage[currentPageIndex].totalSteps }} invalid</b-tag>
              <b-tag
                v-if="selectedWorkflowWithPages.statusPerPage[currentPageIndex]?.warningStepCount > 0"
                type="is-warning"
                icon="exclamation-triangle"
              >{{ selectedWorkflowWithPages.statusPerPage[currentPageIndex].warningStepCount }}/{{ selectedWorkflowWithPages.statusPerPage[currentPageIndex].totalSteps }} warnings</b-tag>
              <!-- all ok -->
              <b-tag
                v-if="selectedWorkflowWithPages.statusPerPage[currentPageIndex]?.errorStepCount === 0 && selectedWorkflowWithPages.statusPerPage[currentPageIndex]?.warningStepCount === 0"
                type="is-success"
                icon="check"
              >All {{ selectedWorkflowWithPages.statusPerPage[currentPageIndex].totalSteps }} valid</b-tag>
            </b-field>
          </div>

          <b-button
            v-if="currentPageIndex === -1"
            v-shortkey="['arrowright']"
            type="is-primary"
            @click="currentPageIndex = currentPageIndex + 1"
            @shortkey="currentPageIndex = currentPageIndex + 1"
          >Next Page</b-button>
          <b-button
            v-else
            v-shortkey="['arrowright']"
            type="is-success"
            @click="advancePageAndSave()"
            @shortkey="advancePageAndSave()"
          >{{ currentPageIndex === selectedWorkflowWithPages.pages.length - 1 ? 'Save' : 'Save & Next Page' }}</b-button>
        </footer>
      </div>
    </b-modal>

    <b-tabs v-if="!isLoading" v-model="tabHashMixin_activeTab" destroy-on-hide>
      <b-tab-item class="asid-assignment-tab">
        <template slot="header">
          <span>Assignment</span>
        </template>

        <section class="right-items">
          <VRecordMeta
            class="is-pulled-right"
            position="is-bottom-left"
            :document-path="docPath"
            :record-meta="asidDB._meta"
            :required-privileges="documentPrivileges"
          />

          <VButtonSettingsModal :settings="settings" :config="settingsConfig" @save="saveSettings" />
        </section>

        <VScanInterface v-if="settings.activateScanInterface" :cmd-callbacks="cmdCallbacks" />

        <div class="columns asid-page-wrapper">
          <div class="column">

            <div
              v-if="activationWorkflows.length > 0"
              class="workflow-wrapper"
            >
              <b-field>
                <template slot="label">
                  Workflows
                  <VTooltipIconHelp
                    text="Workflows are a sequence of steps that need to be completed to activate an ECHO Code. You can define multiple workflows for different use cases."
                  />
                </template>
              </b-field>


              <!-- b-collapse with the available workflows -->
              <b-collapse
                v-for="(workflow, index) in activationWorkflows"
                :key="index+'workflow'"
                class="card"
                animation="slide"
                :open="isWorkflowOpen == index"
                :aria-id="'contentIdForA11y5-' + index"
                @open="isWorkflowOpen = index">
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5-' + index"
                    :aria-expanded="props.open"
                  >
                    <p class="card-header-title">
                      {{ workflow.title }}
                    </p>

                    <!-- status -->
                    <b-field class="step-count">
                      <b-tag
                        v-if="workflow.status.errorStepCount > 0"
                        type="is-danger"
                      >{{ workflow.status.errorStepCount }}/{{ workflow.status.totalSteps }} invalid</b-tag>
                      <b-tag
                        v-if="workflow.status.warningStepCount > 0"
                        type="is-warning"
                      >{{ workflow.status.warningStepCount }}/{{ workflow.status.totalSteps }} warnings</b-tag>

                      <!-- all ok -->
                      <!-- <b-tag
                        v-if="workflow.status.errorStepCount === 0 && workflow.status.warningStepCount === 0"
                        type="is-success"
                      >All {{ workflow.status.totalSteps }} valid</b-tag> -->
                    </b-field>


                    <!-- button to select workflow as the active one -->
                    <b-button
                      size="is-small"
                      icon-right="play"
                      style="margin: 0.6rem;"
                      @click="onClickStartWorkflow(workflow)"
                    >Start Workflow</b-button>

                    <a class="card-header-icon">
                      <b-icon
                        :icon="props.open ? 'chevron-up' : 'chevron-down'"
                        size="is-small"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </template>
                <div class="card-content">
                  <div class="content">

                    <div v-if="!hideTitleAndDescription" class="workflow-description">
                      <VEditorHtml :html="workflow.description" />
                    </div>

                    <!-- attributes, identifier, categorie input fields for each step -->
                    <b-field v-if="!hideTitleAndDescription">
                      <template slot="label">
                        Steps
                        <VTooltipIconHelp
                          text="Each step in the workflow. You can define multiple steps for different use cases."
                        />
                      </template>
                    </b-field>

                    <template v-for="(step, stepIndex) in workflow.steps">
                      <div :key="stepIndex + 'step'" :class="{'step': true, 'box': !hideTitleAndDescription}">

                        <label
                          v-if="!hideTitleAndDescription"
                          class="step-title label"
                        >{{ step.title }}</label>
                        <div v-if="step.description && !hideTitleAndDescription" class="step-description">
                          <VEditorHtml :html="step.description" />
                        </div>
                        <VFormDataEntry
                          v-if="step.action.type === 'workflowStepType_setIdentifier'"
                          ref="validate-3"
                          :data-value-object.sync="asidDB.identifierValue"
                          :data-definition-object="$backendConfig.asid.identifierDefinition"
                          :data-definition-keys="[step.action.value]"
                          :upload-path="uploadPath"
                          :document-path="docPath"
                          :hide-box="true"
                          label-on-border
                        />
                        <VFormDataEntry
                          v-else-if="step.action.type === 'workflowStepType_setAttribute'"
                          ref="validate-4"
                          :data-value-object.sync="asidDB.assetAttributeValue"
                          :data-definition-object="$backendConfig.asid.assetAttributeDefinitions"
                          :data-definition-keys="[step.action.value]"
                          :upload-path="uploadPath"
                          :document-path="docPath"
                          :hide-box="true"
                          label-on-border
                        />
                        <VInputMultiCategoryEntry
                          v-else-if="step.action.type === 'workflowStepType_setCategory'"
                          ref="validate-category-2"
                          v-model="asidDB.categoryIDs"
                          :data-definition-keys="[step.action.value]"
                          :category-entry-definitions="$backendConfig.asid.categoryDefinitions"
                          label-on-border
                        />
                      </div>
                    </template>

                  </div>
                </div>
              </b-collapse>
            </div>

            <b-field label="Categories">
              <!-- <VInputMultiCategorySelection
                v-model="asidDB.categoryIDs"
                :categories-doc="categoryCollection"
                hide-root
              />-->

              <VInputMultiCategoryEntry
                ref="validate-category-3"
                v-model="asidDB.categoryIDs"
                class="box"
                :category-entry-definitions="$backendConfig.asid.categoryDefinitions"
              />
            </b-field>
            <br />

            <!-- <b-field label="Identifier">
        <b-input
          v-model="asidDB.identifierValue"
          :autofocus="settings.persistCategory"
          placeholder="Identifier"
          required
        />
            </b-field>-->
            <b-field>
              <template slot="label">
                Identifiers
                <VTooltipIconHelp
                  text="Identifiers are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
                />
              </template>
            </b-field>
            <VFormDataEntry
              ref="validate-5"
              :data-value-object.sync="asidDB.identifierValue"
              :data-definition-object="$backendConfig.asid.identifierDefinition"
              :upload-path="uploadPath"
              :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
              :document-path="docPath"
              :categories="asidDB.categoryIDs"
              do-set-default-values
            />

            <template v-if="showAttributes">
              <b-field>
                <template slot="label">
                  Attributes
                  <VTooltipIconHelp
                    text="Attributes are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
                  />
                </template>
              </b-field>
              <VFormDataEntry
                ref="validate-6"
                :data-value-object.sync="asidDB.assetAttributeValue"
                :data-definition-object="$backendConfig.asid.assetAttributeDefinitions"
                :upload-path="uploadPath"
                :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
                :document-path="docPath"
                :categories="asidDB.categoryIDs"
                do-set-default-values
              />
            </template>

            <section v-for="dataDefinition,i in dataModuleGroups" :key="i">
              <template v-if="dataElements.find(da=>da.public.groupID === dataDefinition.id)">
                <b-field>
                  <template slot="label">
                    {{ dataDefinition.name }}
                    <VTooltipIconHelp
                      text="These Data Module variables might apply to multiple ECHO Codes. You can change them in the Data Module."
                    />
                    <b-button
                      tag="router-link"
                      :to="{ name: 'module-data-single', params: { id: dataElements.find(da => da.public.groupID === dataDefinition.id)?.id || '' } }"
                      type="is-text"
                      size="is-small"
                      icon-right="external-link-alt"
                    >open Data Element</b-button>
                  </template>
                </b-field>

                <VFormDataEntry
                  disabled
                  :data-value-object="dataElements.find(da=>da.public.groupID === dataDefinition.id)?.data"
                  :data-definition-object="dataDefinition.dataDefinition"
                  :upload-path="uploadPath"
                  :document-path="docPath"
                  title="those values might apply to multiple ECHO Codes. Go to the Data module to change them."
                />
              </template>
            </section>
          </div>

          <div class="column is-narrow echo-sticker-preview-container">
            <VEchoCode
              class="echo-sticker-preview"
              :code-config="asidDB.codeConfig"
              :attribute="asidDB.assetAttributeValue"
              :asid="asidDB.id"
              :base-url="$backendConfig.asid.baseUrl"
              :identifier="asidDB.identifierValue"
              :data="dataByDefinitionKeys"
              :asid-categories="asidDB.categoryIDs"
              :category-collection="categoryCollection"
            />
            <b-tag type="is-dark" class="echo-id">
              <span style="font-family: monospace;">{{ asidDB.id }}</span>
            </b-tag>
          </div>
        </div>

        <hr />

        <section
          v-if="$auth.userHasAllPrivilege([$auth.dbPrivileges.CATEGORIES_READ, $auth.privileges.CODE_LIST_VIEW])"
          class="module-elements-container"
        >
          <h5 class="title is-5">
            Elements
            <b-button
              type="is-text"
              size="is-small"
              @click="$localSettings.asidShow.expandElements=!$localSettings.asidShow.expandElements"
            >{{ !$localSettings.asidShow.expandElements?'show':'hide' }} Module Elements</b-button>
          </h5>
          <div v-if="$localSettings.asidShow.expandElements" class="module-menus-container columns">
            <div v-for="moduleType in activeModuleTypes" :key="moduleType" class="column">
              <VModuleMenu
                :new-element-asid-presets="(Object.keys(asidDB.identifierValue).length>0)?[]:[$props.asid]"
                :new-element-identifier-presets="asidDB.identifierValue"
                :module-type="moduleType"
                :label="getModuleNameByType(moduleType)"
                description=" "
                add-widget-text
                filter-elements
                :filter-by-elements="moduleElements.filter(me => me.type === moduleType).filter(me => me.publishingState === 'published').map(me => me.id)"
              />
            </div>
          </div>
        </section>
        <VCrudControl
          hide-remove
          :save-button-text="asidDB.activated ? 'Update' : asid === 'any' ? 'Activate New ECHO Code' : 'Activate'"
          @cancel="onCancel"
          @save="onSave"
        >
          <b-button
            v-if="isActivateAndNext"
            type="is-success"
            @click="onSaveAndNext"
          >{{ asidDB.activated ? 'Update' : 'Activate' }} & Next</b-button>
        </VCrudControl>
      </b-tab-item>

      <b-tab-item
        v-if="$auth.userHasPrivilege($auth.privileges.CODE_LIST_VIEW) && asidDB.activated"
      >
        <template slot="header">
          <span>
            Responses
            <b-tag rounded>{{ totalResponseCount }}</b-tag>
          </span>
        </template>

        <h5 class="title is-5">Responses</h5>
        <b-field label="Filter">
          <b-field grouped>
            <p class="control">
              <VFilterModulesDropdownView
                v-model="$localSettings.asidShow.filters.responseModules"
              />
            </p>

            <b-field>
              <b-switch
                v-model="$localSettings.asidShow.filters.hideVisits"
                :rounded="false"
              >Hide Visits</b-switch>
            </b-field>
          </b-field>
        </b-field>
        <section class="responses">
          <VResponsesTimelineView
            :asid="asid"
            :responses-form="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Form')) ? alignedResponsesForm: []"
            :responses-protection="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Protection')) ? alignedResponsesProtection: []"
            :responses-file="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('File')) ? alignedResponsesFile: []"
            :responses-i18n=" $localSettings.asidShow.filters.hideVisits ? [] : ($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('I18n')) ? alignedResponsesI18n: []"
            :responses-custom="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Custom')) ? alignedResponsesCustom: []"
            :responses-script="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Script')) ? alignedResponsesScript: []"
            :sessions="($localSettings.asidShow.filters.hideVisits) ? [] : alignedSessions"
            :module-elements-with-type="moduleElements"
          />

          <!-- show load more button if any response count is equal to the limit -->
          <div class="has-text-centered">
            <b-button
              v-if="alignedResponsesForm.length === responseLimit
                || alignedResponsesProtection.length === responseLimit
                || alignedResponsesFile.length === responseLimit
                || alignedResponsesCustom.length === responseLimit
                || alignedResponsesScript.length === responseLimit
                || alignedSessions.length === responseLimit
                || alignedResponsesI18n.length === responseLimit"
              class="load-more-button"
              @click="loadMoreResponses"
            >Load more</b-button>
          </div>

          <VCrudControl hide-remove hide-cancel is-autosave />
        </section>

        <b-loading :active="responsesLoading" />
      </b-tab-item>

      <b-tab-item
        v-if="$auth.userHasPrivilege($auth.privileges.CODE_LIST_VIEW) && asidDB.activated"
      >
        <template slot="header">
          <span>
            Visits
            <b-tag rounded>{{ asidDB._computed.pageviews }}</b-tag>
          </span>
        </template>

        <VueApexCharts
          v-if="!isLoading"
          height="300"
          :options="timeSeriesAppSessionsChartOptions"
          :series="timeSeriesAppSessions"
        />
      </b-tab-item>

      <!-- if the user ha the incidents view privilege, show a tab with open and active incidents -->
      <b-tab-item v-if="$auth.userHasAllPrivilege(servicePrivileges) && asidDB.activated">
        <template slot="header">
          <span @click="onClickGotoIncidents">
            Tickets
            <b-taglist attached style="display: inline-block;">
              <b-tag
                v-if="newIncidentCount > 0"
                title="new ticket count"
                rounded
                type="is-twitter"
              >new: {{ newIncidentCount }}</b-tag>
              <b-tag
                v-if="openIncidentsCount > 0"
                rounded
                type="is-success"
                title="open ticket count"
              >open: {{ openIncidentsCount }}</b-tag>
              <b-tag
                v-if="totalIncidentsCount > 0"
                title="total ticket count"
                rounded
              >total: {{ totalIncidentsCount }}</b-tag>
            </b-taglist>
          </span>
        </template>
      </b-tab-item>
    </b-tabs>

    <!-- </b-tab-item>
    <b-tab-item label="settings" icon="cog">-->
    <b-loading :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'


import { CategoryCollection, CategoryID } from '@/types/typeCategory'

import { assetAttributeKeyedObject, AsidDB, IdentifierKeyedObject, isIdentifierKey, isAssetAttributeKey } from '@/types/typeAsid'
import VueApexCharts from 'vue-apexcharts'
import AsidManager from '@/database/asidManager'
import { DataDefinition, isDataDefinitionKey } from '@/types/typeDataDefinition'
import databaseSchema from '@/database/databaseSchema'
import { merge, typedWhere, typedOrderBy, arrayGroupBy } from '@/database/dbHelper'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VButtonSettingsModal from '@/components/VButtonSettingsModal.vue'
import VResponsesTimelineView from '@/components/VResponsesTimelineView.vue'
import VInputMultiModuleSelection from '@/components/VInputMultiModuleSelection.vue'
import { SettingsAsidActivate, SettingsConfig } from '@/types/typeLocalSettings'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VEchoCode from '@/components/VEchoCode.vue'
import { FormResponseDB } from '@/modules/form/typeFormModule'
import FormModule from '@/modules/form/formModule'

import { CustomResponseDB } from '@/modules/custom/typeCustomModule'
import { FileResponseDB } from '@/modules/file/typeFileModule'
import FileModule from '@/modules/file/fileModule'
import CustomModule from '@/modules/custom/customModule'
import { ElementWithTypeAndID, ModuleType } from '@/modules/typeModules'
import { ModuleManager } from '@/modules/moduleManager'
import { ScriptResponseDB } from '@/modules/script/typeScriptModule'
import ScriptModule from '@/modules/script/scriptModule'

import VModuleMenu from './../../modules/VModuleMenu.vue'
import { ProtectionResponseDB } from '@/modules/protection/typeProtectionModule'
import ProtectionModule from '@/modules/protection/protectionModule'
import { hasDBid } from '@/types/typeGeneral'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { SessionDB } from '@/types/typeAppSession'
import SessionManager from '@/database/sessionManager'
import VFilterModulesDropdownView from '@/components/VFilterModulesDropdownView.vue'
import { intersectSome } from '@/helpers/arrayHelper'
import { AppPreview } from '@/components/VBackendAppPreview.vue'
import moment from 'dayjs'
import { getStatisticsPerDay } from '@/helpers/statisticsHelper'
import VFormDataEntry from '@/components/VFormDataEntry.vue'
import { UPLOAD_PATHS } from '@/helpers/storageHelper'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import I18nModule from '@/modules/i18n/i18nModule'
import { I18nResponseDB } from '@/modules/i18n/typeI18nModule'
import { mixins } from 'vue-class-component'
import VTabHashMixin from '@/components/mixins/VTabHashMixin.vue'
import ServiceModule from '@/modules/service/serviceModule'
import { ScanInterfaceCmd, ScanInterfaceCmdCallback } from '@/helpers/scanInterface'
import VScanInterface from '@/components/VScanInterface.vue'
import VInputMultiCategoryEntry from '@/components/VInputMultiCategoryEntry.vue'
import CategoryHelper from '@/database/categoryHelper'
import { convertToNullOrString } from '@/components/VImportExport.vue'
import { CategoryEntryDefinitionObject, Workflow, WorkflowStep } from '@/types/typeBackendConfig'
import BackendConfigManager from '@/database/backendConfigManager'

library.add(faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt)

// groups all steps with 'showWithPreviousStep' set to same page
interface PaginatedWorkflowSteps {
  pages: WorkflowStep[][]
  statusPerPage: {
    totalSteps: number
    validStepCount: number
    errorStepCount: number
    warningStepCount: number
  }[]
}

@Component({
  components: {
    VInputMultiCategorySelection,
    VTooltipIconHelp,
    VRecordMeta,
    VButtonSettingsModal,
    VResponsesTimelineView,
    VModuleMenu,
    VEchoCode,
    VFilterModulesDropdownView,
    VInputMultiModuleSelection,
    VueApexCharts,
    VFormDataEntry,
    VScanInterface,
    VInputMultiCategoryEntry
  }
})
export default class BackendAsidSingle extends mixins(VTabHashMixin, VCustomVueFireBindMixin) {
  // #region Workflow
  public isWorkflowOpen = -1

  public hideTitleAndDescription = true

  public onClickStartWorkflow(workflow: Workflow) {
    this.selectedWorkflow = workflow
    this.currentPageIndex = workflow.description ? -1 : 0
  }

  private computeStatusForSteps(steps: WorkflowStep[]) {
    // gather all identifier definitions that are used in the workflow
    const identifierKeys = steps
      .filter((step) => step.action.type === 'workflowStepType_setIdentifier')
      .map((step) => step.action.value)

    const identifierDefinitions = BackendConfigManager.getDataDefinitionsFromObject(this.$backendConfig.asid.identifierDefinition).filter((idDef) => identifierKeys.includes(idDef.__identifierKey__))
    const [, validationMessages] = BackendConfigManager.validateDataDefinitionInput(this.asidDB.identifierValue, identifierDefinitions)

    // gather all asset attribute definitions that are used in the workflow
    const assetAttributeKeys = steps
      .filter((step) => step.action.type === 'workflowStepType_setAttribute')
      .map((step) => step.action.value)

    const assetAttributeDefinitions = BackendConfigManager.getDataDefinitionsFromObject(this.$backendConfig.asid.assetAttributeDefinitions).filter((attrDef) => assetAttributeKeys.includes(attrDef.__identifierKey__))
    const [, validationMessagesAssetAttributes] = BackendConfigManager.validateDataDefinitionInput(this.asidDB.assetAttributeValue, assetAttributeDefinitions)


    // gather all categories that are used in the workflow
    const categoryEntryKeys = steps
      .filter((step) => step.action.type === 'workflowStepType_setCategory')
      .map((step) => step.action.value)

    const filteredCategoryEntryDefinitions = Object.fromEntries(Object.entries(this.$backendConfig.asid.categoryDefinitions).filter(([key]) => categoryEntryKeys.includes(key)))
    const [, validationMessagesCategories] = CategoryHelper.validateCategoryEntry(this.$categories, filteredCategoryEntryDefinitions, this.asidDB.categoryIDs)

    const errorStepCount = Object.values(validationMessages).filter((messages) => messages.severity === 'error').length
      + Object.values(validationMessagesAssetAttributes).filter((messages) => messages.severity === 'error').length
      + Object.values(validationMessagesCategories).filter((messages) => messages.severity === 'error').length
    const warningStepCount = Object.values(validationMessages).filter((messages) => messages.severity === 'warning').length
      + Object.values(validationMessagesAssetAttributes).filter((messages) => messages.severity === 'warning').length
      + Object.values(validationMessagesCategories).filter((messages) => messages.severity === 'warning').length
    const validStepCount = steps.length - errorStepCount

    return {
      totalSteps: steps.length,
      validStepCount,
      errorStepCount,
      warningStepCount
    }
  }

  /**
 * 1. sort workflows by order
 * 2. sort steps by order
 * 3. filter the workflows by categories
 * 4. filter the linked identifiers and attributes by category
 * 5. compute the validation result per workflow e.g. 3/12 steps valid
 * 6. compute how many fields are filles per workflow e.g. 5/12 fields filled
 */
  public get activationWorkflows() {
    /**
     * Check if the data entry should be shown based on the categories of the asiDB
     */
    const showDataEntryBasedOnCategories = (categoryIDs: string[]) => {
      if (categoryIDs.length === 0) return true // if no categories are set, show the data entry
      if (categoryIDs.includes('ALL_CATEGORIES')) return true // if the data entry is in the ALL_CATEGORIES category, show it
      return CategoryHelper.isElementActiveForAsidRef(categoryIDs, this.asidDB.categoryIDs, this.$categories)
    }

    // return data sorted by order and by step order
    return this.$backendConfig.activation.workflows
      .sort((a, b) => a.order - b.order)
      .filter((workflow) => {
        return showDataEntryBasedOnCategories(workflow.categories)
      })
      .map((workflow) => {
        const steps = workflow.steps
          .sort((a, b) => a.order - b.order)
          // filter out invalid steps without value
          .filter((step) => step.action.value !== '')
          .filter((step) => {
            const categoriesOfReferencesAttributeOrIdentifier = step.action.type === 'workflowStepType_setIdentifier'
              ? this.$backendConfig.asid.identifierDefinition[step.action.value as isIdentifierKey].categories
              : step.action.type === 'workflowStepType_setAttribute'
                ? this.$backendConfig.asid.assetAttributeDefinitions[step.action.value as isAssetAttributeKey].categories
                : []

            console.log('categoriesOfReferencesAttributeOrIdentifier', categoriesOfReferencesAttributeOrIdentifier)

            return showDataEntryBasedOnCategories(categoriesOfReferencesAttributeOrIdentifier)
          })

        const { validStepCount, errorStepCount, warningStepCount } = this.computeStatusForSteps(steps)

        return {
          ...workflow,
          status: {
            totalSteps: steps.length,
            validStepCount,
            errorStepCount,
            warningStepCount
          },
          steps
        }
      })
      // if the workflow has no steps, do not show it
      .filter((workflow) => workflow.steps.length > 0)
  }


  /**
  public activationWorkflows_db: Workflow[] = [
    {
      title: 'Default',
      description: 'Default workflow',
      order: 0,
      categories: [],
      steps: [
        {
          title: 'Set Identifier i1',
          description: 'Set Identiier',
          showWithPreviousStep: false,
          order: 0,
          stepId: 0,
          action: {
            type: 'workflowStepType_setIdentifier',
            // mandatory: true,
            value: 'i1'
          }
        },
        {
          title: 'Set Attribute A3',
          description: 'Set Identiier',
          showWithPreviousStep: true,
          order: 0,
          stepId: 0,
          action: {
            type: 'workflowStepType_setAttribute',
            // mandatory: true,
            value: 'a3'
          }
        },
        {
          title: 'Set Identifier i4',
          description: 'Set Identiier',
          showWithPreviousStep: false,
          order: 0,
          stepId: 0,
          action: {
            type: 'workflowStepType_setIdentifier',
            // mandatory: true,
            value: 'i4'
          }
        }
      ]
    },
    {
      title: 'Sample Workflow 2 Title',
      description: 'Sample Workflow 2 Description',
      order: 1,
      categories: [],
      steps: [
        {
          title: 'Step 1',
          description: 'Step 1',
          showWithPreviousStep: false,
          order: 0,
          stepId: 1,
          action: {
            type: 'workflowStepType_setCategory',
            // mandatory: true,
            value: ''
          }
        },
        {
          title: 'Step 2',
          description: 'Step 2',
          showWithPreviousStep: false,
          order: 1,
          stepId: 2,
          action: {
            type: 'workflowStepType_setCategory',
            // mandatory: true,
            value: ''
          }
        }
      ]
    }
  ]
   */

  public activationWorkflows_db: Workflow[] = [
    // set category workflow
    {
      title: 'Set Category',
      description: 'Workflow for setting the category',
      categories: [],
      order: 0,
      steps: [
        {
          title: 'Set Category',
          description: 'Set Category <br><img src="https://placehold.co/600x400" alt="Sample Image"><br><p>This is a sample HTML description.</p>',
          showWithPreviousStep: false,
          order: 0,
          stepId: 0,
          action: {
            type: 'workflowStepType_setCategory',
            value: 'e1'
          }
        },
        {
          title: 'Set Some more Category',
          description: 'Set a maximum of 2 categories',
          showWithPreviousStep: true,
          order: 1,
          stepId: 1,
          action: {
            type: 'workflowStepType_setCategory',
            value: 'e2'
          }
        }
      ]
    },
    {
      title: 'ENTHALTENE KOMPONENTEN',
      description: 'Workflow for ENTHALTENE KOMPONENTEN',
      categories: [],
      order: 1,
      steps: [
        {
          title: 'ECU (5040.0003)',
          description: 'Processing ECU (5040.0003)',
          showWithPreviousStep: false,
          order: 2,
          stepId: 1000,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a2'
          }
        },
        {
          title: 'Compressor (6050.0002)',
          description: 'Processing Compressor (6050.0002)',
          showWithPreviousStep: true,
          order: 3,
          stepId: 1001,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a3'
          }
        },
        {
          title: 'Compressor (6050.0005)',
          description: 'Processing Compressor (6050.0005)',
          showWithPreviousStep: true,
          order: 4,
          stepId: 1002,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a4'
          }
        },
        {
          title: 'Compressor Battery (6050.0005)',
          description: 'Processing Compressor Battery (6050.0005)',
          showWithPreviousStep: true,
          order: 5,
          stepId: 1003,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a5'
          }
        }
      ]
    },
    {
      title: 'DICHTIGKEIT TESTS',
      description: 'Workflow for DICHTIGKEIT TESTS',
      categories: [],
      order: 2,
      steps: [
        {
          title: 'AC Druck Start-Test (bar)',
          description: 'Processing AC Druck Start-Test (bar)',
          showWithPreviousStep: false,
          order: 27,
          stepId: 1004,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a27'
          }
        },
        {
          title: 'AC Druck Ende-Test (bar)',
          description: 'Processing AC Druck Ende-Test (bar)',
          showWithPreviousStep: true,
          order: 28,
          stepId: 1005,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a28'
          }
        },
        {
          title: 'KM BL Druck Start-Test (bar)',
          description: 'Processing KM BL Druck Start-Test (bar)',
          showWithPreviousStep: true,
          order: 36,
          stepId: 1006,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a36'
          }
        },
        {
          title: 'KM BL Druck Ende-Test (bar)',
          description: 'Processing KM BL Druck Ende-Test (bar)',
          showWithPreviousStep: true,
          order: 37,
          stepId: 1007,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a37'
          }
        }
      ]
    },
    {
      title: 'COOLING SYSTEM TESTS',
      description: 'Workflow for COOLING SYSTEM TESTS',
      categories: [],
      order: 3,
      steps: [
        {
          title: 'Kühlkreislauf Batterie Dichtigkeit geprüft und OK',
          description: 'Processing Kühlkreislauf Batterie Dichtigkeit geprüft und OK',
          showWithPreviousStep: false,
          order: 38,
          stepId: 1008,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a38'
          }
        },
        {
          title: 'Heizkreislauf Dichtigkeit geprüft und OK',
          description: 'Processing Heizkreislauf Dichtigkeit geprüft und OK',
          showWithPreviousStep: false,
          order: 41,
          stepId: 1009,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a41'
          }
        }
      ]
    },
    {
      title: 'SOFTWARE',
      description: 'Workflow for SOFTWARE',
      categories: [],
      order: 4,
      steps: [
        {
          title: 'Software Revision',
          description: 'Processing Software Revision',
          showWithPreviousStep: false,
          order: 44,
          stepId: 1010,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a44'
          }
        },
        {
          title: 'Config Revision',
          description: 'Processing Config Revision',
          showWithPreviousStep: false,
          order: 45,
          stepId: 1011,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a45'
          }
        }
      ]
    },
    {
      title: 'HV TESTS',
      description: 'Workflow for HV TESTS',
      categories: [],
      order: 5,
      steps: [
        {
          title: 'ECU flashed',
          description: 'Processing ECU flashed',
          showWithPreviousStep: false,
          order: 46,
          stepId: 1012,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a46'
          }
        },
        {
          title: 'HV-TESTS',
          description: 'Processing HV-TESTS',
          showWithPreviousStep: false,
          order: 48,
          stepId: 1013,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a48'
          }
        },
        {
          title: 'Polarität Kompressor HV+/HV- ist korrekt',
          description: 'Processing Polarität Kompressor HV+/HV- ist korrekt',
          showWithPreviousStep: false,
          order: 49,
          stepId: 1014,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a49'
          }
        },
        {
          title: 'Polarität Heizer HV+/HV- ist korrekt',
          description: 'Processing Polarität Heizer HV+/HV- ist korrekt',
          showWithPreviousStep: false,
          order: 50,
          stepId: 1015,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a50'
          }
        }
      ]
    },
    {
      title: 'HVIL TESTS',
      description: 'Workflow for HVIL TESTS',
      categories: [],
      order: 6,
      steps: [
        {
          title: 'HVIL Kreis ist getestet und i.O.',
          description: 'Processing HVIL Kreis ist getestet und i.O.',
          showWithPreviousStep: false,
          order: 52,
          stepId: 1016,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a52'
          }
        },
        {
          title: 'PE Widerstand zwischen Massepunkt und ... ist <0,1Ohm',
          description: 'Processing PE Widerstand zwischen Massepunkt und ... ist <0,1Ohm',
          showWithPreviousStep: false,
          order: 53,
          stepId: 1017,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a53'
          }
        }
      ]
    },
    {
      title: 'OBERFLÄCHENTESTS',
      description: 'Workflow for OBERFLÄCHENTESTS',
      categories: [],
      order: 7,
      steps: [
        {
          title: '... Heizer Oberfläche',
          description: 'Processing Heizer Oberfläche',
          showWithPreviousStep: false,
          order: 54,
          stepId: 1018,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a54'
          }
        },
        {
          title: '... Kompressor Oberfläche',
          description: 'Processing Kompressor Oberfläche',
          showWithPreviousStep: false,
          order: 55,
          stepId: 1019,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a55'
          }
        }
      ]
    },
    {
      title: 'HV TESTS',
      description: 'Workflow for HV TESTS',
      categories: [],
      order: 8,
      steps: [
        {
          title: '... HV Test durchgeführt & OK',
          description: 'Processing HV Test durchgeführt & OK',
          showWithPreviousStep: false,
          order: 75,
          stepId: 1020,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a75'
          }
        }
      ]
    },
    {
      title: 'FUNCTIONSTEST',
      description: 'Workflow for FUNCTIONSTEST',
      categories: [],
      order: 9,
      steps: [
        {
          title: 'Functionstest durchgeführt & OK',
          description: 'Processing Functionstest durchgeführt & OK',
          showWithPreviousStep: false,
          order: 78,
          stepId: 1021,
          action: {
            type: 'workflowStepType_setAttribute',
            value: 'a78'
          }
        }
      ]
    }

  ]

  public selectedWorkflow: Workflow | null = null // todo

  public currentPageIndex = -1

  public get selectedWorkflowWithPages(): Workflow & PaginatedWorkflowSteps | null {
    if (!this.selectedWorkflow) return null

    const pages: WorkflowStep[][] = []
    let pagesAccumulator: WorkflowStep[] = []

    this.selectedWorkflow.steps.forEach((step) => {
      if (step.showWithPreviousStep) {
        pagesAccumulator.push(step)
      } else {
        if (pagesAccumulator.length > 0) {
          pages.push(pagesAccumulator)
          pagesAccumulator = []
        }
        pagesAccumulator.push(step)
      }
    })

    if (pagesAccumulator.length > 0) {
      pages.push(pagesAccumulator)
    }

    return {
      ...this.selectedWorkflow,
      pages,
      statusPerPage: pages.map((page) => this.computeStatusForSteps(page))
    }
  }

  public async advancePageAndSave() {
    if (this.hasDataChanged()) {
      const saveSuceess = await this.$save()

      if (!saveSuceess) {
        this.$helpers.notification.Error('Could not save the current page. Please check the values and try again.')
        return
      }
    }

    if (this.currentPageIndex < this.selectedWorkflowWithPages!.pages.length - 1) {
      this.currentPageIndex++
    }
  }
  // #endregion Workflow


  // used for form controls
  public asidDB: AsidDB & hasDBid = { ...AsidManager.defaultDocDB, id: '' }

  public setIdentifier(cmd: ScanInterfaceCmd) {
    let identifierKey = ''
    let value = cmd.value
    this.identifierDefinitions.forEach((idDef) => {
      if (idDef.__variableKey__ === cmd.argument || idDef.name === cmd.argument) {
        identifierKey = idDef.__variableKey__

        value = this.convertDataytype(cmd.value, idDef)
      }
    })

    if (identifierKey in this.asidDB.identifierValue) {
      this.asidDB.identifierValue[identifierKey as keyof IdentifierKeyedObject] = value
    } else {
      this.$helpers.notification.Error(`Identifier ${cmd.argument} is not a valid identifier name or id`)
    }
  }

  public setAttribute(cmd: ScanInterfaceCmd) {
    let attributeKey = ''
    let value = cmd.value
    this.assetAttributeDefinitions.forEach((attrDef) => {
      if (attrDef.__variableKey__ === cmd.argument || attrDef.name === cmd.argument) {
        attributeKey = attrDef.__variableKey__

        value = this.convertDataytype(cmd.value, attrDef)
      }
    })

    if (attributeKey in this.asidDB.assetAttributeValue) {
      this.asidDB.assetAttributeValue[attributeKey as keyof assetAttributeKeyedObject] = value
    } else {
      this.$helpers.notification.Error(`Attribute ${cmd.argument} is not a valid attribute name or id`)
    }
  }

  private convertDataytype(value: any, dataDef: DataDefinition) {
    switch (dataDef.datatype) {
      case 'number':
      case 'boolean':
      case 'string':
        // return Number(value)
        // now only strings are stored in db

        return convertToNullOrString(value)
      case 'auto_generated':
      case 'gps':
      case 'image':
        throw `Datatype ${dataDef.datatype} is not supported for setting attribute values`
      default:
        return value
    }
  }

  public cmdCallbacks: ScanInterfaceCmdCallback[] = [
    {
      name: 'set-identifier',
      fn: (cmd: ScanInterfaceCmd) => {
        // set the identifier
        console.log('executing set-identifier', cmd)
        this.setIdentifier(cmd)
      }
    },
    {
      name: 'set-attribute',
      fn: (cmd: ScanInterfaceCmd) => {
        // set the attribute
        console.log('executing set-attribute', cmd)
        this.setAttribute(cmd)
      }
    },
    {
      name: 'set-category',
      fn: (cmd: ScanInterfaceCmd) => {
        // set the category
        console.log('executing set-category', cmd)

        // value may either be the id or the name
        if (cmd.value in this.$categories) {
          this.asidDB.categoryIDs = [cmd.value]
        } else {
          // try to find the category by name
          const category = this.$categoriesListWithID.find((c) => c.name === cmd.value)
          if (category) this.asidDB.categoryIDs = [category.id]
          else throw `Category ${cmd.value} not found`
        }
      }
    },
    {
      name: 'add-category',
      fn: (cmd: ScanInterfaceCmd) => {
        // add the category
        console.log('executing add-category', cmd)

        // value may either be the id or the name
        if (cmd.value in this.$categories) {
          this.asidDB.categoryIDs = [...this.asidDB.categoryIDs, cmd.value]
        } else {
          // try to find the category by name
          const category = this.$categoriesListWithID.find((c) => c.name === cmd.value)
          if (category) this.asidDB.categoryIDs = [...this.asidDB.categoryIDs, category.id]
          else throw `Category ${cmd.value} not found`
        }
      }
    },
    {
      name: 'activate-asid',
      fn: async (cmd: ScanInterfaceCmd) => {
        // activate the asid
        console.log('executing activate-asid', cmd)

        await this.onSaveAndNext()
      }
    },
    {
      name: 'open-asid',
      fn: async (cmd: ScanInterfaceCmd) => {
        // open the asid
        console.log('executing open-asid', cmd)

        await this.$router.push({ name: 'asid-single', params: { asid: cmd.argument } })
      }
    }
  ]

  // #region tab handling
  public tabHashMixin_activeTab = 0

  protected tabHashMixin_HASH_TAB_MAP = [
    '',
    '#responses',
    '#visits'
  ]
  // #endregion tab handling

  // #region service Module incidents

  public servicePrivileges = [...ServiceModule.authPrivileges.view, ...ServiceModule.authPrivileges.r]
  public openIncidentsCount: number = 0
  public openIncidentsUnseenCount: number = 0
  public newIncidentCount: number = 0
  public totalIncidentsCount: number = 0

  public getModuleNameByType(moduleType: ModuleType) {
    return ModuleManager.getModuleClassByType(moduleType).displayName
  }

  public async onClickGotoIncidents() {
    await this.$router.push({
      name: ServiceModule.routeNameIncidentsFilter, query: {
        'public.asidID': this.asid
      }
    })
  }

  private async initIncidentCounts() {
    // if the user does not have the incidents view privilege, return
    if (!this.$auth.userHasAllPrivilege(this.servicePrivileges))
      return

    const openFilter = {
      'public.asidID': this.asid,
      'public.state': 'open'
    }
    const { totalCount, unseenCount } = await ServiceModule.getCountForFilter(openFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.openIncidentsCount = totalCount
    this.openIncidentsUnseenCount = unseenCount

    // total filter
    const totalFilter = {
      'public.asidID': this.asid
    }

    const { totalCount: allCount } = await ServiceModule.getCountForFilter(totalFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.totalIncidentsCount = allCount

    // new filter
    const newFilter = {
      'public.asidID': this.asid,
      'public.state': 'new'
    }

    const { totalCount: newCount } = await ServiceModule.getCountForFilter(newFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.newIncidentCount = newCount
  }

  // #endregion service Module incidents

  // prop asid
  @Prop({ type: String, required: false, default: () => '' }) readonly asid!: string

  @Prop({ type: String, required: false, default: () => 'false' }) readonly activateNext!: string

  get isActivateAndNext() {
    return this.activateNext === 'true'
  }

  @Watch('isActivateAndNext', { immediate: true })
  public onActivateNextChange() {
    this.firestore_isUnsavedChangesTrapActive = !this.isActivateAndNext
  }

  get uploadPath() {
    return UPLOAD_PATHS.ASID_ASH_ATTRIBUTES(this.$auth.tenantID)
  }

  private mounted() {
    console.debug('BackendAsidSingle mounted')
    this.isInitialLoaded = false
  }

  public isLoading = false
  public isInitialLoaded = false
  // public asidDB.identifierValue: any = {}
  public identifierDefinitions: (DataDefinition & { __variableKey__: keyof IdentifierKeyedObject })[] = []
  public assetAttributeDefinitions: (DataDefinition & { __variableKey__: keyof assetAttributeKeyedObject })[] = []

  // #region visit timeseries
  public get timeSeriesAppSessionsChartOptions() {
    return {
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      colors: ['#444'],
      chart: {
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true
          // autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     inverseColors: false,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 90, 100]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.debug(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        // get the first entry from the date sorted timeSeriesAppSessions
        min: moment(this.timeSeriesAppSessions?.[0]?.data?.[0]?.x || new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
        // max: moment(new Date()).add(1,'week').toDate().getTime()
        // min: moment(new Date()).startOf('month').toDate().getTime(),
        // max: moment(new Date()).endOf('month').toDate().getTime()
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  get timeSeriesAppSessions() {
    return [{
      name: 'Sessions',
      data: this.asidDB?._computed?.statistics
        && getStatisticsPerDay(this.asidDB?._computed?.statistics)
          .map((d) => ({ x: d.date, y: d.events.pv || 0 }))
          .sort((a, b) => a.x.valueOf() - b.x.valueOf()) // sort by date to easily get the first and last entry
    }]
  }

  // #endregion visit timeseries

  // #region Data Module
  public dataModuleGroups: (DataGroupDB & hasDBid)[] = []

  // moduleElement[] only the top MEs per group
  public get dataElements() {
    return arrayGroupBy(
      (this.moduleElements
        .filter((me) => me.type === 'Data')
        .filter((me) => me.publishingState === 'published')) as Array<{
          id: string
          type: ModuleType
        } & DataElementDB>,
      (d) => d.public.groupID) // {group:groupID, data: moduleElements}
      .map(({ data }) => // moduleElement[] only the top MEs per group
        data.sort((a, b) => a.public.order - b.public.order)[0]
      )
  }

  get dataByDefinitionKeys() {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}
    this.dataModuleGroups.forEach((dd) => {
      Object.entries(dd.dataDefinition).map(([key, def]) => {
        const element = this.dataElements.find((me) => me.public.groupID === dd.id)
        if (element && def.name)
          combinesDataElements[def.name] = element?.data?.[key as isDataKey]
      }
      )
    })
    return combinesDataElements
  }

  // #endregion Data Module


  public categoryCollection: CategoryCollection = this.$categories

  public codeNotFound = false


  get totalResponseCount() {
    // exclude count from service module
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { service, ...responseCountPerModule } = this.asidDB._computed.responseCountPerModule
    return Object.values(responseCountPerModule).reduce((prev, curr) => prev + curr, 0)
  }


  // #region RecordMeta
  get docPath() {
    return (this.$props.asid) ? databaseSchema.COLLECTIONS.ASID.__DOCUMENT_PATH__(this.$props.asid) : null
  }


  get documentPrivileges() {
    return merge(databaseSchema.COLLECTIONS.ASID.__PRIVILEGES__,
      { r: databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__.r, w: [] })
  }

  // #endregion RecordMeta

  get showAttributes() {
    return Object.values(this.$backendConfig.asid.assetAttributeDefinitions).some((dd) => dd.name !== '')
  }

  public async onSaveAndNext() {
    if ((await this.$save()) === true)
      await this.$router.push({ name: 'activate-asid' })
  }

  private branchCategoryCache: { [definitionKey: string]: CategoryID[] } = {}

  private async $save() {
    let successful = false

    try {
      // iterate over all refs start start with validate- and call validateInput directly or on each array element
      const dataValid = Object.entries(this.$refs).map(([key, ref]) => {
        if (key.startsWith('validate-')) {
          if (Array.isArray(ref)) {
            return ref.every((r) => (r as VFormDataEntry).validateInput())
          } else {
            return (ref as VFormDataEntry).validateInput()
          }
        }
        return true
      }).every((v) => v)

      if (!dataValid) {
        throw 'Data not valid, cant save'
      }

      // const identifierValid = Array.isArray(this.$refs['identifier-value'])
      //   ? this.$refs['identifier-value'].every((ref) => (ref as VFormDataEntry).validateInput())
      //   : (this.$refs['identifier-value'] as VFormDataEntry).validateInput()

      // const assetAttributeValid = this.showAttributes
      //   ? Array.isArray(this.$refs['asset-attribute-value'])
      //     ? this.$refs['asset-attribute-value'].every((ref) => (ref as VFormDataEntry).validateInput())
      //     : (this.$refs['asset-attribute-value'] as VFormDataEntry).validateInput()
      //   : true

      // const categoriesValid = Array.isArray(this.$refs['category-value'])
      //   ? this.$refs['category-value'].every((ref) => (ref as VInputMultiCategoryEntry).validateInput())
      //   : (this.$refs['category-value'] as VInputMultiCategoryEntry).validateInput()

      // if (!identifierValid) {
      //   throw 'identifiers not valid, cant save'
      // } else if (!assetAttributeValid) {
      //   throw 'attributes not valid, cant save'
      // } else
      // if (!categoriesValid) {
      //   throw 'categories not valid, cant save'
      // }


      const getvariableString = (dataDefinition: (DataDefinition & { __variableKey__: string })[], getDataValue: (key: isDataDefinitionKey) => any) => {
        return dataDefinition
          .filter((dd) => dd.categories.length === 0 || CategoryHelper.isElementActiveForAsidRef(dd.categories, this.asidDB.categoryIDs, this.$categories))
          .sort((a, b) => a.order - b.order || a.__variableKey__.localeCompare(b.__variableKey__))
          .reduce((str, dataDef) => {
            // if type is image, show image
            if (dataDef.datatype === 'image') {
              return `${str} <b>${dataDef.title || dataDef.name}</b>: <img src="${getDataValue(dataDef.__variableKey__)}" style="max-width: 50px; max-height: 50px;"><br>`
            } else if (dataDef.datatype === 'auto_generated' && !getDataValue(dataDef.__variableKey__)) { // if type is auto_generated and not value is present, show the pattern
              return `${str} <b>${dataDef.title || dataDef.name}</b>: ${dataDef.validator.pattern || ''}<br>`
            }
            return `${str} <b>${dataDef.title || dataDef.name}</b>: ${getDataValue(dataDef.__variableKey__) || ''}<br>`
          }, '')
      }


      const categoryIDsByEntryDefinition: { [key: string]: string[] } = {}

      for (const key in this.$backendConfig.asid.categoryDefinitions) {
      // const branchCategories = CategoryHelper.getAllChildCategoriesArray([value.validator.pivotCategory], this.$categories)
      //   ; (this.formSelectedCategories as any)[key] = this.selectedCategoryIDs.filter((catID: CategoryID) => branchCategories.includes(catID))
        categoryIDsByEntryDefinition[key] = CategoryHelper.filterCategoryIDsByEntryDefinition(
          this.$categories,
          this.$backendConfig.asid.categoryDefinitions,
          key as keyof CategoryEntryDefinitionObject,
          this.asidDB.categoryIDs,
          this.branchCategoryCache
        )
      }

      const getWorkflowString = (workflow: Workflow) => {
        const steps = workflow.steps
          .sort((a, b) => a.order - b.order)
          .map((step) => {
            if (step.action.type === 'workflowStepType_setIdentifier') {
              return getvariableString(this.identifierDefinitions.filter((idDef) => idDef.__variableKey__ === step.action.value), (key) => this.asidDB.identifierValue[key as isIdentifierKey])
            } else if (step.action.type === 'workflowStepType_setAttribute') {
              return getvariableString(this.assetAttributeDefinitions.filter((attrDef) => attrDef.__variableKey__ === step.action.value), (key) => this.asidDB.assetAttributeValue[key as isAssetAttributeKey])
            } else if (step.action.type === 'workflowStepType_setCategory') {
              return `<b>${this.$backendConfig.asid.categoryDefinitions[step.action.value as keyof CategoryEntryDefinitionObject].title}:</b> ${categoryIDsByEntryDefinition[step.action.value].map((catID) => this.categoryCollection[catID].name).join(', ')} <br>`
            }
            return ''
          })
          .join('')
        return `<h3>${workflow.title}</h3>${steps}`
      }

      const workflowStrings = this.activationWorkflows.map(getWorkflowString).join('<hr>')

      const otherIdentifiers = this.identifierDefinitions
        .filter((idDef) => !this.activationWorkflows.some((workflow) => workflow.steps.some((step) => step.action.value === idDef.__variableKey__)))
      const otherAttributes = this.assetAttributeDefinitions
        .filter((attrDef) => !this.activationWorkflows.some((workflow) => workflow.steps.some((step) => step.action.value === attrDef.__variableKey__)))

      const otherString = `<h3>Other</h3>${getvariableString(otherIdentifiers, (key) => this.asidDB.identifierValue[key as isIdentifierKey])}${getvariableString(otherAttributes, (key) => this.asidDB.assetAttributeValue[key as isAssetAttributeKey])}`

      const summaryString = workflowStrings + '<hr>' + otherString

      if ((!this.asidDB.activated && this.$backendConfig.activation.confirmActivation)
        || (this.asidDB.activated && this.$backendConfig.activation.confirmUpdate))
        await new Promise<void>((res, rej) => this.$buefy.dialog.confirm({
          title: `Confirm ${(this.asidDB.activated ? 'Update' : 'Activation')}`,
          message: `${summaryString}
<hr>
Categories: <b>${this.asidDB.categoryIDs.map((catID) => this.categoryCollection[catID].name).join('</b>, <b>')}</b>`,
          confirmText: 'Set Data',
          type: 'is-success',
          onConfirm: async () => {
            res()
          },
          onCancel: () => rej('cancel by user')
        }))

      if (this.$localSettings.asidActivate.persistCategory)
        this.$localSettings.asidActivate.persistedCategories = this.asidDB.categoryIDs

      this.isLoading = true


      if (this.asidDB.tenantID && this.asidDB.tenantID != this.$auth.tenant.id)
        throw 'This CODE is already assigned by another tenant'


      if (this.asid === 'any') {
        const asidID = await AsidManager.activateAnyASID(this.$auth.userEmail, this.$auth.tenant.id, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })

        await this.$router.push({ name: 'asid-single', params: { asid: asidID } })

        return
      } else if (!this.asidDB.tenantID) {
        await AsidManager.assignAndActivateASID(this.$props.asid, this.$auth.userEmail, this.$auth.tenant.id, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })
          .then(() => this.$helpers.notification.Success('ECHO Code activated and assigned'))
      } else if (!this.asidDB.activated) {
        await AsidManager.activateASID(this.$props.asid, this.$auth.userEmail, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })
          .then(() => this.$helpers.notification.Success('ECHO Code activated'))
      } else {
        await AsidManager.updateAsid(
          this.$props.asid,
          this.$auth.userEmail,
          this.asidDB.identifierValue,
          this.asidDB.assetAttributeValue,
          this.asidDB.categoryIDs
        )
          .then(() => this.$helpers.notification.Success('ECHO Code saved'))
          .catch((e: any) => this.$helpers.notification.Error('ECHO Code not saved ' + e))
      }


      await this.init()

      successful = true
    } catch (e: any) {
      this.$helpers.notification.Error('ECHO Code not activated: ' + e)
      successful = false
    } finally {
      this.isLoading = false
    }

    return successful
  }

  public async onSave() {
    await this.$save()
  }

  public async onCancel() {
    await this.init()
  }

  @Watch('asidDB', { deep: true })
  public onAsidChanged() {
    AppPreview.setReferences({
      categoryIDs: this.asidDB.categoryIDs,
      asidID: this.asidDB.id,
      identifierValue: this.asidDB.identifierValue
    },
    this.asidDB.assetAttributeValue
    )

    // hide notifications when asid is not active
    AppPreview.hideNotifications(!this.asidDB.activated)

    // only activate isUnsavedChangesTrapActive when the asid is already active
    this.firestore_isUnsavedChangesTrapActive = this.asidDB.activated && !this.isActivateAndNext
  }

  // public categoryDataTree: CategoryTree = {} as CategoryTree

  // #region settings


  public settings: SettingsAsidActivate = {
    persistCategory: true,
    displayQRCodeScanner: true,
    displayBarcodeScanner: false,
    activateScanInterface: false,
    displayActivateAnyButton: false,
    persistedCategories: []
  }

  public settingsConfig: SettingsConfig[] = [
    {
      title: 'Persist Category',
      description: 'Keep the category selected for the next ECHO CODE when saving this one.',
      accessorKey: 'persistCategory',
      type: 'boolean'
    },
    // {
    //   title: 'ECHO Code Scanner',
    //   description: 'Activate camera based ECHO Code scanner.',
    //   accessorKey: 'displayQRCodeScanner',
    //   type: 'boolean'
    // },
    {
      title: 'Barcode Scanner',
      description: 'Activate barcode scanner to input identifiers',
      accessorKey: 'displayBarcodeScanner',
      type: 'boolean'
    }
  ]

  public initSettings() {
    console.debug('init settings')
    this.settings = cloneObject(this.$localSettings.asidActivate)
  }

  public saveSettings() {
    this.$localSettings.asidActivate = this.settings
  }

  // #endregion settings


  // #region responses
  public moduleElements: ElementWithTypeAndID[] = []

  @Watch('asidDB.categoryIDs')
  @Watch('asidDB.identifierValue')
  private async updateModuleElements() {
    try {
      // if cant read categories its impossible to view hierarchically assigned modules
      if (!this.$auth.userHasPrivilege(this.$auth.dbPrivileges.CATEGORIES_READ)) {
        // show error message
        this.$helpers.notification.Error('You dont have the privilege to read categories. Please contact your administrator.')
      } else {
        this.$unbindHandle(await ModuleManager.onSnapshotElementsForReference({
          asid: this.asid,
          categoryIDs: this.asidDB.categoryIDs,
          identifierValue: this.asidDB.identifierValue
        }, this.$auth.tenant.id, this.$auth.userPrivileges, {
          includeDeleted: false, debugName: 'asidSingle'
        }, (elements) => {
          this.moduleElements = elements
        }, true))
      }
    } catch (error) {
      this.$helpers.notification.Error(error)
    }
  }

  public responsesProtection: Array<hasDBid & ProtectionResponseDB> = []
  public responsesForm: Array<hasDBid & FormResponseDB> = []
  public responsesFile: Array<hasDBid & FileResponseDB> = []
  public responsesCustom: Array<hasDBid & CustomResponseDB> = []
  public responsesScript: Array<hasDBid & ScriptResponseDB> = []
  public responsesI18n: Array<hasDBid & I18nResponseDB> = []
  public sessions: Array<hasDBid & SessionDB> = []

  private allResponsesLoaded = false

  /**
   * Aligned means that for a certain date range all responses for all modules are available
   * if e.g. there are 10 form responses for one month and 5 file responses for one week
   * the smallest timespan to fill the 10 responses (file) will dictate the overall scale
   * otherwise we would show one month, but only one week of file responses, which would be confusing
   */
  public alignedResponsesProtection: Array<hasDBid & ProtectionResponseDB> = []
  public alignedResponsesForm: Array<hasDBid & FormResponseDB> = []
  public alignedResponsesFile: Array<hasDBid & FileResponseDB> = []
  public alignedResponsesCustom: Array<hasDBid & CustomResponseDB> = []
  public alignedResponsesScript: Array<hasDBid & ScriptResponseDB> = []
  public alignedResponsesI18n: Array<hasDBid & I18nResponseDB> = []
  public alignedSessions: Array<hasDBid & SessionDB> = []


  /**
   * ------------time-(older)------>
   * a:             |
   * b: #     #    #|    #     #     #
   * c: ### #  #   #|
   * d:       #     |     #                 #
   * e:             |   #     # ####
   *   |---aligned--|
   *
   * align to series c as it reaches 6 responses first
   * 0. filter out all response with less than 6 responses
   * 1. get the oldest date of all responses
   * 2. get the newset date of the oldest dates
   * 3. filter all responses that are older than the oldest date
   *
   */
  @Watch('responsesProtection')
  @Watch('responsesForm')
  @Watch('responsesFile')
  @Watch('responsesCustom')
  @Watch('responsesScript')
  @Watch('responsesI18n')
  @Watch('sessions')
  private alignResponses() {
    console.debug('try aligning responses. all loaded:', this.allResponsesLoaded)
    if (!this.allResponsesLoaded) return

    const allResponses = [
      this.responsesProtection,
      this.responsesForm,
      this.responsesFile,
      this.responsesCustom,
      this.responsesScript,
      this.responsesI18n,
      this.sessions
    ]

    // if there are more than LIMI responses get the newest date
    // if its less tnan 10 ignore the dates as it is not aligning relevant
    const oldestResponse = allResponses
      .filter((responses) => responses.length >= this.responseLimit)
      .map((responses) => responses[responses.length - 1])

    const oldestDates = oldestResponse.map((response) => response._meta.dateCreated.toDate())

    const newestDate = oldestDates
      .filter((date) => date)
      .sort((a, b) => b.valueOf() - a.valueOf())[0]

    // filter all responses that are older than the oldest date
    // if no oldest date is available due to less than responseLimit responses, dont filter
    if (!newestDate) {
      this.alignedResponsesProtection = this.responsesProtection
      this.alignedResponsesForm = this.responsesForm
      this.alignedResponsesFile = this.responsesFile
      this.alignedResponsesCustom = this.responsesCustom
      this.alignedResponsesScript = this.responsesScript
      this.alignedResponsesI18n = this.responsesI18n
      this.alignedSessions = this.sessions
    } else {
      this.alignedResponsesProtection = this.responsesProtection.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesForm = this.responsesForm.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesFile = this.responsesFile.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesCustom = this.responsesCustom.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesScript = this.responsesScript.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesI18n = this.responsesI18n.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedSessions = this.sessions.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
    }
  }


  public async loadMoreResponses() {
    this.responseLimit += 20
    await this.loadResponses()
  }

  private initialResponsesLoadingRequested = false
  private responseLimit = 20

  @Watch('tabHashMixin_activeTab', { immediate: true })
  private async initLoadResponses() {
    if (this.tabHashMixin_activeTab !== 1) return
    if (this.initialResponsesLoadingRequested) return

    this.initialResponsesLoadingRequested = true

    await this.loadResponses()
  }

  public responsesLoading = false

  private async loadResponses() {
    try {
      this.responsesLoading = true

      await this.$firestoreBind('sessions',
        typedOrderBy<SessionDB>(
          typedWhere<SessionDB>(
            SessionManager
              .getDbCollectionReference(this.$auth.tenant.id)
              .limit(this.responseLimit),
            { asidID: '' }, '==', this.$props.asid
          ),
          { _meta: { dateCreated: '' as any } }, 'desc'
        ),
        { wait: true }
      )


      if (this.$auth.userHasAllPrivilege(FormModule.authPrivileges.r))
        await this.$firestoreBind('responsesForm',
          typedOrderBy<FormResponseDB>(
            typedWhere<FormResponseDB>(
              typedOrderBy<FormResponseDB>(
                typedWhere<FormResponseDB>(
                  FormModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(FileModule.authPrivileges.r))
        await this.$firestoreBind('responsesFile',
          typedOrderBy<FileResponseDB>(
            typedWhere<FileResponseDB>(
              typedOrderBy<FileResponseDB>(
                typedWhere<FileResponseDB>(
                  FileModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(CustomModule.authPrivileges.r))
        await this.$firestoreBind('responsesCustom',
          typedOrderBy<CustomResponseDB>(
            typedWhere<CustomResponseDB>(
              typedOrderBy<CustomResponseDB>(
                typedWhere<CustomResponseDB>(
                  CustomModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(ScriptModule.authPrivileges.r))
        await this.$firestoreBind('responsesScript',
          typedOrderBy<ScriptResponseDB>(
            typedWhere<ScriptResponseDB>(
              typedOrderBy<ScriptResponseDB>(
                typedWhere<ScriptResponseDB>(
                  ScriptModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(ProtectionModule.authPrivileges.r))
        await this.$firestoreBind('responsesProtection',
          typedOrderBy<ProtectionResponseDB>(
            typedWhere<ProtectionResponseDB>(
              typedOrderBy<ProtectionResponseDB>(
                typedWhere<ProtectionResponseDB>(
                  ProtectionModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(I18nModule.authPrivileges.r))
        await this.$firestoreBind('responsesI18n',
          typedOrderBy<ProtectionResponseDB>(
            typedWhere<ProtectionResponseDB>(
              typedOrderBy<ProtectionResponseDB>(
                typedWhere<ProtectionResponseDB>(
                  I18nModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      this.allResponsesLoaded = true

      console.debug('responses loaded')

      this.alignResponses()
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.responsesLoading = false
    }
  }
  // #endregion responses

  public async created() {
    this.initSettings()
    this.initActiveModules()
    await this.initIncidentCounts()
  }

  public activeModuleTypes: ModuleType[] = []

  private initActiveModules() {
    this.$unbindHandle(ModuleManager
      .onSnapshotActivatedModuleClasses(this.$auth.tenant.id, this.$auth.userPrivileges, (Ms) => {
        this.activeModuleTypes = Ms
          .filter((M) => intersectSome(M.authPrivileges.view, this.$auth.userPrivileges))
          .map((M) => M.type)
          .filter((type) => ['Html', 'Form', 'File', 'Custom', 'Script'].includes(type))
      }, (e) => { /** */ }, 'asid-single'))
  }

  // leaving this for reference, using listener on the relevant prop (asid) instead pf route, as route may also change due to hash
  // @Watch('$route', { immediate: true })
  // private async onRouteChange(val: Route, oldVal: Route) {
  //   console.debug('route changed', val, oldVal)

  //   // if only hash changed, dont reload
  //   // if (!!oldVal && val.path === oldVal.path) return

  //   this.init()
  // }

  @Watch('asid', { immediate: true })
  private async init() {
    this.isLoading = true
    // this.isInitialLoaded = false init is also called after updating
    this.codeNotFound = false
    this.initialResponsesLoadingRequested = false
    this.allResponsesLoaded = false

    this.$disposeSnapshots()

    await this.updateModuleElements()

    try {
      if (this.asid !== 'any') {
        await this.$bindSnapshot('asidDB', AsidManager.getDbDocReference(this.$props.asid)).catch((e) => {
          this.codeNotFound = true
          throw `ECHO Code not found. (may be assigned to other company) ${e}`
        })
        if (!this.asidDB) {
          throw 'ECHO Code not found or already activated by other tenant'
        }
      } else {
        this.asidDB = cloneObject({
          ...AsidManager.defaultDocDB,
          id: 'ECHO CODE NOT SELECTED',
          codeConfig: this.$backendConfig.codes[0]
        })
      }


      this.identifierDefinitions = Object.entries(this.$backendConfig.asid.identifierDefinition).map(([key, value]) => ({
        __variableKey__: key as keyof IdentifierKeyedObject,
        ...value
      }))
        .filter((e) => e.name || e.title)
        .sort((a, b) => a.order - b.order)

      this.assetAttributeDefinitions = Object.entries(this.$backendConfig.asid.assetAttributeDefinitions).map(([key, value]) => ({
        __variableKey__: key as keyof assetAttributeKeyedObject,
        ...value
      }))
        .filter((e) => e.name || e.title)
        .sort((a, b) => a.order - b.order)

      // if a data element is available for this asid, get its respective dataDefinition
      // => always get all data groups. Simpler and not much data
      // if (this.dataElements.length > 0) {
      // const query = typedWhere<DataGroupDB & hasDBid>(DataModule.getGroupsQuery(this.$auth.tenant.id, false, true), { id: '' }, 'in', this.dataElements.map(d => d.public.groupID))
      if (this.$auth.userHasAllPrivilege(DataModule.authPrivileges.r)) {
        await this.$firestoreBind('dataModuleGroups', DataModule.getGroupsDbReference(this.$auth.tenant.id), { wait: true })
          .catch((e) => {
            throw `dataModuleGroups not found. ${e}[20220226]`
          })
      } else {
        this.dataModuleGroups = []
      }


      if (this.$localSettings.asidActivate.persistCategory && !this.asidDB.dateActivated)
        this.asidDB.categoryIDs = this.$localSettings.asidActivate.persistedCategories
    } catch (error: any) {
      this.$helpers.notification.Error(`${error}`)
      // this.$router.push({ name: 'activate-asid' })
    } finally {
      this.isLoading = false
      this.isInitialLoaded = true
    }
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';

.backend-asid-single {
  .workflow-wrapper {
    margin-bottom: 2em;

    &.modal {
      margin-bottom: initial;

      .modal-card-title {
        flex-shrink: 1;
      }

      .page-errors {
        margin-left: auto;

        @include until($tablet) {
          width: 100%;

          .tag {
            width: 100%;
          }
        }
      }
    }

    .card-header-title {
      word-break: break-all;
      font-weight: normal;
    }

    .card-content {
      background: #f6f6f6;
      border-top: 1px solid #e5e5e5;
    }

    .step-count {
      margin: 0.5rem;
    }

    .step {
      margin-bottom: 1em;

      background: #f6f6f6;
    }

    .step-description {
      margin-bottom: 1em;
    }

    .workflow-navigation {
      border-radius: 4px;
      padding: 1em;
      display: flex;
      justify-content: space-between;
      background: whitesmoke;

      .button {
        margin: 0.5rem;
      }

      @include until($tablet) {
        flex-direction: column;
        align-items: stretch;
        // make the buttons full with
        .button {
          flex-grow: 1;
        }
      }
    }
  }

  .load-more-button {
    margin-bottom: 2rem;
  }

  .asid-page-wrapper {
    // width: 100%;
  }

  .module-elements-container {
    margin-bottom: 3em;
  }

  .b-tabs {
    .asid-assignment-tab {
      display: flex;
      flex-direction: column;
    }

    .tab-content {
      padding: 1rem 0;

      .tab-item {
        max-width: 85em;
        width: 100%;
        margin: auto;
      }
    }
  }

  form.box {
    margin-bottom: 2em;

    .field-label.is-normal {
      flex-grow: 2;
    }
  }

  .echo-sticker-preview-container {
    height: 22em;
    padding: 0 3em 3em;
    max-width: 32em;
    width: 100%;
    position: sticky;
    top: 0;

    /* Media query for mobile phone screens */
    @media screen and (width <= 768px) {
      /* center the qr code */
      & {
        margin: auto;
      }
    }

    .echo-id {
      margin: auto;
      font-size: 0.9em;
      font-weight: 700;
      width: 100%;
    }
    // .echo-sticker-preview {}
  }

  .module-menus-container {
    overflow-x: auto;
    flex-wrap: wrap;
  }
}

.category-table tr.detail {
  display: none;
}

form.box {
  background: #f6f6f6;
}

.clickable {
  cursor: pointer;
}
</style>
