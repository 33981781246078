<template>
  <div class="category-entry-container">
    <b-field
      v-for="(catEntryDef, key) in filteredCategoryEntryDefinitions"
      :key="key"
      :label="catEntryDef.title"
      :type="validationErrorMessages[key] ? 'is-danger' : validationWarnMessages[key] ? 'is-warning' : null"
      :message="validationErrorMessages[key] || validationWarnMessages[key]"
      :class="labelOnBorder ? 'label-on-border' : ''"
    >
      <VInputMultiCategorySelection
        :selected-category-i-ds="formSelectedCategories[key]"
        :categories-doc="getCategoryBranchBasedOnPivotElement(catEntryDef.validator.pivotCategory)"
        :disable-branch-nodes="catEntryDef.validator.constraint === 'categoryEntryConstraint_leafNode'"
        :multiple="catEntryDef.validator.maxCount > 1"
        @selected="onCategorySelectionChanged(key, $event)"
      />
    </b-field>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'


import CategoryHelper from '@/database/categoryHelper'
// import the component
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CategoryID, CategoryCollection } from '@/types/typeCategory'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'
import { arrayUnique, identicalArray } from '@/helpers/arrayHelper'

/**
 * Entry categories based on the CategoryEntryDefinitionObject
 * inout is one array of categories and the CategoryEntryDefinitionObject
 * multiple input forms are shown to the user to input categories based on the CategoryEntryDefinitionObject
 */

@Component({
  components: {
    VInputMultiCategorySelection
  },
  model: {
    prop: 'selectedCategoryIDs',
    event: 'selected'
  }
})
export default class VInputMultiCategoryEntry extends Vue {
  @Model('selected', { type: Array, required: true }) readonly selectedCategoryIDs!: CategoryID[]
  @Prop({ type: Object, required: true }) readonly categoryEntryDefinitions!: CategoryEntryDefinitionObject
  // datadefinition key - if provided, the data entry will only show the data entry for the provided key
  @Prop({ type: Array, required: false, default: () => [] }) readonly dataDefinitionKeys!: (keyof CategoryEntryDefinitionObject)[]

  // labelOnBorder
  @Prop({ type: Boolean, required: false, default: false }) readonly labelOnBorder!: boolean

  // categories array for each category entry
  public formSelectedCategories: {
    e1: CategoryID[]
    e2: CategoryID[]
    e3: CategoryID[]
    e4: CategoryID[]
    e5: CategoryID[]
    e6: CategoryID[]
  } = {
      e1: ['featureExt2Id'],
      e2: [],
      e3: [],
      e4: [],
      e5: [],
      e6: []
    }


  public formCategoryBranches: {
    e1: CategoryID[]
    e2: CategoryID[]
    e3: CategoryID[]
    e4: CategoryID[]
    e5: CategoryID[]
    e6: CategoryID[]
  } = {
      e1: [],
      e2: [],
      e3: [],
      e4: [],
      e5: [],
      e6: []
    }


  // filter out the category entry definitions which do not contain a pivotCategory
  public get filteredCategoryEntryDefinitions(): Partial<CategoryEntryDefinitionObject> {
    return Object.fromEntries(Object.entries(this.categoryEntryDefinitions)
      .filter(([key, value]: [string, CategoryEntryDefinition]) => value.validator.pivotCategory)
      .filter(([key, value]: [string, CategoryEntryDefinition]) => value.title)
      // sort by key
      .sort(([key1, value1]: [string, CategoryEntryDefinition], [key2, value2]: [string, CategoryEntryDefinition]) => key1.localeCompare(key2))
      // filter dataDefinitionKeys
      .filter(([key, value]: [string, CategoryEntryDefinition]) => this.dataDefinitionKeys.length === 0 || this.dataDefinitionKeys.includes(key as keyof CategoryEntryDefinitionObject))
    )
  }


  public getCategoryBranchBasedOnPivotElement(pivotCategoryID: CategoryID): CategoryCollection {
    return CategoryHelper.getCategoryBranchBasedOnPivotElement(pivotCategoryID, this.$categories)
  }

  private branchCategoryCache: { [definitionKey: string]: CategoryID[] } = {}

  // todo see AppAdress on how its done
  @Watch('categoryEntryDefinitions', { immediate: true, deep: true })
  @Watch('selectedCategoryIDs', { immediate: true })
  private onselectedCategoryIDChanged(val: string[]) {
    // assign the selected categories to the formSelectedCategories based on the category entry definition
    // if a selected category intersects with the set of categories of the category entry definition, then assign it to the formSelectedCategories

    for (const key in this.categoryEntryDefinitions) {
      // const branchCategories = CategoryHelper.getAllChildCategoriesArray([value.validator.pivotCategory], this.$categories)
      //   ; (this.formSelectedCategories as any)[key] = this.selectedCategoryIDs.filter((catID: CategoryID) => branchCategories.includes(catID))
      (this.formSelectedCategories as any)[key] = CategoryHelper.filterCategoryIDsByEntryDefinition(
        this.$categories,
        this.categoryEntryDefinitions,
        key as keyof CategoryEntryDefinitionObject,
        this.selectedCategoryIDs,
        this.branchCategoryCache
      )
    }

    this.validateInput(true)
  }

  public onCategorySelectionChanged(key: keyof CategoryEntryDefinitionObject, selectedIDs: CategoryID[]) {
    /**
     * if the change was to remove a category, remove it from the selectedCategoryIDs and emit the new set
     * if the change was to add a category, add it to the selectedCategoryIDs and emit the new set
     */
    const previousSelection = this.formSelectedCategories[key]
    const removedCategories = previousSelection.filter((catID: CategoryID) => !selectedIDs.includes(catID))
    const addedCategories = selectedIDs.filter((catID: CategoryID) => !previousSelection.includes(catID))

    // add the added categories to the selectedCategoryIDs
    let newSelectedCategoryIDs = arrayUnique([...this.selectedCategoryIDs, ...addedCategories])
    // remove the removed categories from the selectedCategoryIDs
    newSelectedCategoryIDs = newSelectedCategoryIDs.filter((catID: CategoryID) => !removedCategories.includes(catID))

    // do this check to prevent infinite reactive update loops
    if (!identicalArray(newSelectedCategoryIDs, this.selectedCategoryIDs)) {
    // set the new selected categories
      this.formSelectedCategories[key] = [...selectedIDs]
    }

    // only emit if the selected categories are different from the current selected categories to avoid infinite loops
    if (!identicalArray(newSelectedCategoryIDs, this.selectedCategoryIDs)) {
    // set the new selected categories
      this.$emit('selected', newSelectedCategoryIDs)
      this.validateInput()
    }
  }

  @Watch('formSelectedCategories', { immediate: true, deep: true })
  private onFormSelectedCategoriesChanged(oldVal: string[], newVal: string[]) {
    // aggregate all selections and propagate up
    let selectedCategories = Object.values(this.formSelectedCategories).reduce((acc: CategoryID[], val: CategoryID[]) => acc.concat(val), [])

    selectedCategories = arrayUnique(selectedCategories)

    // only emit if the selected categories are different from the current selected categories to avoid infinite loops
    if (!identicalArray(selectedCategories, this.selectedCategoryIDs)) {
      this.$emit('selected', selectedCategories)
      this.validateInput()
    }
  }

  public validationErrorMessages: { [key: string]: string } = {}
  public validationWarnMessages: { [key: string]: string } = {}

  /**
 * Validate the input of the dataValueObjectSync
 * @param warningOnly if true, only show warnings, if false, show also errors
 * @returns true if save is possible, false if not
 */
  public validateInput(warningOnly = false): boolean {
    this.validationErrorMessages = {}
    this.validationWarnMessages = {}

    const validationResult = CategoryHelper.validateCategoryEntry(
      this.$categories,
      this.filteredCategoryEntryDefinitions,
      this.selectedCategoryIDs,
      this.branchCategoryCache
    )

    for (const [key, message] of Object.entries(validationResult[1])) {
      if (warningOnly || message.severity === 'warning') {
        this.$set(this.validationWarnMessages, key, message.text)
      } else {
        this.$set(this.validationErrorMessages, key, message.text)
      }
    }

    return Object.keys(this.validationErrorMessages).length === 0
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';

.category-entry-container {
  .label-on-border {
    .label {
      position: relative;
      top: 0.95rem;
      z-index: 9;
      /* background: white; */
      width: max-content;
      font-size: 0.8rem;
      left: 0.7rem;
      margin-top: -1.7rem;
    }

    .label::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.775em;
      left: 0;
      right: 0;
      height: 0.375em;
      background-color: hsl(0deg 0% 100%);
      z-index: -1;
    }
  }
}
</style>
