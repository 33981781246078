<template>
  <div class="modal-card data-definition-wrapper" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit Data Definition
        {{ modalDataDefinition.title }} ({{ modalDataDefinitionKey }})

      </p>
    </header>

    <section class="modal-card-body">
      <b-message v-if="errorMessage" type="is-danger">{{ errorMessage }}</b-message>

      <b-field grouped expanded>
        <b-field label="Title" expanded>
          <b-input
            v-model="modalDataDefinition.title"
            placeholder="Title"
          />
        </b-field>
        <b-field label="Name" expanded>
          <b-input
            v-model="modalDataDefinition.name"
            :placeholder="dataDefinitionNameSuggestions[modalDataDefinitionKey]"
            pattern="[a-zA-Z0-9_\-]*"
            validation-message="Only letters, numbers, '_' and '-' are allowed"
          />
        </b-field>
      </b-field>

      <b-field grouped>
        <b-field label="Datatype">
          <b-select
            v-model="modalDataDefinition.datatype"
            placeholder="Select datatype"
            @input="(val) => onSelectDatatype(modalDataDefinitionKey, val)"
          >
            <option
              v-for="(type) in DATA_DEFINITION_DATATYPES"
              :key="type.key"
              :value="type.key"
            >{{ type.value }}</option>
          </b-select>
        </b-field>

        <template v-if="modalDataDefinition.datatype !== 'image'">

          <!-- key is added to recompute the field as it may dynamically get addons (the button) -->
          <b-field :key="modalDataDefinition.validatorType" label="Validator">
            <b-select
              v-model="modalDataDefinition.validatorType"
              placeholder="Validator"
              @input="(val) => onAddValidator(modalDataDefinitionKey, val)"
            >
              <optgroup label="Validator">
                <option
                  v-for="option in getPossibleValidatorsBasedOnDatatype(modalDataDefinition.datatype)"
                  :key="option.key"
                  :value="option.key"
                >{{ option.value }}</option>
              </optgroup>
            </b-select>

            <p
              v-if="modalDataDefinition.validatorType !== 'validatorType_none'"
              class="control"
            >
              <b-button
                title="Remove validator"
                outlined
                icon-right="trash"
                @click="onAddValidator(modalDataDefinitionKey)"
              />
            </p>
          </b-field>


          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_minMax'">
            <b-field label="Min value">
              <b-input
                v-model="modalDataDefinition.validator.minMax[0]"
                type="number"
                placeholder="Min value"
              />
            </b-field>
            <b-field label="Max value">
              <b-input
                v-model="modalDataDefinition.validator.minMax[1]"
                type="number"
                placeholder="Max value"
              />
            </b-field>
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_regex'" label="Regex">
            <b-input
              v-model="modalDataDefinition.validator.regex"
              placeholder="Regex"
            />
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_choices'" label="Choices">
            <b-taginput
              v-model="modalDataDefinition.validator.choices"
              placeholder="Choices"
            />
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_value' && modalDataDefinition.datatype === 'number'" label="Valid Value">
            <b-numberinput
              v-model="modalDataDefinition.validator.value"
              placeholder="Value"
            />
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_value' && modalDataDefinition.datatype === 'string'" label="Valid Value">
            <b-input
              v-model="modalDataDefinition.validator.value"
              placeholder="Value"
            />
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_value' && modalDataDefinition.datatype === 'boolean'" label="Valid Value">
            <b-button
              title="Toggle valid value"
              @click="modalDataDefinition.validator.value === 'true'
                ? modalDataDefinition.validator.value = 'false'
                : modalDataDefinition.validator.value = 'true'
              "
            >{{ modalDataDefinition.validator.value === 'true' ? 'true' : 'false' }}</b-button>
          </b-field>

          <b-field v-if="modalDataDefinition.validatorType === 'validatorType_pattern'" label="Pattern">
            <b-input
              v-model="modalDataDefinition.validator.pattern"
              placeholder="Pattern"
            />
          </b-field>

          <!-- dont show if validator is autogenerated -->
          <b-field v-if="modalDataDefinition.validatorType !== 'validatorType_pattern'" label="Default Value">

            <!-- dropdown with choices -->
            <b-select
              v-if="modalDataDefinition.validatorType === 'validatorType_choices'"
              v-model="modalDataDefinition.defaultValue"
              placeholder="text"
            >
              <option :value="''">No default value</option>
              <option
                v-for="choice of modalDataDefinition.validator.choices"
                :key="choice"
                :value="choice"
              >{{ choice }}</option>
            </b-select>

            <!-- if datatype is boolean show a dropdown with true, false and no default value -->
            <b-select
              v-else-if="modalDataDefinition.datatype === 'boolean'"
              v-model="modalDataDefinition.defaultValue"
              placeholder="text"
            >
              <option :value="''">No default value</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </b-select>

            <!-- if dataype is email, only allow valid emails as default -->
            <b-input
              v-else-if="modalDataDefinition.datatype === 'email'"
              ref="validate-input-email"
              v-model="modalDataDefinition.defaultValue"
              type="email"
              placeholder="no default value"
            />

            <b-input
              v-else-if="modalDataDefinition.validatorType !== 'validatorType_pattern'"
              v-model="modalDataDefinition.defaultValue"
              :type="modalDataDefinition.datatype === 'number' ? 'number' : 'text'"
              placeholder="no default value"
            />

          </b-field>

        </template>
      </b-field>

      <b-field label="Categories">
        <VInputMultiCategorySelection
          v-model="modalDataDefinition.categories"
          :categories-doc="$categories"
        />
      </b-field>

      <b-field grouped>
        <b-field label="Allow Empty">
          <b-checkbox
            v-model="modalDataDefinition.allowEmpty"
          >Allow empty value</b-checkbox>
        </b-field>

        <b-field label="Allow Saving Invalid">
          <b-checkbox
            v-model="modalDataDefinition.allowSavingInvalid"
          >Allow saving invalid value</b-checkbox>
        </b-field>
      </b-field>

    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" type="button" @click="saveModalDataDefinition">Save</button>
    </footer>

  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'


import VRecordMeta from '@/components/VRecordMeta.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'

import { DataDefinition, DataDefinitionObject, isDataDefinitionKey } from '@/types/typeDataDefinition'

import { DataDefinitionValidatorType } from '@/types/typeDataDefinition'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'


@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VInputMultiCategorySelection,
    VFormConfigureSticker
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VFormDataDefinitionModal extends Vue {
  @Prop({ type: Object, required: true }) readonly dataDefinitionObject!: DataDefinitionObject

  @Prop({ type: String, required: true }) readonly modalDataDefinitionKey!: isDataDefinitionKey

  @Prop({ type: Boolean, default: () => false }) readonly showIdentifierWarning!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly hideTypes!: DataDefinition['datatype'][]

  @Prop({ type: Boolean, default: () => false })
  readonly hideNames!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly hideValidator!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly showCategoryVisibility!: boolean

  @Watch('modalDataDefinitionKey', { immediate: true })
  private onModalDataDefinitionKeyChange() {
    this.editedElement = this.modalDataDefinition
  }

  get modalDataDefinition() {
    return this.dataDefinitionObjectLocal[this.modalDataDefinitionKey]
  }

  @Watch('dataDefinitionObject', { deep: true, immediate: true })
  private onDataDefinitionObjectChange() {
    this.dataDefinitionObjectLocal = cloneObject(this.dataDefinitionObject)
  }

  public saveModalDataDefinition() {
    this.stopEditElement(this.modalDataDefinition)

    if (this.errorMessage) return
  }

  public dataDefinitionObjectLocal: DataDefinitionObject = {}

  /** name suggestions based on title and removed special chars */
  public dataDefinitionNameSuggestions: { [key: string]: string } = {}

  @Watch('dataDefinitionObjectLocal', { deep: true, immediate: true })
  private onDataDefinitionLocalChange() {
    // for each title generate a suggested name by replacing all special chars with underscores and transform to lowercase
    Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
      let suggestedName = dataDefinition.title
      suggestedName = suggestedName.replaceAll(/[^\w\s,;]/gi, '_')
      suggestedName = suggestedName.replaceAll(' ', '_')
      suggestedName = suggestedName.replaceAll('__', '_')
      suggestedName = suggestedName.toLowerCase()


      // if the key does not exists, set it reactively
      if (!this.dataDefinitionNameSuggestions[key as isDataDefinitionKey])
        this.$set(this.dataDefinitionNameSuggestions, key as isDataDefinitionKey, suggestedName)
      else
        this.dataDefinitionNameSuggestions[key as isDataDefinitionKey] = suggestedName
    })
  }

  get sortedDataDefinitionKeysLocal() {
    return Object.keys(this.dataDefinitionObjectLocal)
      .sort((a, b) => {
        // sort first by order, then by key
        if (this.dataDefinitionObjectLocal[a].order !== this.dataDefinitionObjectLocal[b].order) {
          return this.dataDefinitionObjectLocal[a].order - this.dataDefinitionObjectLocal[b].order
        } else {
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        }
      }
      )
  }


  // #region dataDefinition

  public editedElement: any = null

  public toggleEditElement(element: any) {
    // stop editing if there is currently an element being edited
    if (this.editedElement === element) {
      this.stopEditElement(this.editedElement)
    } else if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)

      // if stopping was indeed successful, start editing the new element
      if (this.editedElement === null) {
        this.editedElement = element
      }
    } else {
      this.editedElement = element
    }
  }

  // fn to be used from external to save the local data
  public $externalSave() {
    if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)
    }
    return this.errorMessage
  }

  public errorMessage = ''

  public stopEditElement(element: any) {
    console.debug('stopEditElement', element)
    // add data definition names if not given
    Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
      if (!dataDefinition.name && dataDefinition.title)
        this.$set(this.dataDefinitionObjectLocal, key as isDataDefinitionKey, { ...dataDefinition, name: this.dataDefinitionNameSuggestions[key as isDataDefinitionKey] })
    })

    // perform html input validation
    let everythingValid = true
    Object.keys(this.$refs as any).forEach((refName) => {
      const ref = this.$refs[refName] as any
      if (refName.startsWith('validate-input-') && ref)
        if (Array.isArray(ref)) {
          ref.forEach((r) => everythingValid = r.checkHtml5Validity() && everythingValid)
        } else {
          everythingValid = ref.checkHtml5Validity() && everythingValid
        }
    })

    if (!everythingValid) {
      this.errorMessage = 'Please correct the errors in the form'
    } else if (!Object.values(this.dataDefinitionObjectLocal).every((idef) => idef.name.match('^[a-zA-Z0-9_-]*$'))) {
      this.errorMessage = 'Names not valid. Only letters [aA-zZ], numbers [0-9] and [-,_,&] are allowed as names'
    } else if (Object.values(this.dataDefinitionObjectLocal).map((idef) => idef.name).filter((value, index, self) => self.indexOf(value) !== index).filter((value, index, self) => !!value).length > 0) { // check for no duplicate names
      this.errorMessage = `Names are not valid. Names must be unique, but the following names are used multiple times: ${Object.values(this.dataDefinitionObjectLocal).map((idef) => idef.name).filter((value, index, self) => self.indexOf(value) !== index).filter((value, index, self) => !!value).join(', ')}`
    } else {
      this.errorMessage = ''
      this.editedElement = null
      this.$emit('update', this.dataDefinitionObjectLocal)
      // close
      ;(this.$parent as any).close()
    }
  }

  get valueTypeName() {
    switch (Object.keys(this.dataDefinitionObjectLocal)[0].charAt(0)) {
      case 'i':
        return 'identifier'
      case 'd':
        return 'data'
      case 'a':
        return 'attribute'
      default:
        return 'unknown'
    }
  }


  public get DATA_DEFINITION_DATATYPES(): { key: DataDefinition['datatype'], value: string }[] {
    return [
      { key: 'boolean' as DataDefinition['datatype'], value: 'Boolean' },
      { key: 'image' as DataDefinition['datatype'], value: 'Image' },
      { key: 'string' as DataDefinition['datatype'], value: 'String' },
      { key: 'number' as DataDefinition['datatype'], value: 'Number' },
      // { key: 'other'  as DataDefinition['datatype'], value: 'Other' },
      { key: 'auto_generated' as DataDefinition['datatype'], value: 'Auto Generated' },
      { key: 'gps' as DataDefinition['datatype'], value: 'GPS' },
      { key: 'email' as DataDefinition['datatype'], value: 'Email' }
    ].filter(({ key }) => !this.hideTypes.includes(key))
  }

  public DATA_DEFINITION_VALIDATOR_TYPES: { key: DataDefinitionValidatorType, value: string }[] = [
    { key: 'validatorType_minMax', value: 'Min Max' },
    { key: 'validatorType_regex', value: 'Regex' },
    { key: 'validatorType_choices', value: 'Choices' },
    { key: 'validatorType_pattern', value: 'Pattern' },
    { key: 'validatorType_value', value: 'Value' }
  ]

  public get dataDefinitionTableColumns() {
    return [{
      field: '__key__',
      label: 'Data ID',
      visible: true,
      type: 'text'
      // tooltip: 'The data ID is used '
    }, {
      //   field: 'order',
      //   label: 'order',
      //   visible: true,
      //   type: 'text',
      //   tooltip: 'The title is used for displaying purposes and as a column header'
      // }, {
      field: 'title',
      label: 'Title',
      visible: true,
      type: 'text',
      tooltip: 'The title is used for displaying purposes and as a column header'
    }, {
      field: 'name',
      label: 'Name',
      visible: !this.hideNames,
      type: 'text',
      tooltip: `The name can be used to programatically access the value e.g. {{ ${this.valueTypeName}.serial_number }}. Only letters [aA-zZ], numbers [0-9] and [-,_] are allowed as ${this.valueTypeName} names`
    },
    // {
    //   field: 'title',
    //   label: 'Title',
    //   visible: true,
    //   tooltip: 'The title is used for displaying purposes as a column heading in the response tab.',
    //   type: 'text'
    // },
    // {
    //   field: 'order',
    //   label: 'Order',
    //   visible: true,
    //   sortable:true,
    //   type: 'number'
    // },
    {
      field: 'datatype',
      label: 'Datatype',
      visible: true,
      type: 'dropdown',
      options: this.DATA_DEFINITION_DATATYPES.map(({ key, value }) => [key, value]).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    }, {
      field: 'validator',
      label: 'Validator',
      visible: !this.hideValidator,
      type: 'validator',
      tooltip: `Used to validate user input when setting the ${this.valueTypeName} value`
    },
    // default value
    {
      field: 'defaultValue',
      label: 'Default Value',
      visible: !this.hideValidator,
      type: 'text',
      tooltip: `The default value is used when no value is set for the ${this.valueTypeName}`
    },
    {
      field: 'allowEmpty',
      label: 'Allow Empty Value',
      visible: !this.hideValidator,
      type: 'allowEmpty',
      tooltip: `If set to true, the ${this.valueTypeName} value can be empty`
    }, {
      field: 'categories',
      label: 'Category Visibility',
      visible: !this.hideValidator && this.showCategoryVisibility,
      type: 'categories',
      tooltip: `The ${this.valueTypeName} will only be visible in the selected categories. Referencing works analog to Element referencing.`
    }]
  }
  // #endregion dataDefinition


  public getPossibleValidatorsBasedOnDatatype(datatype: DataDefinition['datatype']) {
    let validatorTypeKeys: DataDefinitionValidatorType[] = []
    switch (datatype) {
      case 'number':
        validatorTypeKeys = ['validatorType_minMax', 'validatorType_choices', 'validatorType_value']
        break
      case 'string':
        validatorTypeKeys = ['validatorType_regex', 'validatorType_choices', 'validatorType_regex', 'validatorType_value']
        break
      case 'boolean':
        validatorTypeKeys = ['validatorType_value']
        break
      case 'auto_generated':
        validatorTypeKeys = ['validatorType_pattern']
        break

      default:
        validatorTypeKeys = []
    }

    return this.DATA_DEFINITION_VALIDATOR_TYPES.filter((validatorType) => validatorTypeKeys.includes(validatorType.key))
  }


  public onSelectDatatype(dataDefinitionKey: isDataDefinitionKey, DataDefinitionDataType: DataDefinition['datatype']) {
    console.log('onSelectDatatype', dataDefinitionKey, DataDefinitionDataType)
    // reset the validator type
    this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType = 'validatorType_none'

    // if auto_generated, set the pattern
    if (DataDefinitionDataType === 'auto_generated') {
      this.onAddValidator(dataDefinitionKey, 'validatorType_pattern')
    }
  }

  public onAddValidator(dataDefinitionKey: isDataDefinitionKey, DataDefinitionValidatorType?: DataDefinitionValidatorType) {
    this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType = DataDefinitionValidatorType || 'validatorType_none'

    console.log('onAddValidator', dataDefinitionKey, DataDefinitionValidatorType, this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType)


    switch (DataDefinitionValidatorType) {
      case 'validatorType_value':
        // switch based on datatype
        switch (this.dataDefinitionObjectLocal[dataDefinitionKey].datatype) {
          case 'number':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = 0
            break
          case 'string':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = ''
            break
          case 'boolean':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = 'false'
            break
        }
        break
    }

    // if datatype is auto_generated, set validator to pattern
    if (this.dataDefinitionObjectLocal[dataDefinitionKey].datatype === 'auto_generated') {
      // add a sample pattern
      if (this.valueTypeName === 'identifier')
        this.dataDefinitionObjectLocal[dataDefinitionKey].validator.pattern = '{{category|childOf(\'All Categories\')}}_{{identifier.i1}}_{{functions|increment(3)}}'
      else if (this.valueTypeName === 'attribute')
        this.dataDefinitionObjectLocal[dataDefinitionKey].validator.pattern = 'WK{{functions|week()}}'
    }
  }

  // #endregion data definition
}
</script>

<style lang="scss">
.modal-card.data-definition-wrapper {
  .modal-card-body {
    overflow: visible;
  }

  .modal-content {
    overflow: visible;
  }

  .field.has-addons .control:not(:last-child) {
    margin-right: -1px;
  }

  .table-wrapper {
    overflow: visible;
  }

  // only applies for data-label="validator" column
  td[data-label='Validator'] span {
    word-break: break-word;
  }
}
</style>
