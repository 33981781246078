<template>
  <section class="manage-asids-container">
    <VPrivilegeNotification
      :required-privileges="[...documentPrivileges.r, ...documentPrivileges.w]"
    >
      <b-field grouped>
        <b-field expanded :label="`Codes activated ${ activated } out of ${ availableAsidSlots }`">
          <b-progress
            :value="activated"
            :max="availableAsidSlots"
            show-value
          >{{ (activated / availableAsidSlots * 100).toFixed(2) }} %</b-progress>
        </b-field>

        <b-field
          expanded
          :label="`Interactions ${ totalUsedInteractions } out of ${ availableInteractions }`"
        >
          <b-progress
            :value="totalUsedInteractions"
            :max="availableInteractions"
            show-value
          >{{ (totalUsedInteractions / availableInteractions * 100).toFixed(2) }} %</b-progress>
        </b-field>
      </b-field>
    </VPrivilegeNotification>
    <hr />

    <VTable
      v-if="!isLoading"
      ref="VTable"
      class="asid-list-table"
      :column-definition="table_tableColumns"
      :collection-path="table_collectionPath"
      :filter-config="table_filterConfig"
      :query-filter="table_queryFilter"
      :sort-field-accessor.sync="table_sortField"
      :sort-direction.sync="table_sortDirection"
      :items-per-page="table_perPage"
      :live-update-on-first-page="table_liveUpdateOnFirstPage"
      :import-export_update-doc-batch="importExport_updateDocBatch"
      :import-export_get-default-doc="importExport_getDefaultDoc"
      :import-export_get-doc="importExport_getDoc"
      :import-export_import-export-definitions="importExport_importExportDefinitions"
      :import-export_validate-imported-data="importExport_validateImportedData"
      :import-export_before-get-current-data="importExport_beforeGetCurrentData"
      :per-page-options="[20, 50, 100, 400]"
      @checked-rows="c => table_checkedRows = c"
      @table-data="d => table_tableData = d"
      @loading="l => table_isLoading = l"
      @details-open="onDetailsOpen"
      @dblclick="$router.push({ name: 'asid-single', params: { asid: $event.id } })"
    >
      <template #column_activated="{ row }">
        <span
          :class="['tag', { 'is-warning': !row.activated || row.publishingState !== 'published' }, { 'is-success': row.activated }]"
        >{{ row.publishingState !=='published' ? 'not published' : row.activated ? 'yes' : 'no' }}</span>
      </template>

      <template #column_valid="{ value }">
        <span
          :class="['tag', { 'is-danger': !value.valid }, { 'is-success': value.valid }]"
          :title="value.validationMessages.length > 0 ? value.validationMessages.join('\n') : 'Assignment is valid'"
        >{{ value.valid ? 'yes' : 'no' }}</span>
      </template>

      <template #actions="{ row }">
        <b-field>
          <p class="control">
            <b-button
              tag="router-link"
              :to="{ name: 'asid-single', params: { asid: row.id } }"
              class="is-small"
              title="edit assignment"
              icon-right="edit"
            />
          </p>

          <p class="control">
            <b-button
              tag="router-link"
              title="show responses"
              icon-right="stream"
              :to="{ name: 'asid-single', params: { asid: row.id }, hash: '#responses' }"
              class="is-small"
            />
          </p>
          <p class="control">
            <b-dropdown class="setting-dropdown" position="is-bottom-left" append-to-body>
              <template #trigger>
                <b-button icon-left="ellipsis-h" size="is-small" />
              </template>

              <!-- <b-dropdown-item v-if="!hideGroups" separator /> -->
              <b-dropdown-item
                @click="onSetAsidPublishingState(row.id, row.publishingState === 'archived' ? 'published' : 'archived')"
              >{{ row.publishingState === 'archived' ? 'unarchive' : 'archive' }} ECHO CODE</b-dropdown-item>

              <b-dropdown-item
                v-if="row.publishingState === 'draft'"
                @click="onSetAsidPublishingState(row.id, 'published')"
              >publish ECHO CODE</b-dropdown-item>

              <!-- if has the service view privilege, show an item to create a new incident for this echo code -->
              <b-dropdown-item v-if="$auth.userHasPrivilege('service:view')" has-link>
                <router-link
                  :to="{ name: 'module-service-incident-single', params: { id: 'new' }, query: { asidID: row.id } }"
                >
                  <b-icon icon="clipboard-list" size="is-small" />create ticket
                </router-link>
              </b-dropdown-item>

              <!-- <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else</b-dropdown-item>-->
            </b-dropdown>
          </p>
        </b-field>
      </template>

      <template #detail="{ row }">
        Asid:
        <span style="font-family: monospace;">{{ row.id }}</span>
        <br />Module Elements:
        <VTagModuleElements v-if="elementsByAsid[row.id]" :elements="elementsByAsid[row.id]" />

        <div class="echo-code-container">
          <!-- :style="{width: (false) ? $backendConfig.codes[0].width+'mm' : '14em'}" -->
          <span style="display: none;">{{ getDataByDefinitionKeys.length }}</span>
          <VEchoCode
            :identifier="row.identifierValue"
            :attribute="row.assetAttributeValue"
            :data="getDataByDefinitionKeys(row)"
            :code-config="row.codeConfig"
            :asid="row.id"
            :base-url="$backendConfig.asid.baseUrl"
            :asid-categories="row.categoryIDs"
            :category-collection="$categories"
          />
        </div>
      </template>
    </VTable>

    <b-button
      type="is-text"
      expanded
      @click="doShowArchivedAsids = !doShowArchivedAsids"
    >{{ doShowArchivedAsids ? 'Hide' : 'Show' }} archived ECHO CODEs</b-button>

    <b-loading :is-full-page="false" :active.sync="anyLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import VButtonToggleLiveUpdate from '@/components/VButtonToggleLiveUpdate.vue'
import VEchoCode from '@/components/VEchoCode.vue'
import VFilterCategoriesDropdownView from '@/components/VFilterCategoriesDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VImportExport, { convertToNullOrString, typeImportExportDefinitions } from '@/components/VImportExport.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VTagModuleElements from '@/components/VTagModuleElements.vue'
import databaseSchema from '@/database/databaseSchema'
import { AsidDB, asidID, assetAttributeValueType, identifierValueType, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import { CategoryID } from '@/types/typeCategory'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { library } from '@fortawesome/fontawesome-svg-core'
import VTable, { TableColumnDefinition } from '@/components/VTable.vue'
import {
  faAngleLeft, faAngleRight, faArrowUp,
  faChevronRight, faClipboardList, faEllipsisH, faMars, faReplyAll, faStream, faSync, faVenus
} from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase/compat/app'
import { Component, Watch } from 'vue-property-decorator'
import AsidManager from '../../database/asidManager'
import { merge, typedWhere } from '../../database/dbHelper'
import { ModuleManager } from '../../modules/moduleManager'
import { BaseElementDB, hasModuleType, ModuleType, PublishingState } from '../../modules/typeModules'
import moment from 'dayjs'
import CategoryHelper from '@/database/categoryHelper'
import { FilterConfigNew } from '@/database/filterUtil'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { DataCache } from '@/helpers/dataCache'
import { FilterConfig } from '@/components/mixins/VPaginateMixin.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { arrayUnique } from '@/helpers/arrayHelper'
import VPrivilegeNotification from '@/components/VPrivilegeNotification.vue'
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'
import BackendConfigManager from '@/database/backendConfigManager'


library.add(faArrowUp, faStream, faReplyAll, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faSync, faEllipsisH, faClipboardList)

@Component({
  components: {
    VTagModuleElements,
    VImportExport,
    VRecordMeta,
    VEchoCode,
    VFilterDropdownView,
    VFilterCategoriesDropdownView,
    VFilterDateDropdownView,
    VButtonToggleLiveUpdate,
    VTable,
    VPrivilegeNotification
  }
})
export default class BackendAsidList extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public table_currentPage = 1
  public table_sortField = this.$localSettings.asidlist.sortField
  public table_sortDirection = this.$localSettings.asidlist.sortDirection
  public table_filterConfig: FilterConfig<AsidDB>[] = []

  public table_checkedRows: any[] = []
  public table_tableData: (AsidDB & hasDBid)[] = []
  public table_isLoading = false

  public debug(row: any) {
    console.log(row)
  }

  @Watch('table_sortField')
  private onChangePaginationSortField() {
    this.$localSettings.asidlist.sortField = this.table_sortField
  }

  @Watch('table_sortDirection')
  private onChangePaginationSortDirection() {
    this.$localSettings.asidlist.sortDirection = this.table_sortDirection
  }

  public getDataByDefinitionKeys(asidDB: AsidDB & hasDBid) {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}

    const dataElements = (this.elementsByAsid[asidDB.id]
      ?.filter((e) => e.type === 'Data') as Array<DataElementDB & hasDBid & hasModuleType>)
      ?.sort((a, b) => b.public.order - a.public.order) || []

    // combine all data elements data values into one object
    dataElements.forEach((dataElement) => {
      const dataGroup = this.dataGroupsByID[dataElement.public.groupID]
      if (dataGroup) {
        Object.entries(dataGroup.dataDefinition).map(([key, def]) => {
          if (def.name)
            combinesDataElements[def.name] = dataElement.data[key as isDataKey]
        })
      }
    })

    return combinesDataElements
  }

  // @Watch('pagination_filterConfig', { deep: true })
  // private onChangePaginationFilterConfig() {
  //   // todo
  //   this.$localSettings.asidlist.filter.categories = this.pagination_filterConfig.find(fc => fc.type === 'categories')?.in || []
  // }

  public table_liveUpdateOnFirstPage = true


  mounted() {
    //
  }

  public isLoading = false
  public importIsLoading = false
  public doShowArchivedAsids = false
  public elementsByAsid: { [key: string]: (BaseElementDB & hasDBid & hasModuleType)[] } = {}
  public dataGroupsByID: { [key: string]: (DataGroupDB & hasDBid & hasModuleType) } = {}


  public table_perPage = 20
  public table_collectionPath = AsidManager.getDbCollectionReference().path

  private branchCategoryCache: { [definitionKey: string]: CategoryID[] } = {}

  public get table_tableColumns() {
    const columnDef: TableColumnDefinition<AsidDB & hasDBid>[] = [
      // {
      //   field: 'asidID',
      //   label: 'ECHO CODE',
      //   numeric: false,
      //   searchable: true,
      //   sortable: true
      // },
      {
        field: 'id',
        label: 'ID',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false,
        editable: false,
        cellClass: 'asid-column'
      }, {
        field: 'activated',
        label: 'Activated',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: true,
        editable: false,
        centered: true
      },
      // is identifiers and attributes valid
      {
        field: 'valid', // field doesnt matter, as formatter is used to compute the actual value
        label: 'Assignment Valid',
        formatter: (asid: string, asidDB) => {
          const identifierDefinitions = BackendConfigManager.getDataDefinitionsFromObject(this.$backendConfig.asid.identifierDefinition).filter((e) => e.name || e.title)
          const filteredIdentifierDefinitions = BackendConfigManager.filterDataDefinitionsByCategories(identifierDefinitions, this.$categories, asidDB.categoryIDs)
          const [validIdentifier, validationMessagesIdentifier] = BackendConfigManager.validateDataDefinitionInput(asidDB.identifierValue, filteredIdentifierDefinitions)

          const assetAttributeDefinitions = BackendConfigManager.getDataDefinitionsFromObject(this.$backendConfig.asid.assetAttributeDefinitions).filter((e) => e.name || e.title)
          const filteredAssetAttributeDefinitions = BackendConfigManager.filterDataDefinitionsByCategories(assetAttributeDefinitions, this.$categories, asidDB.categoryIDs)
          const [validAssetAttributes, validationMessagesAssetAttributes] = BackendConfigManager.validateDataDefinitionInput(asidDB.assetAttributeValue, filteredAssetAttributeDefinitions)

          const validationMessages = [...Object.values(validationMessagesIdentifier).map((e) => e.text), ...Object.values(validationMessagesAssetAttributes).map((e) => e.text)]

          return { valid: validIdentifier && validAssetAttributes, validationMessages }
        },
        numeric: false,
        searchable: false,
        sortable: false,
        editable: false,
        centered: true
      },
      {
        field: 'dateActivated',
        label: 'Date Activated',
        formatter: (d: any) => d ? moment(d.toDate()).format('YYYY.MM.DD - HH:mm') : 'none',
        numeric: false,
        searchable: true,
        sortable: true
      },
      {
        field: 'categoryIDs',
        label: 'Categories',
        formatter: (catIDs: CategoryID[]) => catIDs.map((id) => this.$getCategoryName(id)),
        numeric: false,
        searchable: true,
        sortable: false,
        display: 'taglist',

        editable: true
        // headerClass: 'is-element-reference',
        // cellClass: 'is-element-reference'
        // columnGroup: 'References'
      },

      // categoryDefinitions
      ...Object.entries(this.$backendConfig.asid.categoryDefinitions || {})
        .filter(([definitionKey, definition]: [string, CategoryEntryDefinition]) => definition.title)
        // dont show category definitions with ROOT_CATEGORY_ID as pivot, as they are already covered by the categoryIDs column
        .filter(([definitionKey, definition]: [string, CategoryEntryDefinition]) => definition.validator.pivotCategory !== ROOT_CATEGORY_ID)
        // sort by key
        .sort(([keyA, a]: [string, CategoryEntryDefinition], [keyB, b]: [string, CategoryEntryDefinition]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        .map(([definitionKey, definition]: [string, CategoryEntryDefinition]) => ({
          field: 'categoryIDs',
          label: definition.title,
          numeric: false,
          searchable: false,
          sortable: false,
          editable: false,
          display: 'taglist' as const,
          formatter: (catIDs: CategoryID[]) => {
            const filteredCatIDsForEntryDef = CategoryHelper.filterCategoryIDsByEntryDefinition(
              this.$categories,
              this.$backendConfig.asid.categoryDefinitions,
              definitionKey as keyof CategoryEntryDefinitionObject,
              catIDs,
              this.branchCategoryCache
            )
            return filteredCatIDsForEntryDef.map((id) => this.$getCategoryName(id))
          }

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),


      ...Object.entries(this.$backendConfig.asid.identifierDefinition || {})
        .filter(([definitionKey, definition]) => (definition.title || definition.name))
        // sort by order and key
        .sort(([keyA, a], [keyB, b]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        // filter by visible categories
        .filter(([definitionKey, definition]) => {
          const filteredCategories = CategoryHelper.getFilteredCategories(
            this.$categories,
            this.$localSettings.modules.filters.categories,
            this.$auth.user?.visibleCategories || [],
            this.$localSettings.modules.filters.categoriesIncludeParentCats,
            this.$localSettings.modules.filters.categoriesIncludeChildCats
          )

          if (filteredCategories.length === 0) return true

          if (definition.categories.length === 0) return true

          return definition.categories.some((catID) => filteredCategories.includes(catID))
        })
        .map(([definitionKey, definition]) => ({
          field: `identifierValue.${definitionKey}`,
          label: (definition.title || definition.name),
          numeric: false,
          // numeric: definition.datatype === 'number',
          searchable: ((+definitionKey.substring(1)) <= 6), // allow everything to be sorted
          sortable: ((+definitionKey.substring(1)) <= 3), // only allow sort up to index d3 for index permutation reasons (alrdy 60 composiet indexes)
          editable: true,
          // display image if image otherwise tag
          display: definition.datatype === 'image' ? 'image' as const : 'tag' as const

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),

      ...Object.entries(this.$backendConfig.asid.assetAttributeDefinitions || {})
        .filter(([definitionKey, definition]) => (definition.title || definition.name))
        // sort by order and key
        .sort(([keyA, a], [keyB, b]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        // filter by visible categories
        .filter(([definitionKey, definition]) => {
          const filteredCategories = CategoryHelper.getFilteredCategories(
            this.$categories,
            this.$localSettings.modules.filters.categories,
            this.$auth.user?.visibleCategories || [],
            this.$localSettings.modules.filters.categoriesIncludeParentCats,
            this.$localSettings.modules.filters.categoriesIncludeChildCats
          )

          if (filteredCategories.length === 0) return true

          if (definition.categories.length === 0) return true

          return definition.categories.some((catID) => filteredCategories.includes(catID))
        })
        .map(([definitionKey, definition]) => ({
          field: `assetAttributeValue.${definitionKey}`,
          label: (definition.title || definition.name),
          numeric: false,
          // numeric: definition.datatype === 'number',
          // formatter to handle boolean values
          // formatter: (value: any) => {
          //   if (definition.datatype === 'boolean') {
          //     return value === true ? 'yes' : value === false ? 'no' : ''
          //   }
          //   return value
          // },
          searchable: true, // allow everything to be sorted
          sortable: ((+definitionKey.substring(1)) <= 3), // only allow sort up to index d3 for index permutation reasons (alrdy 60 composiet indexes)
          editable: true,
          display: definition.datatype === 'image' ? 'image' as const : 'tag' as const

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),

      {
        field: '_computed.responseCountPerModule',
        label: 'Interaction Count',
        tooltip: 'An interaction is counted every time a user interacts with an ECHO Code. This includes the initial view of the ECHO Code, as well as every time a user opens a file or submits a form response.',
        formatter: (responseCountPerModule: number) => Object.values(responseCountPerModule).reduce((a, b) => a + b, 0),
        numeric: false,
        searchable: false,
        sortable: false
      }, {
        field: '_computed.pageviews',
        label: 'View Count',

        numeric: false,
        searchable: true,
        sortable: true
      }
    ]

    return columnDef
  }

  public async onSetAsidPublishingState(asidID: asidID, targetState: PublishingState) {
    this.isLoading = true

    try {
      if (targetState === 'archived') {
        this.$buefy.dialog.confirm({
          title: 'Archiving ECHO Code',
          message: `Archiving the ECHO Code "${asidID}" will also archive all ${Object.values(this.table_tableData.find((e) => e.id === asidID)?._computed.responseCountPerModule || { a: 0 }).reduce((a, b) => a + b)} responses assigned to this Code.`,
          confirmText: 'Archive ECHO Code',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            await AsidManager.update(asidID, this.$auth.userEmail, { publishingState: 'archived' })
          }
        })
      } else {
        await AsidManager.update(asidID, this.$auth.userEmail, { publishingState: targetState })
      }
    } catch (error: any) {
      this.$helpers.notification.Error('error changing publishing state [20220220]: ' + error.toString())
    } finally {
      this.isLoading = false
    }
  }

  // @Watch('doShowArchivedAsids')
  // private onChangeDoShowArchivedAsids() {
  //   (this.$refs as any).VTable.refreshData(true)
  // }

  // protected table_localDocsFilter(docs: (AsidDB & hasDBid)[]) {
  //   return docs.filter(d => this.doShowArchivedAsids || d.publishingState !== 'archived')
  // }

  // when doShowArchivedAsids changes, change the filter config accordingly
  @Watch('doShowArchivedAsids', { immediate: true })
  private onChangeDoShowArchivedAsidsFilterConfig() {
    const clonedFilterConfig = cloneObject(this.table_filterConfig)
    // find publishing state filter
    const filterConfig = clonedFilterConfig.find((fc) => fc.fieldAccesor.publishingState)

    // if not found, return
    if (!filterConfig) return

    // set the filter value according to the doShowArchivedAsids value
    filterConfig.presets = this.doShowArchivedAsids ? ['published', 'archived', 'draft'] : ['published', 'draft']

    this.table_filterConfig = clonedFilterConfig

    if ((this.$refs as any).VTable)
      (this.$refs as any).VTable.refreshData(true)
  }

  // #region RecordMeta

  get documentPrivileges() {
    return merge(databaseSchema.COLLECTIONS.ASID.__PRIVILEGES__,
      { r: databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__PRIVILEGES__.r, w: [] })
  }

  // #endregion RecordMeta

  protected notBackendSortable = false

  get anyLoading() {
    return this.isLoading || this.table_isLoading || this.importIsLoading
  }


  public table_queryFilter(): FilterConfigNew<AsidDB>[] {
    //  return typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id)
    return [
      {
        fieldAccessor: { tenantID: '' },
        opStr: '==',
        values: [this.$auth.tenant.id],
        indexGroups: [],
        isMandatory: true
      }
    ]
  }


  public getModuleColorByType(moduleType: ModuleType) {
    return ModuleManager.getModuleClassByType(moduleType).color
  }


  private dataCacheGroup = new DataCache<DataGroupDB & hasDBid>(async (groupID) => {
    return await DataModule.getGroup<DataGroupDB>(this.$auth.tenantID, groupID)
  })

  public async onDetailsOpen(row: any) {
    // instead of a listener, only query those values when the details are opened, as also statistic changes on the elements would cause a rerende
    this.$unbindHandle(await ModuleManager.onSnapshotElementsForAsid(row.id, this.$auth.tenant.id, undefined, { includeDeleted: false, debugName: 'asid list' }, (elements) => {
      console.log('setting', this.elementsByAsid)

      this.$set(this.elementsByAsid, row.id, elements)

      // get all groups for data elements
      const dataElements = elements.filter((e) => e.type === 'Data')

      const groupIDs = arrayUnique(dataElements.map((e) => e.public.groupID))

      groupIDs.forEach((groupID) => {
        this.dataCacheGroup.get(groupID).then((d) => {
          this.$set(this.dataGroupsByID, groupID, d)
        }, (e) => {
          this.$helpers.notification.Error('error loading group data [202202292]: ' + e.toString())
        })
      })
    }, true))
  }

  private unsubscribeSnapshot?: SnapshotUnbindHandle = undefined

  public activated = 0
  public availableAsidSlots = 0
  public totalUsedInteractions = 0
  public availableInteractions = 0
  private onActivatedAsidsHandle?: SnapshotUnbindHandle = undefined

  public async created() {
    this.isLoading = true

    this.branchCategoryCache = {}

    // await this.$bind('asids', AsidManager.getDbCollectionReference().where('Tenant', '==', tenant.id))
    // await this.$bind('categoriesDoc', CategoryHelper.getCategoriesDoc(this.$auth.tenant.id))
    if (this.$auth.userHasPrivilege('config:read'))
      this.onActivatedAsidsHandle = AsidManager.onPlanData(this.$auth.tenantID, (data) => {
        this.activated = data._computed.activatedAsids
        this.availableAsidSlots = data.availableAsidSlots
        this.totalUsedInteractions = data._computed.totalUsedInteractions
        this.availableInteractions = AsidManager.getAvailableInteractionsCount(data)
      }, (e) => {
        this.$helpers.notification.Error('error loading plan data [20230321]: ' + e.toString())
      })

    this.setFilterConfig()
    this.onChangeGlobalCategoryFilter()
    this.isLoading = false
  }

  public setFilterConfig() {
    this.table_filterConfig = [
      // publishing state filter
      {
        fieldAccesor: { publishingState: 'published' },
        objAcessor: { value: '' },
        objDisplayAcessor: { title: '' },
        options: [{ title: 'Published', value: 'published' }, { title: 'Archived', value: 'archived' }],
        hideEmptyOption: true,
        type: 'exact' as const,
        in: [],
        presets: ['published', 'draft'],
        range: [],
        notBackendSortable: false
      },
      {
        fieldAccesor: { id: '' } as Partial<AsidDB>,
        collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
        objAcessor: { id: '' },
        queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
        type: 'exact' as const,
        in: [],
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { activated: true },
        objAcessor: { value: '' },
        objDisplayAcessor: { title: '' },
        options: [{ title: 'Yes', value: true }, { title: 'No', value: false }],
        hideEmptyOption: true,
        type: 'exact' as const,
        in: [],
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { categoryIDs: [] },
        collectionPath: '',
        objAcessor: { categoryIDs: [] },
        type: 'categories' as const,
        // in: this.getCategoryFilter(),
        in: [],
        presets: this.getCategoryFilterPreset(),
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { _computed: { pageviews: 0 } } as AsidDB,
        collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
        queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
        objAcessor: { _computed: { pageviews: 0 } } as AsidDB,
        type: 'exact-number' as const,
        in: [],
        range: [],
        notBackendSortable: false
        // only first 3 (allow filter for 6) identifers have full sort option. are filterable though
      }, ...Object.entries(this.$backendConfig.asid.identifierDefinition)
        // .filter(([key, def]) => +key[1] <= 5)
        .map(([identifierKey, identDef]) => {
          return {
            fieldAccesor: { identifierValue: { [identifierKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
            collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
            objAcessor: { identifierValue: { [identifierKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            // if the dataype is a number, use 'exact-number' filter type
            type: identDef.datatype === 'number' ? 'exact-number' as const : 'exact' as const,
            in: [],
            range: [],
            notBackendSortable: false,
            // if the datatype is a number, use set hideRange to true
            hideRange: identDef.datatype === 'number'
          }
        }),
      // filterconig for attributes
      ...Object.entries(this.$backendConfig.asid.assetAttributeDefinitions)
        .map(([attributeKey, attributeDef]) => {
          return {
            fieldAccesor: { assetAttributeValue: { [attributeKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
            collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
            objAcessor: { assetAttributeValue: { [attributeKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            // if the dataype is a number, use 'exact-number' filter type
            type: attributeDef.datatype === 'number' ? 'exact-number' as const : 'exact' as const,
            in: [],
            range: [],
            notBackendSortable: false,
            // if the datatype is a number, use set hideRange to true
            hideRange: attributeDef.datatype === 'number'
          }
        })

    ]
  }

  @Watch('$localSettings.modules.filters.categories', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats')
  @Watch('$localSettings.modules.filters.categoriesIncludeParentCats')
  @Watch('$categories', { deep: true })
  private onChangeGlobalCategoryFilter() {
    const catConfigIndex = this.table_filterConfig.findIndex((fc) => fc.type === 'categories')

    if (catConfigIndex >= 0) {
      (this.table_filterConfig[catConfigIndex].presets as string[]) = this.getCategoryFilterPreset()
    }
  }

  private getCategoryFilter() {
    // if no local filter is set, use the global one
    if (this.$localSettings.asidlist.filter.categories.length === 0) {
      const filterInputCagegories = [...this.$localSettings.modules.filters.categories]

      // add parent categories if configured in local settings
      if (this.$localSettings.modules.filters.categoriesIncludeParentCats) {
        const parentCats = CategoryHelper.getAllParentCategoriesArray(this.$localSettings.modules.filters.categories, this.$categories)
        filterInputCagegories.push(...parentCats)
      }

      // add child categories if configured in local settings
      if (this.$localSettings.modules.filters.categoriesIncludeChildCats) {
        const childCats = CategoryHelper.getAllChildCategoriesArray(this.$localSettings.modules.filters.categories, this.$categories)
        filterInputCagegories.push(...childCats)
      }

      console.log('filterInputCagegories', filterInputCagegories)

      return filterInputCagegories
    } else {
      return this.$localSettings.asidlist.filter.categories
    }
  }

  private getCategoryFilterPreset() {
    return CategoryHelper.getFilteredCategories(
      this.$categories,
      this.$localSettings.modules.filters.categories,
      this.$auth.user?.visibleCategories || [],
      this.$localSettings.modules.filters.categoriesIncludeParentCats,
      this.$localSettings.modules.filters.categoriesIncludeChildCats
    )
  }

  public beforeDestroy() {
    if (this.unsubscribeSnapshot) this.unsubscribeSnapshot()
    if (this.onActivatedAsidsHandle) this.onActivatedAsidsHandle()
  }


  // #region import/export

  /**
   * convert doc property to string or object or array
   * exportFormatter(doc): object|string|[]
   *
   * apply exported doc property to doc
   * importFormatter(import: object|string|[], doc)
   */
  public importExport_importExportDefinitions: typeImportExportDefinitions<AsidDB & hasDBid> = [
    {
      readOnly: true,
      exportColumnName: 'Activated',
      exportFormatter: (me) => me.activated,
      importFormatter: (imp: string, me) => me.activated = !!imp
    },
    {
      readOnly: true,
      exportColumnName: 'DateActivated',
      exportFormatter: (me) => me.dateActivated ? me.dateActivated.toDate().toString() : 'not activated'
    },
    {
      readOnly: true,
      exportColumnName: 'DateUpdated',
      exportFormatter: (me) => me._meta.dateUpdated.toDate().toString()
    },
    {
      readOnly: false,
      exportColumnName: 'CategoryNames',
      exportFormatter: (me) => me.categoryIDs.map((cid) => this.$getCategoryName(cid)),
      importFormatter: (imp: string[], me) => me.categoryIDs = imp.filter((catName) => catName).map((catName) => this.$getCategoryID(catName))
    },
    {
      readOnly: false,
      exportColumnName: 'Identifiers',
      exportFormatter: (me) => Object.fromEntries(
        Object.entries(this.$backendConfig.asid.identifierDefinition)
          // sort first by order and if order is the same, sort by __identifierKey__
          .sort(([keyA, valueA], [keyB, valueB]) => valueA.order - valueB.order || keyA.localeCompare(keyB, 'en', { numeric: true }))
          .filter(([key, value]) => value.name)
          .map(([key, value]) => [value.name, me.identifierValue[key as isIdentifierKey]])
      ),
      importFormatter: (imp: {
        [k: string]: identifierValueType
      }, me) => {
        Object.entries(this.$backendConfig.asid.identifierDefinition)
          .filter(([key, identDef]) => identDef.name)
          .forEach(([key, identDef]) => {
            if (identDef.name in imp)
              me.identifierValue[key as isIdentifierKey] = convertToNullOrString(imp[identDef.name])
          })
      }
    },
    // assetAttributes
    {
      readOnly: false,
      exportColumnName: 'AssetAttributes',
      exportFormatter: (me) => Object.fromEntries(
        Object.entries(this.$backendConfig.asid.assetAttributeDefinitions)
          // sort first by order and if order is the same, sort by __identifierKey__
          .sort(([keyA, valueA], [keyB, valueB]) => valueA.order - valueB.order || keyA.localeCompare(keyB, 'en', { numeric: true }))
          .filter(([key, value]) => value.name)
          .map(([key, value]) => [value.name, me.assetAttributeValue[key as isAssetAttributeKey]])
      ),
      importFormatter: (imp: {
        [k: string]: assetAttributeValueType
      }, me) => {
        Object.entries(this.$backendConfig.asid.assetAttributeDefinitions)
          .filter(([key, identDef]) => identDef.name)
          .forEach(([key, identDef]) => {
            if (identDef.name in imp)
              me.assetAttributeValue[key as isAssetAttributeKey] = convertToNullOrString(imp[identDef.name])
          })
      }
    },
    {
      readOnly: false,
      exportColumnName: 'PublishingState',
      exportFormatter: (me) => me.publishingState,
      importFormatter: (imp: string, me) => {
        const isPublishingState = (string: any): string is PublishingState =>
          ['draft', 'published', 'archived', 'deleted'].includes(string)

        if (!isPublishingState(imp)) {
          throw `Valid values for publishingState are ('draft' , 'published' , 'archived' , 'deleted'). The supplied value was ${imp}`
        }

        me.publishingState = imp
      }
    }, {
      readOnly: true,
      exportColumnName: 'FormResponses',
      exportFormatter: (me) => me._computed.responseCountPerModule.form
    }, {
      readOnly: true,
      exportColumnName: 'FileResponses',
      exportFormatter: (me) => me._computed.responseCountPerModule.file
    }, {
      readOnly: true,
      exportColumnName: 'ViewCount',
      exportFormatter: (me) => me._computed.pageviews
    }, {
      readOnly: true,
      exportColumnName: 'Echo Code Url',
      exportFormatter: (me) => AsidManager.createLink(me.id, this.$backendConfig.asid.baseUrl || undefined)
    }
  ]


  public importExport_validateImportedData(importedData: Partial<hasDBid>[]) {
    // if ('categoryIDs' in importedData && 'categoryNames' in importedData)
    // throw 'Only categoryIDs or categoryNames may be in the imported data. Remove the column you do not want to import'
  }

  public async importExport_beforeGetCurrentData(importedData: hasDBid[]): Promise<hasDBid[]> {
    // if an imported dataset hat the id 'new_<something>', it is a custom number range asid that needs to be registered
    // filter them all out, remove the prefix and call the cloud function to register them
    this.importIsLoading = true
    // show toast that importing is ongoing
    let savingToast = this.$buefy.toast.open({
      indefinite: true,
      message: 'importing data ...',
      queue: false
      // type: 'is-info'
    })
    const modifiedImportData: hasDBid[] = []

    const asidIDsToRegister: string[] = []
    for (const data of importedData) {
      if (data.id?.startsWith('new_')) {
        const newID = data.id.replace('new_', '')
        modifiedImportData.push({ ...data, id: newID })
        asidIDsToRegister.push(newID)
      } else {
        modifiedImportData.push(data)
      }
    }

    if (asidIDsToRegister.length === 0) {
      savingToast.close()
      this.importIsLoading = false
      return modifiedImportData
    }

    // call the cloud function to register the new asids
    const registerAsidsResponse = await AsidManager.backendRegisterAsid(this.$auth.tenantID, asidIDsToRegister)

    savingToast.close()

    const asidsWithRegistrationError = registerAsidsResponse.asidIdRegistrationStatus.filter((status) => status.status === 'ERROR_ALREADY_REGISTERED')

    // ask the user if they want to override the already registered asids
    if (asidsWithRegistrationError.length > 0) {
      const response = await new Promise((resolve, reject) => {
        this.$buefy.dialog.confirm({
          title: 'ECHO Codes already registered',
          message: `The following ECHO Codes are already registered: <br><br>${asidsWithRegistrationError.map((status) => `<b>${status.asidID}</b>`).join('<br>')} <br><br> Do you want to override the existing registrations?`,
          type: 'is-warning',
          hasIcon: true,
          onCancel: () => {
            resolve('cancel')
          },
          onConfirm: () => {
            resolve('confirm')
          }
        })
      })

      if (response === 'cancel') {
        // filter out the asids that are already registered
        for (const response of asidsWithRegistrationError) {
          const index = modifiedImportData.findIndex((data) => data.id === response.asidID)
          if (index >= 0) {
            modifiedImportData.splice(index, 1)
          }
        }
      }
    }

    const asidsWithOtherError = registerAsidsResponse.asidIdRegistrationStatus.filter((status) => status.status !== 'ERROR_ALREADY_REGISTERED' && status.status !== 'OK')

    // show a modal dialog with the problematic asids
    if (asidsWithOtherError.length > 0) {
      await new Promise((resolve, reject) => {
        this.$buefy.dialog.alert({
          title: 'Error registering ECHO Codes',
          message: `The following ECHO Codes could not be registered: <br><br>${asidsWithOtherError.map((status) =>
            `<b>${status.asidID}</b>: (${status.errorMessage})`
          ).join('<br>')} <br><br> Please correct the errors and try again. The remaining codes will be imported.`,
          type: 'is-danger',
          hasIcon: true,
          onCancel: () => {
            resolve(1)
          },
          onConfirm: () => {
            resolve(1)
          }
        })
      })
    }

    // filter out asids with other errors
    // iterate the responses and remove failed imports from the data
    for (const response of asidsWithOtherError) {
      const index = modifiedImportData.findIndex((data) => data.id === response.asidID)
      if (index >= 0) {
        modifiedImportData.splice(index, 1)
      }
    }

    this.importIsLoading = false

    return modifiedImportData
  }

  public importExport_getDoc(importedData: Partial<hasDBid>) {
    return AsidManager.getDbDocReference(importedData.id || '').get()
  }

  public importExport_getDefaultDoc() {
    return AsidManager.defaultDocDB
  }

  public importExport_updateDocBatch(docId: string, docData: any, batch: firebase.firestore.WriteBatch) {
    return AsidManager.updateBatch(docId, this.$auth.userEmail, docData, batch)
  }

  // #endregion import/export
}
</script>

<style lang="scss">
.asid-column {
  font-family: monospace;
  white-space: nowrap;
}

.manage-asids-container {
  position: relative;

  .echo-code-container {
    width: 100%;
    max-width: 35em;
    height: 20em;
    // max-height: 20em;
  }

  // .tags:last-child {
  //   margin-bottom: inherit;
  // }
}

.progress {
  width: 100% !important;
}
</style>
