<template>
  <section class="data-definition-wrapper">
    <!-- show error message -->
    <b-message v-if="errorMessage" type="is-danger">{{ errorMessage }}</b-message>
    <b-table
      :paginated="Object.keys(dataDefinitionObjectLocal).length > 10"
      pagination-simple
      :per-page="formPaginationPerPage"
      :current-page.sync="formPaginationCurrentPage"
      :draggable="editedElement === null"
      :data="sortedDataDefinitionKeysLocal"
      @dragstart="dragstart"
      @drop="drop"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        placeholder="Search..."
        icon="search"
        size="is-small"
      />

      <b-table-column
        v-for="(column) in dataDefinitionTableColumns"
        :key="column.field"
        :visible="column.visible"
        :label="column.label"
        :sortable="column.sortable"
      >
        <template v-if="column.tooltip" #header>
          {{ column.label }}
          <VTooltipIconHelp :text="column.tooltip" />
        </template>

        <template #default="props">
          <template v-if="editedElement === (props.row) && column.field !== '__key__' ">
            <b-field v-if="column.type === 'allowEmpty'">
              <b-checkbox
                v-if="dataDefinitionObjectLocal[props.row].defaultValue !== ''"
                :disabled="true"
                :value="false"
                title="empty value cannot be set as the default value will be used"
              />
              <!-- if a validator is set, show a checkbox  to allow or deny empty values -->
              <b-checkbox
                v-else
                v-model="dataDefinitionObjectLocal[props.row].allowEmpty"
                expanded
                title="allow empty value to set"
              />
            </b-field>

            <b-field v-if="column.type === 'allowSavingInvalid'">
              <b-checkbox
                v-model="dataDefinitionObjectLocal[props.row].allowSavingInvalid"
                expanded
                title="allow empty value to set"
              />
            </b-field>

            <!-- categories -->
            <b-field v-else-if="column.field === 'categories'">
              <VInputMultiCategorySelection
                v-model="dataDefinitionObjectLocal[props.row][column.field]"
                class="is-small"
                style="max-width: 300px; min-width: 200px;"
                :categories-doc="$categories"
              />
            </b-field>

            <b-field v-else-if="column.field === 'defaultValue'">
              <!-- dropdown with choices -->
              <b-select
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_choices'"
                v-model="dataDefinitionObjectLocal[props.row].defaultValue"
                size="is-small"
                placeholder="text"
              >
                <option :value="''">No default value</option>
                <option
                  v-for="choice of dataDefinitionObjectLocal[props.row].validator.choices"
                  :key="choice"
                  :value="choice"
                >{{ choice }}</option>
              </b-select>

              <!-- if datatype is boolean show a dropdown with true, false and no default value -->
              <b-select
                v-else-if="dataDefinitionObjectLocal[props.row].datatype === 'boolean'"
                v-model="dataDefinitionObjectLocal[props.row].defaultValue"
                size="is-small"
                placeholder="text"
              >
                <option :value="''">No default value</option>
                <option value="true">true</option>
                <option value="false">false</option>
              </b-select>

              <!-- if dataype is email, only allow valid emails as default -->
              <b-input
                v-else-if="dataDefinitionObjectLocal[props.row].datatype === 'email'"
                ref="validate-input-email"
                v-model="dataDefinitionObjectLocal[props.row].defaultValue"
                size="is-small"
                type="email"
                placeholder="no default value"
              />

              <b-input
                v-else-if="dataDefinitionObjectLocal[props.row].validatorType !== 'validatorType_pattern' && dataDefinitionObjectLocal[props.row].datatype !== 'image'"
                v-model="dataDefinitionObjectLocal[props.row].defaultValue"
                :type="dataDefinitionObjectLocal[props.row].datatype === 'number' ? 'number' : 'text'"
                size="is-small"
                placeholder="no default value"
              />
            </b-field>

            <b-field v-else-if="column.type !== 'validator'">
              <b-input
                v-if="column.field === 'name'"
                v-model="dataDefinitionObjectLocal[props.row][column.field]"
                size="is-small"
                pattern="[a-zA-Z0-9_\-]*"
                validation-message="Only letters, numbers, '_' and '-' are allowed"
                :placeholder="dataDefinitionNameSuggestions[props.row]"
              />

              <b-input
                v-else-if="column.type === 'text'"
                v-model="dataDefinitionObjectLocal[props.row][column.field]"
                size="is-small"
                :placeholder="`[${column.field}]`"
              />

              <b-select
                v-else-if="column.field === 'datatype'"
                v-model="dataDefinitionObjectLocal[props.row][column.field]"
                size="is-small"
                placeholder="text"
                @input="(val)=>onSelectDatatype(props.row, val)"
              >
                <option
                  v-for="([key,title]) in Object.entries(column.options)"
                  :key="key"
                  :value="key"
                >{{ title }}</option>
              </b-select>

              <b-select
                v-else-if="column.type === 'dropdown'"
                v-model="dataDefinitionObjectLocal[props.row][column.field]"
                size="is-small"
                placeholder="text"
              >
                <option
                  v-for="([key,title]) in Object.entries(column.options)"
                  :key="key"
                  :value="key"
                >{{ title }}</option>
              </b-select>
            </b-field>

            <b-field v-else-if="column.type === 'validator'" class="has-addons">
              <b-select
                size="is-small"
                :value="dataDefinitionObjectLocal[props.row].validatorType"
                placeholder="Validator"
                @input="(val)=>onAddValidator(props.row, val)"
              >
                <optgroup label="Validator">
                  <option
                    v-for="option in getPossibleValidatorsBasedOnDatatype(dataDefinitionObjectLocal[props.row].datatype)"
                    :key="option.key"
                    :value="option.key"
                  >{{ option.value }}</option>
                </optgroup>
              </b-select>

              <p
                v-if="dataDefinitionObjectLocal[props.row].validatorType !== 'validatorType_none'"
                class="control"
              >
                <b-button
                  title="Remove validator"
                  size="is-small"
                  outlined
                  icon-right="trash"
                  @click="onAddValidator(props.row)"
                />
              </p>

              <b-input
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_minMax'"
                v-model="dataDefinitionObjectLocal[props.row].validator.minMax[0]"
                size="is-small"
                :step="0.000001"
                style="min-width: 5rem;"
                :type="dataDefinitionObjectLocal[props.row].datatype"
                placeholder="min value"
              />

              <b-input
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_minMax'"
                v-model="dataDefinitionObjectLocal[props.row].validator.minMax[1]"
                size="is-small"
                :step="0.000001"
                style="min-width: 5rem;"
                :type="dataDefinitionObjectLocal[props.row].datatype"
                placeholder="max value"
              />

              <b-input
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_regex'"
                v-model="dataDefinitionObjectLocal[props.row].validator.regex"
                size="is-small"
                style="min-width: 5rem;"
                placeholder="regex"
              />

              <b-taginput
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_choices'"
                v-model="dataDefinitionObjectLocal[props.row].validator.choices"
                size="is-small"
                placeholder="choices"
              />

              <!-- value validator based on datatype -->
              <b-numberinput
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_value' && dataDefinitionObjectLocal[props.row].datatype === 'number'"
                v-model="dataDefinitionObjectLocal[props.row].validator.value"
                size="is-small"
                placeholder="value"
                style="min-width: 8rem;"
                :step="0.000001"
              />

              <b-input
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_value' && dataDefinitionObjectLocal[props.row].datatype === 'string'"
                v-model="dataDefinitionObjectLocal[props.row].validator.value"
                size="is-small"
                style="min-width: 5rem;"
                placeholder="value"
              />

              <!-- <b-checkbox-button
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_value' && dataDefinitionObjectLocal[props.row].datatype === 'boolean'"
                v-model="dataDefinitionObjectLocal[props.row].validator.value"
                size="is-small"
                :true-value="'asds'"
                :native-value="'bla'"
                :false-value="'asdad'"
                placeholder="value"
              >
                <span>{{ dataDefinitionObjectLocal[props.row].validator.value }}</span>
              </b-checkbox-button>-->

              <p
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_value' && dataDefinitionObjectLocal[props.row].datatype === 'boolean'"
              >
                <b-button
                  title="Toggle valid value"
                  expanded
                  size="is-small"
                  @click="dataDefinitionObjectLocal[props.row].validator.value === 'true'
                    ? dataDefinitionObjectLocal[props.row].validator.value = 'false'
                    : dataDefinitionObjectLocal[props.row].validator.value = 'true'
                  "
                >{{ dataDefinitionObjectLocal[props.row].validator.value === 'true' ? 'true' : 'false' }}</b-button>
              </p>

              <b-input
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_pattern'"
                v-model="dataDefinitionObjectLocal[props.row].validator.pattern"
                size="is-small"
                expanded
                placeholder="pattern"
              />
            </b-field>
          </template>

          <template v-else>
            <span v-if="column.field === '__key__'" @click="toggleEditElement(props.row)">
              <b-tooltip
                v-if="+props.row[1] > 3 && showIdentifierWarning"
                label="Only the first 3 variables may be used for querying in the list ECHO CODEs view. Use this variable for data you don't want to filter by"
                multilined
                animated
                dashed
              >{{ props.row }}</b-tooltip>
              <span v-else>{{ props.row }}</span>
            </span>

            <span v-else-if="column.field === 'validator'" @click="toggleEditElement(props.row)">
              <span
                v-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_minMax'"
              >minMax: {{ dataDefinitionObjectLocal[props.row].validator.minMax.join(' - ') }}</span>
              <span
                v-else-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_regex'"
              >regex: {{ dataDefinitionObjectLocal[props.row].validator.regex }}</span>
              <span
                v-else-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_choices'"
              >choices: {{ dataDefinitionObjectLocal[props.row].validator.choices.join(', ') }}</span>
              <span
                v-else-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_pattern'"
              >pattern: {{ dataDefinitionObjectLocal[props.row].validator.pattern }}</span>
              <span
                v-else-if="dataDefinitionObjectLocal[props.row].validatorType === 'validatorType_value'"
              >value: {{ dataDefinitionObjectLocal[props.row].validator.value }}</span>
            </span>

            <!-- show allow empty state as disabled checkbox -->
            <b-checkbox
              v-else-if="column.field === 'allowEmpty' && dataDefinitionObjectLocal[props.row].defaultValue !== ''"
              :disabled="true"
              :value="false"
              @click="toggleEditElement(props.row)"
            />
            <b-checkbox
              v-else-if="column.field === 'allowEmpty'"
              :disabled="true"
              :value="dataDefinitionObjectLocal[props.row][column.field]"
              @click="toggleEditElement(props.row)"
            />

            <b-checkbox
              v-else-if="column.field === 'allowSavingInvalid'"
              :disabled="true"
              :value="dataDefinitionObjectLocal[props.row][column.field]"
              @click="toggleEditElement(props.row)"
            />

            <span
              v-else-if="column.type === 'dropdown'"
              @click="toggleEditElement(props.row)"
            >{{ column.options[dataDefinitionObjectLocal[props.row][column.field]] }}</span>

            <span
              v-else-if="column.field === 'name'"
              @click="toggleEditElement(props.row)"
            >{{ dataDefinitionObjectLocal[props.row][column.field] || dataDefinitionNameSuggestions[props.row] }}</span>

            <b-taglist
              v-else-if="column.type === 'categories'"
              @click="toggleEditElement(props.row)"
            >
              <b-tag
                v-if="dataDefinitionObjectLocal[props.row][column.field].length === 0"
                @click="toggleEditElement(props.row)"
              >All</b-tag>
              <template v-else>
                <b-tag
                  v-for="category in dataDefinitionObjectLocal[props.row][column.field]"
                  :key="category"
                  size="is-small"
                  @click="toggleEditElement(props.row)"
                >{{ $getCategoryName(category) }}</b-tag>
              </template>
            </b-taglist>

            <span
              v-else
              @click="toggleEditElement(props.row)"
            >{{ dataDefinitionObjectLocal[props.row][column.field] }}</span>
          </template>
        </template>
      </b-table-column>

      <b-table-column field="actions" label="Actions">
        <template #default="props">
          <b-field>
            <!-- <p v-if="editedElement === (props.row)" class="control">
                  <b-button
                    size="is-small"
                    icon-right="save"
                    type="is-success"
                    @click="toggleEditElement(props.row)"
                  />
            </p>-->
            <p class="control">
              <b-button
                :title="(editedElement === (props.row)) ? 'Save' : 'Edit'"
                size="is-small"
                :icon-right="(editedElement === (props.row)) ? 'save' : 'edit'"
                @click="toggleEditElement(props.row)"
              />
            </p>
          </b-field>
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'


import VRecordMeta from '@/components/VRecordMeta.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'

import { DataDefinition, DataDefinitionObject, isDataDefinitionKey } from '@/types/typeDataDefinition'

import { DataDefinitionValidatorType } from '@/types/typeDataDefinition'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'


@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VInputMultiCategorySelection,
    VFormConfigureSticker
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VFormDataDefinition extends Vue {
  @Model('update', { type: Object }) readonly dataDefinitionObject!: DataDefinitionObject

  @Prop({ type: Boolean, default: () => false }) readonly showIdentifierWarning!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly hideTypes!: DataDefinition['datatype'][]

  @Prop({ type: Boolean, default: () => false })
  readonly hideNames!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly hideValidator!: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly showCategoryVisibility!: boolean

  public dataDefinitionObjectLocal: DataDefinitionObject = {
    default: {
      title: 'sample', name: 'sample', datatype: 'string', allowEmpty: false,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }, categories: [], order: 0
    }
  }

  /** name suggestions based on title and removed special chars */
  public dataDefinitionNameSuggestions: { [key: string]: string } = {}

  @Watch('dataDefinitionObject', { deep: true, immediate: true })
  private onDataDefinitionChange() {
    this.dataDefinitionObjectLocal = cloneObject(this.dataDefinitionObject)
  }

  @Watch('dataDefinitionObjectLocal', { deep: true, immediate: true })
  private onDataDefinitionLocalChange() {
    // for each title generate a suggested name by replacing all special chars with underscores and transform to lowercase
    Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
      let suggestedName = dataDefinition.title
      suggestedName = suggestedName.replaceAll(/[^\w\s,;]/gi, '_')
      suggestedName = suggestedName.replaceAll(' ', '_')
      suggestedName = suggestedName.replaceAll('__', '_')
      suggestedName = suggestedName.toLowerCase()


      // if the key does not exists, set it reactively
      if (!this.dataDefinitionNameSuggestions[key as isDataDefinitionKey])
        this.$set(this.dataDefinitionNameSuggestions, key as isDataDefinitionKey, suggestedName)
      else
        this.dataDefinitionNameSuggestions[key as isDataDefinitionKey] = suggestedName
    })
  }

  get sortedDataDefinitionKeysLocal() {
    return Object.keys(this.dataDefinitionObjectLocal)
      .sort((a, b) => {
        // sort first by order, then by key
        if (this.dataDefinitionObjectLocal[a].order !== this.dataDefinitionObjectLocal[b].order) {
          return this.dataDefinitionObjectLocal[a].order - this.dataDefinitionObjectLocal[b].order
        } else {
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        }
      }
      )
  }


  // #region dataDefinition

  public editedElement: any = null

  public toggleEditElement(element: any) {
    // stop editing if there is currently an element being edited
    if (this.editedElement === element) {
      this.stopEditElement(this.editedElement)
    } else if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)

      // if stopping was indeed successful, start editing the new element
      if (this.editedElement === null) {
        this.editedElement = element
      }
    } else {
      this.editedElement = element
    }
  }

  // fn to be used from external to save the local data
  public $externalSave() {
    if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)
    }
    return this.errorMessage
  }

  public errorMessage = ''

  public stopEditElement(element: any) {
    console.debug('stopEditElement', element)
    // add data definition names if not given
    Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
      if (!dataDefinition.name && dataDefinition.title)
        this.$set(this.dataDefinitionObjectLocal, key as isDataDefinitionKey, { ...dataDefinition, name: this.dataDefinitionNameSuggestions[key as isDataDefinitionKey] })
    })

    // perform html input validation
    let everythingValid = true
    Object.keys(this.$refs as any).forEach((refName) => {
      const ref = this.$refs[refName] as any
      if (refName.startsWith('validate-input-') && ref)
        if (Array.isArray(ref)) {
          ref.forEach((r) => everythingValid = r.checkHtml5Validity() && everythingValid)
        } else {
          everythingValid = ref.checkHtml5Validity() && everythingValid
        }
    })

    if (!everythingValid) {
      this.errorMessage = 'Please correct the errors in the form'
    } else if (!Object.values(this.dataDefinitionObjectLocal).every((idef) => idef.name.match('^[a-zA-Z0-9_-]*$'))) {
      this.errorMessage = 'Names not valid. Only letters [aA-zZ], numbers [0-9] and [-,_,&] are allowed as names'
    } else if (Object.values(this.dataDefinitionObjectLocal).map((idef) => idef.name).filter((value, index, self) => self.indexOf(value) !== index).filter((value, index, self) => !!value).length > 0) { // check for no duplicate names
      this.errorMessage = `Names are not valid. Names must be unique, but the following names are used multiple times: ${Object.values(this.dataDefinitionObjectLocal).map((idef) => idef.name).filter((value, index, self) => self.indexOf(value) !== index).filter((value, index, self) => !!value).join(', ')}`
    } else {
      this.errorMessage = ''
      this.editedElement = null
      this.$emit('update', this.dataDefinitionObjectLocal)
    }
  }

  get valueTypeName() {
    switch (Object.keys(this.dataDefinitionObjectLocal)[0].charAt(0)) {
      case 'i':
        return 'identifier'
      case 'd':
        return 'data'
      case 'a':
        return 'attribute'
      default:
        return 'unknown'
    }
  }


  public get DATA_DEFINITION_DATATYPES(): { key: DataDefinition['datatype'], value: string }[] {
    return [
      { key: 'boolean' as DataDefinition['datatype'], value: 'Boolean' },
      { key: 'image' as DataDefinition['datatype'], value: 'Image' },
      { key: 'string' as DataDefinition['datatype'], value: 'String' },
      { key: 'number' as DataDefinition['datatype'], value: 'Number' },
      // { key: 'other'  as DataDefinition['datatype'], value: 'Other' },
      { key: 'auto_generated' as DataDefinition['datatype'], value: 'Auto Generated' },
      { key: 'gps' as DataDefinition['datatype'], value: 'GPS' },
      { key: 'email' as DataDefinition['datatype'], value: 'Email' }
    ].filter(({ key }) => !this.hideTypes.includes(key))
  }

  public DATA_DEFINITION_VALIDATOR_TYPES: { key: DataDefinitionValidatorType, value: string }[] = [
    { key: 'validatorType_minMax', value: 'Min Max' },
    { key: 'validatorType_regex', value: 'Regex' },
    { key: 'validatorType_choices', value: 'Choices' },
    { key: 'validatorType_pattern', value: 'Pattern' },
    { key: 'validatorType_value', value: 'Value' }
  ]

  public get dataDefinitionTableColumns() {
    return [{
      field: '__key__',
      label: 'Data ID',
      visible: true,
      type: 'text'
      // tooltip: 'The data ID is used '
    }, {
      //   field: 'order',
      //   label: 'order',
      //   visible: true,
      //   type: 'text',
      //   tooltip: 'The title is used for displaying purposes and as a column header'
      // }, {
      field: 'title',
      label: 'Title',
      visible: true,
      type: 'text',
      tooltip: 'The title is used for displaying purposes and as a column header'
    }, {
      field: 'name',
      label: 'Name',
      visible: !this.hideNames,
      type: 'text',
      tooltip: `The name can be used to programatically access the value e.g. {{ ${this.valueTypeName}.serial_number }}. Only letters [aA-zZ], numbers [0-9] and [-,_] are allowed as ${this.valueTypeName} names`
    },
    // {
    //   field: 'title',
    //   label: 'Title',
    //   visible: true,
    //   tooltip: 'The title is used for displaying purposes as a column heading in the response tab.',
    //   type: 'text'
    // },
    // {
    //   field: 'order',
    //   label: 'Order',
    //   visible: true,
    //   sortable:true,
    //   type: 'number'
    // },
    {
      field: 'datatype',
      label: 'Datatype',
      visible: true,
      type: 'dropdown',
      options: this.DATA_DEFINITION_DATATYPES.map(({ key, value }) => [key, value]).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    }, {
      field: 'validator',
      label: 'Validator',
      visible: !this.hideValidator,
      type: 'validator',
      tooltip: `Used to validate user input when setting the ${this.valueTypeName} value`
    },
    // default value
    {
      field: 'defaultValue',
      label: 'Default Value',
      visible: !this.hideValidator,
      type: 'text',
      tooltip: `The default value is used when no value is set for the ${this.valueTypeName}`
    },
    {
      field: 'allowEmpty',
      label: 'Allow Empty Value',
      visible: !this.hideValidator,
      type: 'allowEmpty',
      tooltip: `If set to true, the ${this.valueTypeName} value can be empty`
    },
    {
      field: 'allowSavingInvalid',
      label: 'Allow Saving Invalid',
      visible: !this.hideValidator,
      type: 'allowSavingInvalid',
      tooltip: `If set to true, the ${this.valueTypeName} value can be saved even if it is not matching the validator or is empty (if Allow Empty Value is set to false)`
    },
    {
      field: 'categories',
      label: 'Category Visibility',
      visible: !this.hideValidator && this.showCategoryVisibility,
      type: 'categories',
      tooltip: `The ${this.valueTypeName} will only be visible in the selected categories. Referencing works analog to Element referencing.`
    }]
  }
  // #endregion dataDefinition


  public getPossibleValidatorsBasedOnDatatype(datatype: DataDefinition['datatype']) {
    let validatorTypeKeys: DataDefinitionValidatorType[] = []
    switch (datatype) {
      case 'number':
        validatorTypeKeys = ['validatorType_minMax', 'validatorType_choices', 'validatorType_value']
        break
      case 'string':
        validatorTypeKeys = ['validatorType_regex', 'validatorType_choices', 'validatorType_regex', 'validatorType_value']
        break
      case 'boolean':
        validatorTypeKeys = ['validatorType_value']
        break
      case 'auto_generated':
        validatorTypeKeys = ['validatorType_pattern']
        break

      default:
        validatorTypeKeys = []
    }

    return this.DATA_DEFINITION_VALIDATOR_TYPES.filter((validatorType) => validatorTypeKeys.includes(validatorType.key))
  }


  public onSelectDatatype(dataDefinitionKey: isDataDefinitionKey, DataDefinitionDataType: DataDefinition['datatype']) {
    console.log('onSelectDatatype', dataDefinitionKey, DataDefinitionDataType)
    // reset the validator type
    this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType = 'validatorType_none'

    // if auto_generated, set the pattern
    if (DataDefinitionDataType === 'auto_generated') {
      this.onAddValidator(dataDefinitionKey, 'validatorType_pattern')
    }
  }

  public onAddValidator(dataDefinitionKey: isDataDefinitionKey, DataDefinitionValidatorType?: DataDefinitionValidatorType) {
    this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType = DataDefinitionValidatorType || 'validatorType_none'

    console.log('onAddValidator', dataDefinitionKey, DataDefinitionValidatorType, this.dataDefinitionObjectLocal[dataDefinitionKey].validatorType)


    switch (DataDefinitionValidatorType) {
      case 'validatorType_value':
        // switch based on datatype
        switch (this.dataDefinitionObjectLocal[dataDefinitionKey].datatype) {
          case 'number':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = 0
            break
          case 'string':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = ''
            break
          case 'boolean':
            this.dataDefinitionObjectLocal[dataDefinitionKey].validator.value = 'false'
            break
        }
        break
    }

    // if datatype is auto_generated, set validator to pattern
    if (this.dataDefinitionObjectLocal[dataDefinitionKey].datatype === 'auto_generated') {
      // add a sample pattern
      if (this.valueTypeName === 'identifier')
        this.dataDefinitionObjectLocal[dataDefinitionKey].validator.pattern = '{{category|childOf(\'All Categories\')}}_{{identifier.i1}}_{{functions|increment(3)}}'
      else if (this.valueTypeName === 'attribute')
        this.dataDefinitionObjectLocal[dataDefinitionKey].validator.pattern = 'WK{{functions|week()}}'
    }
  }

  // #endregion data definition

  // #region reordering

  public draggingRow: string = ''
  public draggingRowIndex: number = -1
  public formPaginationPerPage: number = 10
  public formPaginationCurrentPage: number = 1
  public formTempPaginationCurrentPage: number = 1

  public dragstart(payload: { row: string, index: number, event: DragEvent }) {
    this.draggingRow = payload.row
    this.draggingRowIndex = payload.index

    this.formTempPaginationCurrentPage = this.formPaginationCurrentPage
    this.formPaginationPerPage = 1000

    // payload.event.dataTransfer.effectAllowed = 'copy'
  }

  public dragover(payload: { event: any, index: number }) {
    // console.log(payload.event.target?.classList)
    // payload.event.dataTransfer.dropEffect = 'copy'
    payload.event.target?.closest('tr').classList.add('is-selected')
    payload.event.preventDefault()
  }

  public dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    payload.event.preventDefault()
  }

  public drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    const droppedOnRowIndex = payload.index
    // this.$buefy.toast.open(`Moved from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    this.formPaginationPerPage = 10
    this.formPaginationCurrentPage = this.formTempPaginationCurrentPage


    // if the element has no oder, set an order to all elements based on their index

    this.sortedDataDefinitionKeysLocal.forEach((key, index) => {
      const dataDefinition = this.dataDefinitionObjectLocal[key]
      dataDefinition.order = dataDefinition.order === 0 ? index + 1 : dataDefinition.order
    })


    // change the order property of the dragged element and all elements in between
    const draggedElement = this.dataDefinitionObjectLocal[this.draggingRow]
    const draggedElementOrder = draggedElement.order

    const droppedOnElement = this.dataDefinitionObjectLocal[this.sortedDataDefinitionKeysLocal[droppedOnRowIndex]]
    const droppedOnElementOrder = droppedOnElement.order

    console.log(draggedElementOrder, '>', droppedOnElementOrder)


    // if the dragged element is dropped on a higher index, move all elements between the dragged element and the dropped element one index up
    if (draggedElementOrder > droppedOnElementOrder) {
      Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
        if (dataDefinition.order >= droppedOnElementOrder && dataDefinition.order < draggedElementOrder) {
          dataDefinition.order++
        }
      })
    } else {
      // if the dragged element is dropped on a lower index, move all elements between the dragged element and the dropped element one index down
      Object.entries(this.dataDefinitionObjectLocal).forEach(([key, dataDefinition]) => {
        if (dataDefinition.order <= droppedOnElementOrder && dataDefinition.order > draggedElementOrder) {
          dataDefinition.order--
        }
      })
    }

    // set the order of the dragged element to the order of the dropped element
    draggedElement.order = droppedOnElementOrder

    this.$emit('update', this.dataDefinitionObjectLocal)
  }

  // #endregion reordering
}
</script>

<style lang="scss">
.data-definition-wrapper {
  .field.has-addons .control:not(:last-child) {
    margin-right: -1px;
  }

  .table-wrapper {
    overflow: visible;
  }

  // only applies for data-label="validator" column
  td[data-label='Validator'] span {
    word-break: break-word;
  }
}
</style>
