<template>
  <section>
    <!-- section if activateScanInterface is active -->
    <section
      ref="scan-interface"
      class="scan-interface"
      :class="{
        'is-active': isActivated
      }"
      @click="isActivated = true"
    >
      <!-- if this area is not focused, show info to focus it -->

      <div
        class="notification"
        :class="{
          'is-info': isActivated
        }">
        <p v-if="message !== ''" class="message">{{ message }}</p>

        <p v-if="!isActivated">
          Click here to activate the scan interface
          <br />The ECHO scan interface allow to quickly activate and assign ECHO CODEs by using a barcode or data matrix code scanner
        </p>

        <p v-else>
          <span class="blob white" />
          Scan Interface is active. Scan a barcode or data matrix code to perform actions
        </p>

        <!-- input to focus when active -->
        <input
          ref="input"
          v-model="scannerKeyboardTextInput"
          type="text"
          class="scan-input"
          @keydown.tab.prevent
          @blur="isActivated = false"
        />

        <div v-if="executionLog.length > 0" class="action-log">
          Action Log
          <div
            v-for="(executedCmd,i) in executionLog"
            :key="i"
            class="action-log-entry"
            :class="{
              'is-info': executedCmd.type === 'info',
              'is-danger': executedCmd.type === 'error'
            }"
          >
            <b>{{ executedCmd.title }}</b>
            : {{ executedCmd.message }}
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ScanInterface, ScanInterfaceCmdCallback } from '@/helpers/scanInterface'

@Component({
  components: {}
})
export default class VScanInterface extends Vue {
  @Prop({ type: Array, required: true, default: () => [] })
  private cmdCallbacks!: ScanInterfaceCmdCallback[]

  // if true, the scan interface will not execute any command
  @Prop({ type: Boolean, required: false, default: false })
  private dryRun!: boolean

  // #region scan interface
  public scannerKeyboardTextInput = ''
  public isActivated: boolean = true
  public executionLog: ({ title: string, message: string, type: 'info' | 'error' })[] = []

  public message = ''

  @Watch('scannerKeyboardTextInput')
  private handleScanInterfaceInput() {
    const allCallbacks: ScanInterfaceCmdCallback[] = [
      {
        name: 'show-message',
        fn: (cmd) => {
          this.message = cmd.value
        }
      },
      ...this.cmdCallbacks
    ]

    const SI = ScanInterface(allCallbacks, (executedCmd) => {
      this.executionLog.push({
        title: executedCmd.name,
        message: executedCmd.argument + ' ' + executedCmd.value,
        type: 'info'
      })
      // show toast
      this.$buefy.toast.open({
        indefinite: false,
        pauseOnHover: true,
        message: 'Executed command: ' + executedCmd.name + ': ' + executedCmd.argument + ' ' + executedCmd.value,
        type: 'is-info',
        queue: false
      })

      this.message = ''
    }, (error) => {
      console.log(error)
      // show notification
      this.$buefy.toast.open({
        type: 'is-danger',
        message: error.toString(),
        queue: false
      })

      this.executionLog.push({
        title: 'Error',
        message: error.toString(),
        type: 'error'
      })
    })

    this.scannerKeyboardTextInput = SI.processText(this.scannerKeyboardTextInput)
  }


  @Watch('isActivated', { immediate: true })
  private onIsActivatedChanged() {
    if (!this.$refs.input) return

    if (this.isActivated) {
      // focus the input

      console.log('focus')
      // next tick
      this.$nextTick(() => {
        (this.$refs.input as any).focus()
      })
    } else {
      // blur the input
      (this.$refs.input as any).blur()
    }
  }

  public mounted() {
    this.onIsActivatedChanged()
  }


  public beforeDestroy() {
    // document.body.removeEventListener('click', this.onClickEvent)
  }
  // #endregion scan interface
}


</script>

<style lang="scss">
.scan-interface {
  margin: 2rem 0;
  cursor: pointer;

  p.message {
    background: #00000030;
    padding: 1rem;
  }

  .notification {
    border: 2px solid #209dee;
  }

  .action-log {
    border: 1px solid #00000024;
    background: #ffffff29;
    padding: 1rem;
    border-radius: 0.4rem;

    .action-log-entry {
      background: #00000034;
      padding: 0.3rem 0.6rem;
      margin: 0.4rem 0;
      border-radius: 0.2rem;

      &.is-danger {
        background: #ff004dbd;
      }
    }
  }

  &:not(.is-active) {
    .notification {
      border: 2px dashed #209dee;
    }
  }

  input.scan-input {
    background: #ffffffab;
    border: 2px solid white;
    border-radius: 0.6rem;
    padding: 0.6rem;
    width: 100%;
    margin: 1rem 0;
    font-size: 1rem;

    &:focus {
      outline-color: #209dee;
    }
  }

  .blob {
    background: black;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 100%);
    margin-right: 0.3em;
    height: 0.7em;
    width: 0.7em;
    transform: scale(1);
    animation: pulse-black 2s infinite;
  }

  .blob.white {
    background: white;
    box-shadow: 0 0 0 0 rgb(255 255 255 / 100%);
    animation: pulse-white 2s infinite;
  }

  @keyframes pulse-white {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgb(255 255 255 / 70%);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
}
</style>
