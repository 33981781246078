import merge from 'deepmerge'
import Category, {
  CategoryTree,
  CategoryCollection,
  CategoryItem,
  CategoryID,
  CategoryCollectionDocDB
} from '@/types/typeCategory'
import { uniqueID, typedPartialUpdatePayload } from './dbHelper'
import db, { serverTimestamp, increment } from '@/firebase'
import { TenantID } from '@/types/typeTenant'
import firebase from 'firebase/compat/app'
import { keys } from '@/helpers/globalHelpers'
import BaseManager from './baseManager'
import RecordMetaHelper from './recordMetaHelper'
import databaseSchema, { defaultCategoryItem } from './databaseSchema'
import { DeepPartial } from '@/types/typeGeneral'
import deepmerge from 'deepmerge'
import { CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import { cloneObject } from '@/helpers/dataShapeUtil'

export interface CategoryFlattened extends CategoryTree {
  path: string
}

export default class CategoryHelper extends BaseManager {
  public static defaultDocDB: CategoryCollectionDocDB = databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__EMPTY_DOC__
  public static defaultCategoryItem: CategoryItem = defaultCategoryItem
  public static requiredPrivileges = databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__PRIVILEGES__

  public static addCategory(tenantId: TenantID, authEmail: string, category: DeepPartial<CategoryItem>) {
    const id = uniqueID()
    const tmpcategory = merge(defaultCategoryItem, category as Partial<CategoryItem>)

    return this.updateDoc(
      this.getCategoriesDocRef(tenantId),
      authEmail,
      {
        collection: {
          [id]: tmpcategory
        }
      },
      true
    )
  }

  public static addCategoryBatch(
    tenantId: TenantID,
    authEmail: string,
    category: DeepPartial<CategoryItem>,
    batch: firebase.firestore.WriteBatch
  ) {
    const id = uniqueID()
    const tmpcategory = merge(defaultCategoryItem, category as Partial<CategoryItem>)

    return this.updateDocBatch(
      this.getCategoriesDocRef(tenantId),
      authEmail,
      {
        collection: {
          [id]: tmpcategory
        }
      },
      batch,
      true
    )
  }

  public static addCategories(tenantId: TenantID, authEmail: string, category: CategoryCollection) {
    const tmpCats: CategoryCollection = {}
    for (const key in category) {
      if (Object.prototype.hasOwnProperty.call(category, key)) {
        const element = category[key]
        tmpCats[key] = merge(defaultCategoryItem, element as Partial<CategoryItem>)
      }
    }

    return this.updateDoc(
      this.getCategoriesDocRef(tenantId),
      authEmail,
      {
        collection: tmpCats
      },
      true
    )
  }

  public static async removeCategory(
    tenantId: TenantID,
    authEmail: string,
    categoryID: CategoryID,
    removeChildren = false
  ) {
    // todo transaction
    const categoriesDocData = (await this.getCategoriesDocRef(tenantId).get()).data() as CategoryCollectionDocDB
    const childCategoryIds = Object.keys(categoriesDocData.collection).filter(
      (catId) => categoriesDocData.collection[catId].parentID === categoryID
    )

    // const updateInstruction: any = {
    //   [`collection.${categoryID}`]: firebase.firestore.FieldValue.delete()
    // }

    let updateInstruction = typedPartialUpdatePayload<CategoryCollectionDocDB>({
      collection: { [categoryID]: { publishingState: 'deleted' } }
    }) as any
    // set parent of category to children
    childCategoryIds.forEach(
      (childCatID) =>
        (updateInstruction = {
          ...updateInstruction,
          ...typedPartialUpdatePayload<CategoryCollectionDocDB>({
            collection: { [childCatID]: { parentID: categoriesDocData.collection[categoryID].parentID } }
          })
        })
      // (updateInstruction[`collection.${childCatID}`] = {
      //   ...categoriesDocData.collection[childCatID],
      //   parentID: categoriesDocData.collection[categoryID].parentID
      // })
    )

    if (removeChildren)
      // todo this does not work for grandchildren and so on.
      childCategoryIds.forEach(
        (childCatID) =>
          (updateInstruction[`collection.${childCatID}`] = typedPartialUpdatePayload<CategoryItem>({
            publishingState: 'deleted'
          }))
        // childCatID => (updateInstruction[`collection.${childCatID}`] = firebase.firestore.FieldValue.delete())
      )

    return this.getCategoriesDocRef(tenantId).update({
      ...updateInstruction,
      ...RecordMetaHelper.getUpdateMetaInstructions(serverTimestamp, increment, authEmail)
    })
  }

  public static async updateCategory(
    tenantId: TenantID,
    authEmail: string,
    categoryID: CategoryID,
    category: DeepPartial<CategoryItem>
  ) {
    // make sure the parent is not (below) the current category tree
    const categoriesDocData = (await this.getCategoriesDocRef(tenantId).get()).data() as CategoryCollectionDocDB
    let categoryDB = categoriesDocData.collection[categoryID]

    if (!categoryDB) throw `category with ID:"${categoryID}" not found`

    // hydrate category for sanity checks
    categoryDB = deepmerge(categoryDB, category) as CategoryItem

    let tempParentCatID = categoryDB.parentID
    while (categoriesDocData.collection[tempParentCatID]) {
      if (categoryID === tempParentCatID) throw 'Can not set parent to current category or children'

      tempParentCatID = categoriesDocData.collection[tempParentCatID].parentID
    }

    // make sure parent category exists
    if (!categoriesDocData.collection[categoryDB.parentID]) category.parentID = ''

    return this.updateDoc<CategoryCollectionDocDB>(this.getCategoriesDocRef(tenantId), authEmail, {
      collection: {
        [categoryID]: category
      }
    })
  }

  public static async updateCategoryBatch(
    tenantId: TenantID,
    authEmail: string,
    categoryID: CategoryID,
    category: DeepPartial<CategoryItem>,
    categoriesDocData: CategoryCollectionDocDB,
    batch: firebase.firestore.WriteBatch
  ) {
    // make sure the parent is not (below) the current category tree
    let categoryDB = categoriesDocData.collection[categoryID]

    if (!categoryDB) throw `category with ID:"${categoryID}" not found`

    // hydrate category for sanity checks
    categoryDB = deepmerge(categoryDB, category) as CategoryItem

    let tempParentCatID = categoryDB.parentID
    while (categoriesDocData.collection[tempParentCatID]) {
      if (categoryID === tempParentCatID) throw 'Can not set parent to current category or children'

      tempParentCatID = categoriesDocData.collection[tempParentCatID].parentID
    }

    // make sure parent category exists
    if (!categoriesDocData.collection[categoryDB.parentID]) category.parentID = ''

    this.updateDocBatch<CategoryCollectionDocDB>(
      this.getCategoriesDocRef(tenantId),
      authEmail,
      {
        collection: {
          [categoryID]: category
        }
      },
      batch
    )
  }

  public static async updateCategories(
    tenantId: TenantID,
    authEmail: string,
    categories: { [key: CategoryID]: DeepPartial<CategoryItem> }
  ) {
    // make sure the parent is not (below) the current category tree
    const categoriesDocData = (await this.getCategoriesDocRef(tenantId).get()).data() as CategoryCollectionDocDB

    for (const categoryID in categories) {
      if (categoryID in categories) {
        const category = categoriesDocData.collection[categoryID]

        if (!category) throw `category with ID:"${categoryID}" not found`

        let tempCatID = category.parentID
        while (categoriesDocData.collection[tempCatID]) {
          if (categoryID === tempCatID) throw 'Can not set parent to current category or children'

          tempCatID = categoriesDocData.collection[tempCatID].parentID
        }

        // make sure parent category exists
        if (!categoriesDocData.collection[category.parentID]) categories[categoryID].parentID = ''
      }
    }

    return this.updateDoc<CategoryCollectionDocDB>(this.getCategoriesDocRef(tenantId), authEmail, {
      collection: categories
    })
  }

  public static getCategoriesDocRef(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__DOCUMENT_PATH__(tenantId))
  }

  // did not work propery since when loggin out and in some reference was still there to the old snapshot listener. see simplifier version below
  // intiial idea was to only have onse snapshot listener for multiple request to categoreis. is now implemented using a global categoriesvariable
  // private static getCategoriesDocSnapshotInstance: CallbackHelper<CategoryCollection>
  // private static getCategoriesDocSnapshotInstanceTenantID: string // reset callback when tenantID changed
  // public static getCategoriesDocSnapshot(
  //   tenantId: TenantID,
  //   cb: (catDoc: CategoryCollection) => void,
  //   errCb?: (e: any) => void
  // ) {
  //   if (this.getCategoriesDocSnapshotInstanceTenantID !== tenantId) {
  //     this.getCategoriesDocSnapshotInstanceTenantID = tenantId

  //     if (this.getCategoriesDocSnapshotInstance) {
  //       this.getCategoriesDocSnapshotInstance.removeAllListeners()
  //     } else {
  //       this.getCategoriesDocSnapshotInstance = new CallbackHelper()
  //     }

  //     this.getCategoriesDocRef(tenantId).onSnapshot(
  //       this.getCategoriesDocSnapshotInstance.cbProxy((snapshot: firebase.firestore.DocumentSnapshot) => {
  //         return (snapshot.data() as CategoryCollectionDocDB).collection
  //       }),
  //       errCb
  //     )
  //   }

  //   return this.getCategoriesDocSnapshotInstance.onSnapshot(docArr => cb(docArr[0]))
  // }

  public static getCategoriesCollectionSnapshot(
    tenantId: TenantID,
    cb: (catDoc: CategoryCollection) => void,
    errCb?: (e: any) => void
  ) {
    return this.getCategoriesDocRef(tenantId).onSnapshot((snapshot) => {
      cb((snapshot.data() as CategoryCollectionDocDB).collection)
    }, errCb)
  }

  public static async getCategoriesCollection(tenantId: TenantID) {
    const catDoc = await this.getCategoriesDocRef(tenantId).get()
    if (!catDoc.exists) throw `no category  doc found for tenantID: ${tenantId}. [2022022634]`
    return (catDoc.data() as CategoryCollectionDocDB).collection
  }

  // public static idMap(categories: Array<Category>) {
  //   return new Map(categories.map(i => [i.id, i]))
  // }

  // public static idObjectMap(categories: Array<Category>): CategoryCollection {
  //   let tmpCatObject: CategoryCollection = {}
  //   for (const category of categories) {
  //     tmpCatObject[category.id] = category
  //   }
  //   return tmpCatObject
  // }

  public static getAllParentCategories(childCategories: CategoryID[], allCategories: CategoryCollection) {
    const allParentCategories: Map<CategoryID, CategoryItem> = new Map()

    function traverseUp(categoryID: CategoryID) {
      allParentCategories.set(categoryID, allCategories[categoryID])
      if (allCategories[categoryID]?.parentID) traverseUp(allCategories[categoryID].parentID)
    }

    childCategories.forEach((C) => traverseUp(C))

    return allParentCategories
  }

  public static getAllParentCategoriesCollection(
    childCategories: CategoryID[],
    allCategories: CategoryCollection
  ): CategoryCollection {
    const allParentCategories: CategoryCollection = {}

    function traverseUp(categoryID: CategoryID) {
      allParentCategories[categoryID] = allCategories[categoryID]
      if (allCategories[categoryID]?.parentID) traverseUp(allCategories[categoryID].parentID)
    }

    childCategories.forEach((C) => traverseUp(C))

    return allParentCategories
  }

  /**
   * returns true if the element is active for the given asid
   *
   * @param elementCategories
   * @param asidCategories
   * @param categories
   * @returns
   */
  public static isElementActiveForAsidRef(
    elementCategories: CategoryID[],
    asidCategories: CategoryID[],
    categories: CategoryCollection
  ) {
    const allParentCategories = this.getAllParentCategories(asidCategories, categories)
    return elementCategories.some((c) => allParentCategories.has(c))
  }

  /**
   * returns an array of all unique parent categories of a given category including the category itself
   *
   * @param childCategories
   * @param allCategories
   * @returns
   */
  public static getAllParentCategoriesArray(childCategories: CategoryID[], allCategories: CategoryCollection) {
    const allParentCategories: CategoryID[] = []

    function traverseUp(categoryID: CategoryID) {
      if (!allParentCategories.includes(categoryID)) allParentCategories.push(categoryID)
      if (allCategories[categoryID]?.parentID) traverseUp(allCategories[categoryID].parentID)
    }

    childCategories.forEach((C) => traverseUp(C))

    return allParentCategories
  }

  /**
   * returns a map of all unique child categories of a given category including the category itself
   *
   * @param childCategories
   * @param allCategories
   * @returns
   */
  public static getAllChildCategories(childCategories: CategoryID[], allCategories: CategoryCollection) {
    const allChildCategories: Map<CategoryID, CategoryItem> = new Map()

    function traverseDown(childCategoryIds: CategoryID[]) {
      childCategoryIds.forEach((cId) => {
        if (cId in allCategories) {
          allChildCategories.set(cId, allCategories[cId])
          traverseDown(keys(allCategories).filter((key) => allCategories[key].parentID === cId))
        }
      })
    }
    traverseDown(childCategories)

    return allChildCategories
  }

  /**
   *
   * returns an array of all unique child categories of a given category
   *
   * @param childCategories
   * @param allCategories
   * @returns
   */
  public static getAllChildCategoriesArray(
    childCategories: CategoryID[],
    allCategories: CategoryCollection
  ): CategoryID[] {
    const allChildCategories: CategoryID[] = []
    const allCategoryIDs = keys(allCategories)

    function traverseDown(childCategoryIds: CategoryID[]) {
      childCategoryIds.forEach((cId) => {
        if (cId in allCategories) {
          if (!allChildCategories.includes(cId)) allChildCategories.push(cId)
          const childCategories = allCategoryIDs.filter((key) => allCategories[key].parentID === cId)
          traverseDown(childCategories)
        }
      })
    }
    traverseDown(childCategories)

    return allChildCategories
  }

  public static getFlattenedData(categoryDoc: CategoryCollection) {
    return CategoryHelper.flattenedDataAndAssParentReference(CategoryHelper.buildCategoryTree(categoryDoc, []))
  }

  public static idObjectMapIncParentReference(categories: Array<Category>) {
    return this.flattenedDataAndAssParentReference(this.buildChildrenTree(categories))
  }

  public static toCategoriesArray(categories: CategoryCollection): Array<Category> {
    const tmpCatArray: Array<Category> = []
    for (const id in categories) {
      if (id in categories) {
        const element = categories[id]
        tmpCatArray.push({ ...element, id })
      }
    }

    return tmpCatArray
  }

  public static buildCategoryTree(categories: CategoryCollection, enabledCatIDs: CategoryID[] = []): CategoryTree {
    return this.buildChildrenTree(this.toCategoriesArray(categories), enabledCatIDs)
  }

  private static buildChildrenTree(categories: Array<Category>, enabledCatIDs: CategoryID[] = []): CategoryTree {
    // use collator for better performance
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    const addChildren = (el: CategoryTree) => {
      // find all elements whose parent is the current el
      const tmpChildren = categories
        .filter((d) => d.parentID === el.id)
        .sort((a, b) => collator.compare(a.name, b.name))

      // repeat for all children
      for (const child of tmpChildren) {
        addChildren(
          el.children[
            el.children.push({
              ...child,
              id: child.id,
              children: [],
              isDisabled: enabledCatIDs.length > 0 && !enabledCatIDs.includes(child.id),
              parentCategory: null
            }) - 1
          ]
        )
      }
    }

    const categoryDataTree: CategoryTree = {
      id: '',
      name: '$',
      parentCategory: null,
      parentID: '',
      children: [],
      isDisabled: false,
      description: '',
      publishingState: 'published',
      _computed: {
        linkedElements: 0,
        linkedAsids: 0
      }
    }
    addChildren(categoryDataTree)

    return { ...categoryDataTree }
  }

  public static flattenedDataAndAssParentReference(category: CategoryTree): { [k: string]: CategoryFlattened } {
    const flattenedData: { [k: string]: CategoryFlattened } = {}

    this.iterateCategoryTree(
      (cat, parent) => {
        cat.parentCategory = parent
        const path = (parent ? flattenedData[parent.id].path : '') + '/' + cat.name
        flattenedData[cat.id] = { ...cat, path }
      },
      category,
      null
    )

    return flattenedData
  }

  public static iterateCategoryTree(
    cb: (cat: CategoryTree, parent: CategoryTree | null) => void,
    rootCat: CategoryTree,
    parent: null | CategoryTree
  ) {
    cb(rootCat, parent)

    if (rootCat.children)
      for (const child of rootCat.children) {
        this.iterateCategoryTree(cb, child, rootCat)
      }
  }

  public static getRootCategoryID(flattenedData: { [k: string]: CategoryFlattened }): string {
    let tempCategory = flattenedData[Object.keys(flattenedData)[0]] // get random element
    if (!tempCategory) return ''

    // traverse up until root
    while (tempCategory.parentCategory) tempCategory = tempCategory.parentCategory as CategoryFlattened

    return tempCategory.id
  }

  public static filterCategoryIDsByEntryDefinition(
    categories: CategoryCollection,
    entryDefinition: Partial<CategoryEntryDefinitionObject>,
    definitionKey: keyof typeof entryDefinition,
    categoryIDs: CategoryID[],
    cache: { [definitionKey: string]: CategoryID[] } = {} // caches the brach categories for each definitionKey
  ): CategoryID[] {
    let branchCategories: CategoryID[] = []

    if (cache[definitionKey]) {
      branchCategories = cache[definitionKey]
    } else {
      const catEntryDef = entryDefinition[definitionKey]
      if (!catEntryDef) return []
      branchCategories = CategoryHelper.getAllChildCategoriesArray([catEntryDef.validator.pivotCategory], categories)
      cache[definitionKey] = branchCategories
    }

    return categoryIDs.filter((catID: CategoryID) => branchCategories.includes(catID))
  }

  /**
   * validates that the categories match the category entry definition
   */
  public static validateCategoryEntry(
    categoryCollection: CategoryCollection,
    categoryEntryDefinitions: Partial<CategoryEntryDefinitionObject>,
    enteredCategoryIDs: CategoryID[],
    cache: { [definitionKey: string]: CategoryID[] } = {} // caches the brach categories for each definitionKey
  ): [boolean, { [key: string]: { severity: 'error' | 'warning', text: string } }, boolean] {
    const validationErrorMessages: { [key: string]: { severity: 'error' | 'warning', text: string } } = {}

    for (const [key, value] of Object.entries(categoryEntryDefinitions)) {
      const selectedCategories = CategoryHelper.filterCategoryIDsByEntryDefinition(
        categoryCollection,
        categoryEntryDefinitions,
        key as keyof CategoryEntryDefinitionObject,
        enteredCategoryIDs,
        cache
      )
      const branchCategories = CategoryHelper.getAllChildCategoriesArray([value.validator.pivotCategory], categoryCollection)
      if (selectedCategories.length < value.validator.minCount) {
        validationErrorMessages[key] = {
          severity: 'error',
          text: `Please select at least ${value.validator.minCount} categories`
        }
      } else if (selectedCategories.length > value.validator.maxCount) {
        validationErrorMessages[key] = {
          severity: 'error',
          text: `Please select a maximum of ${value.validator.maxCount} categories`
        }
      } else if (selectedCategories.some((catID: CategoryID) => !branchCategories.includes(catID))) {
        validationErrorMessages[key] = {
          severity: 'error',
          text: `Please select a category from the branch of ${value.validator.pivotCategory}`
        }
      }
    }

    return [Object.keys(validationErrorMessages).length === 0, validationErrorMessages, true]
  }


  /**
   * returns a new category collection with only the categories that are in the branch of the pivot element, including the pivot element
   *
   * @param pivotCategoryID
   * @returns
   */
  public static getCategoryBranchBasedOnPivotElement(
    pivotCategoryID: CategoryID,
    categories: CategoryCollection
  ): CategoryCollection {
    const branchCategories = CategoryHelper.getAllChildCategoriesArray([pivotCategoryID], categories)
    branchCategories.push(pivotCategoryID)
    console.log('getCategoryBranchBasedOnPivotElement', branchCategories)

    const filteredCategories = Object.fromEntries(
      Object.entries(cloneObject(categories)).filter(([key, value]: [string, CategoryItem]) =>
        branchCategories.includes(key)
      )
    )

    console.log('getCategoryBranchBasedOnPivotElement', filteredCategories)

    // the pivot element is the new root category, so set its parent to ''
    if (pivotCategoryID in filteredCategories) filteredCategories[pivotCategoryID].parentID = ''
    return filteredCategories
  }

  /**
   * returns a new category ids array with only the categories that are in the branch of the pivot element, including the pivot element
   * the userVisibleCategories has precedence over the userCategoryFilter
   * only categories below the userVisibleCategories are returned
   * if userVisibleCategories is empty, the userCategoryFilter is used
   * if userVisibleCategories is set, the userCategoryFilter can be used to filter the userVisibleCategories
   *
   * @param allCcategories
   * @param userCategoryFilter
   * @param userVisibleCategories
   * @param includeParentCategories
   * @param includeChildCategories
   */
  public static getFilteredCategories(
    allCategories: CategoryCollection,
    userCategoryFilter: CategoryID[],
    userVisibleCategories: CategoryID[],
    includeParentCategories = false,
    includeChildCategories = false
  ): CategoryID[] {
    // if there are no filters active, return an empty array
    if (userCategoryFilter.length === 0 && userVisibleCategories.length === 0) return []

    const categoryBranch
      = userVisibleCategories.length === 0
        ? allCategories
        : userVisibleCategories
          .map((catID) => this.getCategoryBranchBasedOnPivotElement(catID, allCategories))
          .reduce((acc, val) => ({ ...acc, ...val }), {} as CategoryCollection)

    let categoryIDs: CategoryID[] = []

    if (userCategoryFilter.length === 0) {
      // if no filter is set, return all categories
      categoryIDs = Object.keys(categoryBranch)
    } else {
      if (!includeParentCategories && !includeChildCategories) categoryIDs = userCategoryFilter

      if (includeParentCategories) {
        categoryIDs.push(...this.getAllParentCategoriesArray(userCategoryFilter, allCategories))
      }
      if (includeChildCategories) {
        categoryIDs.push(...this.getAllChildCategoriesArray(userCategoryFilter, allCategories))
      }
    }

    return categoryIDs
  }

  /**
   *
   * @returns a category collection with only the categories that are visible to the user
   */
  public static getAvailableUserCategoriesCollection(
    allCategories: CategoryCollection,
    userVisibleCategories: CategoryID[] = []
  ) {
    if (userVisibleCategories.length > 0) {
      const visibleCategories = userVisibleCategories
      const visibleCategoriesTrees = visibleCategories.map((cat) => {
        return CategoryHelper.getCategoryBranchBasedOnPivotElement(cat, allCategories)
      })

      // convert array of cat trees to single object
      const visibleCategoriesFlat = visibleCategoriesTrees.reduce((acc, val) => {
        return {
          ...acc,
          ...val
        }
      }, {})

      return visibleCategoriesFlat
    } else {
      return allCategories
    }
  }
}
