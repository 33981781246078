<template>
  <section class="data-entry-wrapper">
    <div v-if="isReady" :class="!hideBox ? 'box form' : ''">
      <!-- show warn message -->
      <b-message
        v-if="errorMessageGlobal"
        type="is-danger"
        :has-icon="true"
        icon="alert-circle"
      >{{ errorMessageGlobal }}</b-message>
      <template v-for="(dataDefinition, i) in dataDefinitions">
        <div
          v-if="showDataEntryBasedOnCategories(dataDefinition) || dataValueObjectSync[dataDefinition.__identifierKey__] !== null"
          :key="i"
          class="identifier-wrapper"
        >
          <b-field
            :label="dataDefinition.title || dataDefinition.name"
            :title="`variable name: ${dataDefinition.name}, value: ${dataValueObjectSync[dataDefinition.__identifierKey__]}`"
            :horizontal="labelOnBorder ? false : true"
            :label-position="labelOnBorder ? 'on-border' : 'top'"
            expanded
            :message="
              validationErrorMessages[dataDefinition.__identifierKey__]
                || validationWarnMessages[dataDefinition.__identifierKey__]
                || (!showDataEntryBasedOnCategories(dataDefinition) && dataValueObjectSync[dataDefinition.__identifierKey__] !== null ? 'This field is not active for the current category but has data' : '')"
            :type="validationErrorMessages[dataDefinition.__identifierKey__] ? 'is-danger' : validationWarnMessages[dataDefinition.__identifierKey__] ? 'is-warning' : undefined"
          >
            <b-field
              expanded
              class="has-addons"
              :type="validationErrorMessages[dataDefinition.__identifierKey__] ? 'is-danger' : validationWarnMessages[dataDefinition.__identifierKey__] ? 'is-warning' : undefined"
            >
              <!-- if type is image, display an image picker -->
              <template v-if="dataDefinition.datatype === 'image'">
                <!-- if upload path is missing, show an error -->
                <b-message
                  v-if="(!uploadPath || !documentPath) && !disableImageUpload && !disabled"
                  type="is-danger"
                  :has-icon="true"
                  icon="alert-circle"
                >UploadPath prop is missing</b-message>

                <p class="control image-preview">
                  <template v-if="!dataValueObjectSync[dataDefinition.__identifierKey__]">
                    <button
                      :style="{backgroundImage: `repeating-linear-gradient(45deg, #aaa, #aaa 5px, #ddd 5px, #ddd 10px)`}"
                      style="width: 40px;"
                      class="button"
                    />
                  </template>
                  <template v-else>
                    <button
                      :style="{backgroundImage: `url(${dataValueObjectSync[dataDefinition.__identifierKey__]}), repeating-linear-gradient(45deg, #aaa, #aaa 5px, #ddd 5px, #ddd 10px)`}"
                      style="width: 40px;"
                      class="button"
                    />
                    <img
                      class="tile-image-preview"
                      style="background-image: repeating-linear-gradient(45deg, #aaa, #aaa 10px, #ddd 10px, #ddd 20px);"
                      :src="dataValueObjectSync[dataDefinition.__identifierKey__]"
                      alt
                    />
                  </template>
                </p>

                <p v-if="!disableImageUpload && !disabled" class="control tile-image-upload-button is-fullwidth">
                  <b-button
                    icon-left="upload"
                    expanded
                    @click="imageUploadModalActiveForDataKey = dataDefinition.__identifierKey__"
                  >{{ dataValueObjectSync[dataDefinition.__identifierKey__] ? 'Change Image' : 'Add Image' }}</b-button>
                </p>
              </template>

              <b-input
                v-else-if="dataDefinition.datatype === 'number' && (dataDefinition.validatorType === 'validatorType_none' || dataDefinition.validatorType === 'validatorType_regex' || dataDefinition.validatorType === 'validatorType_minMax' || dataDefinition.validatorType === 'validatorType_value')"
                ref="validate-input"
                v-model="dataValueObjectSync[dataDefinition.__identifierKey__]"
                v-debounce:200ms="(text)=>validateInput(true)"
                :use-html5-validation="true"
                expanded
                :step="0.000001"
                :disabled="disabled"
                :placeholder="dataDefinition.title || dataDefinition.name"
                :type="dataDefinition.datatype"
              />

              <b-input
                v-else-if="!autocomplete && dataDefinition.datatype !== 'boolean' && (dataDefinition.validatorType === 'validatorType_none' || dataDefinition.validatorType === 'validatorType_regex' || dataDefinition.validatorType === 'validatorType_minMax' || dataDefinition.validatorType === 'validatorType_value')"
                ref="validate-input-2"
                v-model="dataValueObjectSync[dataDefinition.__identifierKey__]"
                v-debounce:200ms="(text)=>validateInput(true)"
                :use-html5-validation="false"
                expanded
                :disabled="disabled"
                :placeholder="dataDefinition.title || dataDefinition.name"
                :type="dataDefinition.datatype"
                :min="dataDefinition.validatorType === 'validatorType_minMax' ? dataDefinition.validator.minMax[0] : undefined"
                :max="dataDefinition.validatorType === 'validatorType_minMax' ? dataDefinition.validator.minMax[1] : undefined"
                :pattern="
                  dataDefinition.validatorType === 'validatorType_regex' ? dataDefinition.validator.regex :
                  (dataDefinition.datatype === 'string' && dataDefinition.validatorType === 'validatorType_minMax')? `[${dataDefinition.validator.minMax[0]}-${dataDefinition.validator.minMax[1]}]*` : undefined"
              />

              <!-- if datatype is string and autocomplete is enabled, display a b-autocomplete -->
              <b-autocomplete
                v-else-if="autocomplete && dataDefinition.datatype !== 'boolean' && (dataDefinition.validatorType === 'validatorType_none' || dataDefinition.validatorType === 'validatorType_regex' || dataDefinition.validatorType === 'validatorType_minMax' || dataDefinition.validatorType === 'validatorType_value')"
                v-model="dataValueObjectSync[dataDefinition.__identifierKey__]"
                v-debounce:500ms="(text)=>onGetIdentifierAutocomplData(dataDefinition.__identifierKey__, text)"
                :data="identifierAutocompleteData[dataDefinition.__identifierKey__]"
                :placeholder="dataDefinition.title || dataDefinition.name"
                expanded
                :disabled="disabled"
                :loading="isFetchingIdentifierAutocompleteData"
                keep-first
              />

              <p
                v-if="(dataDefinition.datatype === 'number' || dataDefinition.datatype === 'string') && displayBarcodeScanner && !(dataDefinition.validatorType === 'validatorType_choices' && !disabled)"
                class="control"
              >
                <b-button @click="onOpenBarcodeModal(i)">scan</b-button>
              </p>

              <!-- if datatype is auto_generated, disable editing, but show the pattern -->
              <b-input
                v-if="dataDefinition.datatype === 'auto_generated'"
                v-debounce:200ms="(text)=>validateInput(true)"
                :use-html5-validation="false"
                :value="dataValueObjectSync[dataDefinition.__identifierKey__] || editingAutogenerated === dataDefinition.__identifierKey__ ? dataValueObjectSync[dataDefinition.__identifierKey__] : dataDefinition.validator.pattern"
                expanded
                :disabled="editingAutogenerated !== dataDefinition.__identifierKey__"
                :placeholder=" dataDefinition.validator.pattern"
                @input="(v)=>dataValueObjectSync[dataDefinition.__identifierKey__] = v"
              />

              <!-- if the auto_generated value not empoty, display a button to set the value manually -->
              <p
                v-if="!disabled && dataDefinition.datatype === 'auto_generated' && dataValueObjectSync[dataDefinition.__identifierKey__] === null && editingAutogenerated !== dataDefinition.__identifierKey__"
                class="control"
              >
                <b-button
                  @click="editingAutogenerated = dataDefinition.__identifierKey__"
                >set manually</b-button>
              </p>
              <!-- set automaticslly -->
              <p
                v-if="!disabled && dataDefinition.datatype === 'auto_generated' && editingAutogenerated === dataDefinition.__identifierKey__"
                class="control"
              >
                <b-button
                  @click="dataValueObjectSync[dataDefinition.__identifierKey__] = null; editingAutogenerated = ''"
                >set automatically</b-button>
              </p>

              <!-- if the datatype is boolean, but the values is null, display a button to set it to true -->
              <p
                v-if="dataDefinition.datatype === 'boolean' && dataValueObjectSync[dataDefinition.__identifierKey__] === null"
                class="control"
                style="width: 100%; margin-bottom: 0;"
              >
                <b-button
                  expanded
                  :disabled="disabled"
                  @click="dataValueObjectSync[dataDefinition.__identifierKey__] = 'true'; validateInput(true)"
                >not set, click to set value</b-button>
              </p>

              <!-- if the datatype is boolean, display a b-checkbox-button -->
              <p
                v-else-if="dataDefinition.datatype === 'boolean'"
                class="control"
                style="width: 100%; margin-bottom: 0;"
              >
                <b-button
                  expanded
                  :disabled="disabled"
                  :type="
                    validationErrorMessages[dataDefinition.__identifierKey__] ? 'is-danger is-light' :
                    validationWarnMessages[dataDefinition.__identifierKey__] ? 'is-warning is-light' :
                    dataValueObjectSync[dataDefinition.__identifierKey__] === 'true' ? 'is-primary' : undefined"
                  :icon-left="dataValueObjectSync[dataDefinition.__identifierKey__] === 'true' ? 'check' : undefined"
                  @click="dataValueObjectSync[dataDefinition.__identifierKey__] === 'true'
                            ? dataValueObjectSync[dataDefinition.__identifierKey__] = 'false'
                            : dataValueObjectSync[dataDefinition.__identifierKey__] = 'true';
                          validateInput(true)
                  "
                >{{ dataValueObjectSync[dataDefinition.__identifierKey__] === 'true' ? 'true' : 'false' }}</b-button>
              </p>

              <b-select
                v-if="dataDefinition.validatorType === 'validatorType_choices'"
                v-model="dataValueObjectSync[dataDefinition.__identifierKey__]"
                v-debounce:200ms="(text)=>validateInput(true)"
                :placeholder="dataDefinition.title || dataDefinition.name"
                expanded
                :disabled="disabled"
              >
                <optgroup
                  v-if="dataDefinition.validator.choices.includes(dataValueObjectSync[dataDefinition.__identifierKey__])"
                  :label="dataDefinition.name"
                >
                  <option
                    v-for="option in dataDefinition.validator.choices"
                    :key="option"
                    :value="option"
                  >{{ option }}</option>
                </optgroup>

                <optgroup v-else label="choices">
                  <option
                    v-for="option in dataDefinition.validator.choices.concat(dataValueObjectSync[dataDefinition.__identifierKey__]).filter((v) => v)"
                    :key="option"
                    :value="option"
                  >{{ option }}</option>
                </optgroup>
              </b-select>

              <!-- if a value is selected, display a button to reset to null -->
              <p
                v-if="dataValueObjectSync[dataDefinition.__identifierKey__] !== null && !disabled && (dataDefinition.defaultValue === '' || dataValueObjectSync[dataDefinition.__identifierKey__] !== dataDefinition.defaultValue)"
                class="control"
              >
                <!-- if it has a default values, show reset isntead of clear -->
                <b-button
                  v-if="dataDefinition.defaultValue !== ''"
                  icon-right="undo"
                  title="Resets the field value to the default value"
                  @click="dataValueObjectSync[dataDefinition.__identifierKey__] = null; validateInput(true)"
                >reset</b-button>
                <b-button
                  v-else
                  icon-right="times"
                  title="Clears the field value"
                  @click="dataValueObjectSync[dataDefinition.__identifierKey__] = null; validateInput(true)"
                >clear</b-button>
              </p>
            </b-field>
          </b-field>
        </div>
      </template>
    </div>

    <VImageUploadModal
      :active.sync="isImageUploadModalActiveForDataKey"
      name-prefix="ash-attribute-"
      :max-filesize="1024 * 1024"
      :max-image-width="800"
      :min-image-width="800"
      :allow-variable="false"
      :url="dataValueObjectSync[imageUploadModalActiveForDataKey] || ''"
      :upload-path="uploadPath"
      :uploader-document-path="documentPath"
      @input="url => {dataValueObjectSync[imageUploadModalActiveForDataKey] = url}"
    />

    <b-modal
      :active.sync="isBarcodeScannerModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="settings">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Barcode Scanner</p>
            <!-- <div>Scan A Barcode</div> -->
          </header>
          <section class="modal-card-body">
            <section v-if="isBarcodeScannerModalActive">
              <StreamBarcodeReader @decode="onBarcodeDecode" @loaded="onBarcodeLoaded" />
            </section>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="isBarcodeScannerModalActive = false">Close</button>
            <!-- <button class="button is-success" @click="saveSettings">Save</button> -->
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'


import { AsidDB, IdentifierValue } from '@/types/typeAsid'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import { DataDefinition, DataDefinitionObject, isDataDefinitionKey, DataValueObject } from '@/types/typeDataDefinition'
import VImageUploadModal from './image/VImageUploadModal.vue'
import AsidManager from '@/database/asidManager'
import { typedWhere, typedWhereSartsWith } from '@/database/dbHelper'
import { arrayUnique } from '@/helpers/arrayHelper'
import { dbPrivileges } from '@/helpers/privileges'
import CategoryHelper from '@/database/categoryHelper'
import BackendConfigManager from '@/database/backendConfigManager'


@Component({
  components: {
    VTooltipIconHelp,
    StreamBarcodeReader,
    VImageUploadModal
  }
})
export default class VFormDataEntry extends Vue {
  @PropSync('dataValueObject', { type: Object }) readonly dataValueObjectSync!: DataValueObject

  @Prop({ type: Object, required: true }) readonly dataDefinitionObject!: DataDefinitionObject

  @Prop({ type: Boolean, required: false, default: false }) readonly displayBarcodeScanner!: boolean

  // uploadPath prop
  @Prop({ type: String, required: false, default: () => '' }) readonly uploadPath!: string

  @Prop({ type: String, required: false, default: () => '' }) readonly documentPath!: string

  // disable image upload prop
  @Prop({ type: Boolean, required: false, default: false }) readonly disableImageUpload!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean

  // autocomplete identifeirs prop
  @Prop({ type: Boolean, required: false, default: () => false }) readonly autocomplete!: boolean

  // labelOnBorder
  @Prop({ type: Boolean, required: false, default: false }) readonly labelOnBorder!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly hideTypes!: DataDefinition['datatype'][]

  // prop set default values
  @Prop({ type: Boolean, required: false, default: false }) readonly doSetDefaultValues!: boolean

  // categories to limit the display of entries
  @Prop({ type: Array, default: () => ['ALL_CATEGORIES'] }) readonly categories!: string[]

  // datadefinition key - if provided, the data entry will only show the data entry for the provided key
  @Prop({ type: Array, required: false, default: () => [] }) readonly dataDefinitionKeys!: isDataDefinitionKey[]

  // hide the box surrounding the data entry
  @Prop({ type: Boolean, required: false, default: false }) readonly hideBox!: boolean

  //
  public showDataEntryBasedOnCategories(dataDefinition: DataDefinition) {
    if (dataDefinition.categories.length === 0) return true // if no categories are set, show the data entry
    if (this.categories.includes('ALL_CATEGORIES')) return true // if the data entry is in the ALL_CATEGORIES category, show it
    return CategoryHelper.isElementActiveForAsidRef(dataDefinition.categories, this.categories, this.$categories)
  }

  public imageUploadModalActiveForDataKey: isDataDefinitionKey = ''

  public get isImageUploadModalActiveForDataKey() {
    return this.imageUploadModalActiveForDataKey !== ''
  }

  public set isImageUploadModalActiveForDataKey(value: boolean) {
    if (value === false)
      this.imageUploadModalActiveForDataKey = ''
  }

  // key of the dataValueObjectSync beeing edited
  public editingAutogenerated: string = ''


  public dataDefinitions: (DataDefinition & { __identifierKey__: isDataDefinitionKey })[] = []

  // #region autocomplete

  public identifierAutocompleteData: { [key: string]: (string | number | null | object | boolean)[] } = {}

  public isFetchingIdentifierAutocompleteData = false

  get hasAsidReadPrivilege() {
    return this.$auth.userHasAllPrivilege([dbPrivileges.ASID_READ])
  }

  public async onGetIdentifierAutocomplData(identifierKey: keyof IdentifierValue, text: string) {
    if (this.$backendConfig.asid.identifierDefinition[identifierKey].validatorType === 'validatorType_choices') {
      // if choices are the validator, just show the choices in the dropdown
      this.identifierAutocompleteData[identifierKey] = this.$backendConfig.asid.identifierDefinition[identifierKey].validator.choices || []
    } else if (this.hasAsidReadPrivilege) {
      this.isFetchingIdentifierAutocompleteData = true

      const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text)
      const queryUC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map((q) => q.limit(50).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap((r) => r.docs.map((d) => d.data() as AsidDB).map((asid) => asid.identifierValue[identifierKey]))
      this.identifierAutocompleteData[identifierKey] = arrayUnique(responseDocuments)
      this.isFetchingIdentifierAutocompleteData = false
    } else {
      this.identifierAutocompleteData[identifierKey] = [text]
    }
  }
  // #endregion autocomplete


  public validationErrorMessages: { [key: string]: string } = {}
  public validationWarnMessages: { [key: string]: string } = {}

  /**
 * Validate the input of the dataValueObjectSync
 * @param warningOnly if true, only show warnings, if false, show also errors
 * @returns true if save is possible, false if not
 */
  public validateInput(warningOnly = false) {
    console.log('validateInput')
    this.setDefaultValues()
    return this.validate(warningOnly)[2]
  }

  private validate(warningOnly = false) {
    const filteredDataDefinitions = BackendConfigManager.filterDataDefinitionsByCategories(this.dataDefinitions, this.$categories, this.categories)
    const [valid, validationMessages, canSave] = BackendConfigManager.validateDataDefinitionInput(this.dataValueObjectSync, filteredDataDefinitions)

    this.validationErrorMessages = {}
    this.validationWarnMessages = {}

    Object.entries(validationMessages).forEach(([key, message]) => {
      if (warningOnly || message.severity === 'warning') {
        this.$set(this.validationWarnMessages, key, message.text)
      } else {
        this.$set(this.validationErrorMessages, key, message.text)
      }
    })

    return [valid, validationMessages, canSave]
  }

  // #region barcodeModal
  public isBarcodeScannerModalActive = false
  private barcodeIdentifierIndex = 0
  public onOpenBarcodeModal(index: number) {
    this.barcodeIdentifierIndex = index
    this.isBarcodeScannerModalActive = true
  }

  public onBarcodeDecode(value: string, b: any, c: any) {
    this.isBarcodeScannerModalActive = false
    console.log(value, b, c)
    this.dataValueObjectSync[this.dataDefinitions[this.barcodeIdentifierIndex].__identifierKey__] = value
  }

  public onBarcodeLoaded() {
    // this.isBarcodeScannerModalActive = false
  }

  /** on dataValueObjectSync convert an empty string to null */
  @Watch('dataValueObjectSync', { deep: true, immediate: true })
  private onDataValueObjectSyncChanged() {
    Object.entries(this.dataValueObjectSync)
      .forEach(([key, value]) => {
        if (value === '') {
          this.$set(this.dataValueObjectSync, key, null)
        }
        // if the type is boolean, convert null to false
        // => dont do that as this causes unsaved changes everytime even if the user does not change anything
        // if (this.dataDefinitions.find(d => d.__identifierKey__ === key)?.datatype === 'boolean' && value === null) {
        //   this.$set(this.dataValueObjectSync, key, false)
        // }
      })

    this.validateInput(true)
    this.setDefaultValues()
  }


  get isReady() {
    console.log(this.dataDefinitions.length, this.dataValueObjectSync)
    return this.dataDefinitions.length <= Object.keys(this.dataValueObjectSync).length
  }

  public errorMessageGlobal = ''

  @Watch('dataDefinitionObject', { deep: true, immediate: true })
  @Watch('dataDefinitionKeys', { deep: true, immediate: false })
  public init() {
    this.dataDefinitions = BackendConfigManager.getDataDefinitionsFromObject(this.dataDefinitionObject)
      .filter((e) => e.name || e.title)
      // filter out all dataDefinitions that are in the hideTypes array
      .filter((e) => !this.hideTypes.includes(e.datatype))
      // filter datadefinitionKey
      .filter((e) => this.dataDefinitionKeys.length === 0 || this.dataDefinitionKeys.includes(e.__identifierKey__))
      // sort first by order and if order is the same, sort by __identifierKey__
      .sort((a, b) => a.order - b.order || a.__identifierKey__.localeCompare(b.__identifierKey__, 'en', { numeric: true }))

    // if the provided dataDefinitionKey results in an empty array, show a warning
    if (this.dataDefinitionKeys.length > 0 && this.dataDefinitions.length === 0) {
      // check what dataDefinitionKey is missing
      const missingDataDefinitionKeys = this.dataDefinitionKeys.filter((k) => !this.dataDefinitions.find((d) => d.__identifierKey__ === k))
      this.errorMessageGlobal = `No dataDefinition found for key: ${missingDataDefinitionKeys.join(', ')}`
    } else {
      this.errorMessageGlobal = ''
    }

    this.setDefaultValues()
    this.validateInput(true)
  }

  private setDefaultValues() {
    if (!this.doSetDefaultValues) return
    // set the default values to all fields that are null
    this.dataDefinitions.forEach((d) => {
      if (this.dataValueObjectSync[d.__identifierKey__] === null && d.defaultValue !== '') {
        this.$set(this.dataValueObjectSync, d.__identifierKey__, d.defaultValue)
      }
    })
  }


  created() {
    // this.init()
  }

  // #endregion barcodeModal
}
</script>

<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

.data-entry-wrapper {
  .form.box {
    margin-bottom: 2em;
  }

  .field-body {
    flex-grow: 10;
  }

  .field-label.is-normal {
    flex-grow: 2;
  }

  .identifier-wrapper {
    margin-bottom: 1rem;

    @include until($tablet) {
      & > .field {
        flex-direction: column;
      }
    }

    .help {
      margin-top: -0.5rem;
      text-align: end;
    }

    .is-expanded {
      flex-grow: 1;
    }

    // hide the fields own warning message as we are displaying a custom message
    .field.has-addons.is-expanded .field.has-addons.is-expanded {
      .help.is-warning,
      .help.is-danger {
        display: none;
      }
    }
  }

  @include until($tablet) {
    .field-label.is-normal {
      margin-right: 0.5rem;
    }
  }

  .form.box {
    background: #f6f6f6;
  }

  img.tile-image-preview {
    display: none;
    position: absolute;
    max-width: 300px;
    max-height: 300px;
    width: 200px;
    padding: 1em;
    z-index: 9999;
    // border: 1px solid #e4e4e4;
    background: white;
    // add shadow
    box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  }

  .tile-image-upload-button {
    margin-bottom: 0 !important;
  }

  .image-preview {
    margin-bottom: 0 !important;

    &:hover {
      img.tile-image-preview {
        display: block;
      }
    }

    button {
      background-size: cover;
    }
  }
}
</style>
