import { Statistics } from '@/types/typeBase'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function getStatisticsPerDay<T>(
  statistics: Statistics<T>
): { date: Date, utcYear: number, utcMonth: number, utcDay: number, events: T }[] {
  return Object.entries(statistics.date.y).flatMap(
    (
      [year, yEntry] // [2022, {e:T, m:{}}]
    ) =>
      Object.entries(yEntry.m || {}).flatMap(
        (
          [month, mEntry] // [11, {e:T, m:{}}]
        ) =>
          Object.entries(mEntry.d || {}).flatMap(
            (
              [day, dEntry] // [28, {e:T, d:{}}]
            ) => ({
              date: new Date(Date.UTC(+year, +month - 1, +day)),
              utcYear: +year,
              utcMonth: +month,
              utcDay: +day,
              events: dEntry.e
            })
          )
      )
  )
}

export function getStatisticsForMonth<T>(
  statistics: Statistics<T>,
  year: number, // e.g. 2022
  month: number // 1..12
): T {
  const emptyEventObj = Object.fromEntries(
    Object.entries(statistics.date?.e || {}).map(([eventName, val]) => [eventName, 0])
  ) as unknown as T

  return (statistics.date?.y?.[year]?.m?.[month]?.e as T) || emptyEventObj
}

export function getStatisticsPerTime<T>(statistiscs: Statistics<T>): { time: string, events: T }[] {
  return Object.entries(statistiscs.time).flatMap(
    (
      [time, tEntry] // [10_5, T]
    ) => {
      const hours = time.split('_')[0]
      const minutes = (+time.split('_')[1] || 0) * 6

      const dateString = `${hours}:${minutes.toString().padStart(2, '0')}` // HH:MM

      // Convert to a full date before using UTC
      const formattedLocalString = dayjs.utc(`1970-01-01 ${dateString}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')

      return {
        time: formattedLocalString,
        events: tEntry
      }
    }
  )
}

export function getStatisticsForDateRange<T>(
  statistics: Statistics<T>,
  startDate: Date,
  endDate: Date
): { date: Date, events: T }[] {
  const start = startDate.getTime()
  const end = endDate.getTime()

  const statisticsPerDay = getStatisticsPerDay(statistics)

  return statisticsPerDay.filter(
    (entry) => {
      const entryDate = entry.date.getTime()
      return entryDate >= start && entryDate <= end
    }
  )
}
