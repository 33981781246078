import { ModuleType, BaseGroupDB, BaseElementDB, BaseResponseDB, BaseResponseItemDB } from '@/modules/typeModules'
import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'

import { GlobalAdminNotificationDB, GlobalAdminCodeConfigDB, GlobalAdminStatisticsDB } from '@/types/typeAdmin'
import { AdminDataPerTenantDB } from '@/types/typeAdminData'
import { AsidDB, asidState, assetAttributeValue } from '@/types/typeAsid'
import { TenantDB, Terms } from '@/types/typeTenant'
import { ApiUserReferenceDB, UserReferenceDB } from '@/types/typeUser'
import Category, { CategoryCollectionDocDB, CategoryItem } from '@/types/typeCategory'
import { AppConfigDB, LegalConfig } from '@/types/typeAppConfig'
import { CodeConfig, BackendConfigDB, assetAttributeDefinitions } from '@/types/typeBackendConfig'
import { PlanDB } from '@/types/typePlan'
import RecordMeta from '@/types/typeRecordMeta'
import { FormModuleDB, FormGroupDB, FormElementDB } from '@/modules/form/typeFormModule'
import { HelpRessouceDB } from '@/types/typeHelpRessource'
import { dbPrivileges } from '../helpers/privileges'
import { objectID } from '@/types/typeGeneral'
import { BatchDB } from '@/types/typeBatch'
import { BaseDB, Statistics } from '@/types/typeBase'
import { AppSessionDB, SessionDB } from '@/types/typeAppSession'
import { FileElementDB, FileGroupDB, FileModuleDB } from '@/modules/file/typeFileModule'
import {
  BgFgColorDefinition,
  CiElementDB,
  CiGroupDB,
  CiModuleDB,
  ExpandedWidgetDesignDefinition,
  TileWidgetDesignDefinition,
  WidgetDesignDefinition
} from '@/modules/ci/typeCiModule'
import { HtmlElementDB, HtmlGroupDB, HtmlModuleDB } from '@/modules/html/typeHtmlModule'
import { ScriptElementDB, ScriptGroupDB, ScriptModuleDB } from '@/modules/script/typeScriptModule'
import {
  ProtectionElementDB,
  ProtectionGroupDB,
  ProtectionModuleDB,
  ProtectionResponseDB
} from '@/modules/protection/typeProtectionModule'

import { AuditLogDB } from '@/types/typeAuditTrail'
import {
  DataElementDataDefinition,
  DataElementDB,
  DataGroupDB,
  DataKeyedObject,
  DataModuleDB
} from '@/modules/data/typeDataModule'
import { CustomElementDB, CustomGroupDB, CustomModuleDB } from '@/modules/custom/typeCustomModule'
import { I18nElementDB, I18nGroupDB, I18nModuleDB } from '@/modules/i18n/typeI18nModule'
import {
  ServiceModuleDB,
  ServiceGroupDB,
  ServiceElementDB,
  ServiceResponseIncidentDB,
  ServiceResponseIncidentItemDB
} from '@/modules/service/typeServiceModule'
import { cloneObject } from '../helpers/dataShapeUtil'
import { RPCAppDataResponse } from '@/types/typeRPC'
import { AppContactDB, contactDB } from '@/types/typeAppContact'
import { ROOT_CATEGORY_ID } from '../businessLogic/sharedConstants'
import { DataDefinition } from '@/types/typeDataDefinition'
import { AlarmLogDB } from '@/types/typeAlarmLog'
import { LinkElementDB, LinkGroupDB, LinkModuleDB } from '@/modules/link/typeLinkModule'

/**
 * This default data file is also used in tests and thus no other modules but types are imported here
 * TODO use getters including a clone call
 */

export type dbDefinition = {
  __NAME__: string
  __DOCUMENT_PATH__?: (...args: any[]) => string
  __COLLECTION_PATH__?: (...args: any[]) => string
  __EMPTY_DOC__?: any
  __PRIVILEGES__?: RequiredDocPrivileges
  [key: string]: dbDefinition | undefined | ((...args: any[]) => string) | string | RequiredDocPrivileges
}

export const asidStates: asidState[] = ['generated', 'printing', 'inStock', 'shipped']

export const defaultRecordMeta: RecordMeta = {
  dateCreated: new Date(),
  dateUpdated: new Date(),
  modifiedByUserId: '',
  createdByUserId: '',
  revisions: 0
}

export const defaultBaseResponseDB: BaseResponseDB = {
  seenByUserID: {},
  public: {
    asidID: '',
    elementID: '',
    responseTransactionID: '',
    tenantID: '',
    locale: 'default',
    data: {}
  },
  responseSession: '',
  categoryIDs: [],
  asidIdentifiers: {
    i1: null,
    i2: null,
    i3: null,
    i4: null,
    i5: null,
    i6: null
  },
  assetAttributeValues: {
    ...(Object.fromEntries(
      Array.from(Array(200).keys()) /// 0..49
        .map((i) => ['a' + (i + 1), null])
    ) as assetAttributeValue)
  },
  publishingState: 'published',
  backendData: {
    acknowledge: false,
    note: ''
  },
  _meta: defaultRecordMeta
}

export const defaultBaseResponseItemDB: BaseResponseItemDB = {
  seenByUserID: {},
  responseSession: '',
  public: {
    responseID: '',
    // elementID: '',
    responseTransactionID: '',
    tenantID: '',
    locale: 'default'
  },
  publishingState: 'published',
  _meta: defaultRecordMeta
}

export const defaultAppSessionDB: AppSessionDB = {
  _computed: {
    statistics: getDefaultStatistics({ c: 0, u: 0 })
  },
  _meta: defaultRecordMeta
}

export const defaultAppContactDB: AppContactDB = {
  _computed: {
    statistics: getDefaultStatistics({ c: 0, u: 0, d: 0 })
  },
  _meta: defaultRecordMeta
}

export const defaultContactDB: contactDB = {
  asidIDs: [],
  createdOnAsidID: '',
  publishingState: 'published',
  contactInfo: {
    name: '',
    email: '',
    phone: '',
    address: {
      name: '',
      city: '',
      notes: '',
      street: '',
      zip: 0,
      country: ''
    }
  },
  notes: '',
  _computed: {
    statistics: getDefaultStatistics({ u: 0 })
  },
  _meta: defaultRecordMeta
}

export const defaultAppData: RPCAppDataResponse = {
  moduleGroupAppDatas: [],
  globalData: {
    dataHash: '',
    sessionID: '',
    tenantID: '',
    pages: [],
    legal: {
      imprint: {
        linkTitle: { locales: { default: '' }, _ltType: true },
        text: { locales: { default: '' }, _ltType: true }
      },
      terms: {
        linkTitle: { locales: { default: '' }, _ltType: true },
        text: { locales: { default: '' }, _ltType: true }
      },
      privacy: {
        linkTitle: { locales: { default: '' }, _ltType: true },
        text: { locales: { default: '' }, _ltType: true }
      },
      other: {
        linkTitle: { locales: { default: '' }, _ltType: true },
        text: { locales: { default: '' }, _ltType: true }
      }
    }
  }
}

export const defaultSessionsDB: SessionDB = {
  sessionID: '',
  asidID: '',
  userID: '',
  sessionState: {},

  _computed: {
    visitedCount: 0,
    statistics: getDefaultStatistics({ v: 0 })
  },
  _meta: defaultRecordMeta
}

// #region global design

const defaultBgFgColorDefinition: BgFgColorDefinition = {
  background: '',
  text: ''
}

export const defaultExpandedWidgetDesignDefinition: ExpandedWidgetDesignDefinition = {
}

export const defaultWidgetDesignDefinition: WidgetDesignDefinition = {
  title: {
    textDesign: {
      color: {
        background: '',
        text: ''
      },
      font: {
        fontFamily: '', // default: '', | native font family
        url: '', // default: '', | webfont url
        size: -999, // default: -999 | rem
        weight: -999 // default: -999
      }
    },
    alignment: 'default',
    spacing: {
      inner: {
        top: -999,
        right: -999,
        bottom: -999,
        left: -999
      },
      outer: {
        top: -999,
        right: -999,
        bottom: -999,
        left: -999
      }
    }
  },
  subtitle: {
    textDesign: {
      color: {
        background: '',
        text: ''
      },
      font: {
        fontFamily: '', // default: '', | native font family
        url: '', // default: '', | webfont url
        size: -999, // default: -999 | rem
        weight: -999 // default: -999 // default: -999 | rem
      }
    }
  },
  text: {
    color: {
      background: '',
      text: ''
    },
    font: {
      fontFamily: '', // default: '', | native font family
      url: '', // default: '', | webfont url
      size: -999, // default: -999 | rem
      weight: -999 // default: -999 // default: -999 | rem
    }
  },
  buttonColor: {
    background: '',
    text: ''
  },
  highlightColor: {
    background: '',
    text: ''
  },
  backgroundColor: '',
  spacing: {
    inner: {
      top: -999,
      right: -999,
      bottom: -999,
      left: -999
    },
    outer: {
      top: -999,
      right: -999,
      bottom: -999,
      left: -999
    },
    border: {
      top: -999,
      right: -999,
      bottom: -999,
      left: -999
    }
  },
  borderRadius: -999,
  borderColor: '',
  outerShadow: -999,
  outlineColor: ''
}

export const defaultTileWidgetDesignDefinition: TileWidgetDesignDefinition = {
  aspectRatio: -999,
  alignmentVertical: 'default',
  gapSpacing: -999
}
// #endregion global design

export const defaultBaseGroupDB: BaseGroupDB = {
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  _meta: defaultRecordMeta,
  public: {
    groupType: 'group-type_widget',
    pageID: '',
    display: {
      displayType: 'inline',
      imageUrl: { locales: { default: '' }, _ltType: true },
      tintMode: 'partial',
      designExpanded: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition
      },
      designTile: {
        ...defaultWidgetDesignDefinition,
        ...defaultTileWidgetDesignDefinition
      }
    },
    title: { locales: { default: '' }, _ltType: true },
    subtitle: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true },
    order: -99999
  }
}

export const defaultBaseElementComputed = {
  _computed: {
    queryCategoryIDs: [],
    responseCount: 0,
    deletedResponseCount: 0,
    createdResponseCount: 0,
    statistics: getDefaultStatistics({ crc: 0, drc: 0, rc: 0 })
  }
}

export const defaultBaseElementDB: BaseElementDB = {
  name: '',
  publishingState: 'published',
  reference: {
    identifierValues: {
      i1: [],
      i2: [],
      i3: [],
      i4: [],
      i5: [],
      i6: []
    },
    asidIDs: [],
    categoryIDs: {
      c1: [],
      c2: [],
      c3: []
    }
  },
  public: {
    groupID: 'default-group',
    order: 0
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

/**
 * set the dault objects here, so they can be imported from testing, and CF environmwnt withut requiring the other dependencies
 */

// #region Form Module
export const defaultFormModuleDB: FormModuleDB = {
  activated: true,
  public: {
    type: 'Form'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}
export const defaultFormGroupDB: FormGroupDB = {
  ...defaultBaseGroupDB,
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  actions: { notifiedEmails: [] },
  _meta: defaultRecordMeta,
  public: {
    ...defaultBaseGroupDB.public,
    inline: false,
    formCustomOptions: {
      // isSingleQuestion: false // show one question/panel per page
    },
    formBaseOptions: {
      checkErrorsMode: 'onNextPage',
      focusOnFirstError: true,
      firstPageIsStarted: false,
      goNextPageAutomatic: false,
      questionsOnPageMode: 'standard',
      // completeText: 'Complete',
      // isSinglePage: false,
      // pageNextText: 'Next',
      // pagePrevText: 'Prev',
      questionErrorLocation: 'bottom',
      questionStartIndex: '1',
      questionTitleLocation: 'top',
      questionTitleTemplate: '{require} {title}',
      questionsOrder: 'initial',
      requiredText: '*',
      sendResultOnPageNext: false,
      showCompletedPage: true,
      showNavigationButtons: 'bottom',
      showPageNumbers: false,
      showPageTitles: false,
      showPrevButton: true,
      showProgressBar: 'none',
      showQuestionNumbers: 'on', // on, onPage, off
      showTitle: true,
      storeOthersAsComment: true,
      completedHtml: ''
    }
  }
}
export const defaultFormElementDB: FormElementDB = {
  ...defaultBaseElementDB,
  name: 'form',
  publishingState: 'draft',
  description: '',
  public: {
    order: 0,
    groupID: 'default-group',
    options: {
      randomQuestionOrder: false,
      randomQuestionSubsample: 0
    },
    triggers: [],
    questions: []
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

// #endregion Form Module

// #region Ci Module
export const defaultCiModuleDB: CiModuleDB = {
  activated: true,
  public: {
    type: 'Ci'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultCiGroupDB: CiGroupDB = {
  ...defaultBaseGroupDB
}

// const defaultSpacing: Spacing = {
//   top: -1,
//   right: -1,
//   bottom: -1,
//   left: -1
// }

// const defaultWidgetSpacing: WidgetSpacing = {
//   inner: defaultSpacing,
//   outer: defaultSpacing,
//   border: defaultSpacing
// }

// const defaultFontDefinition: FontDefinition = {
//   fontFamily: '', // default: '', | native font family
//   url: '', // default: '', | webfont url
//   size: -1, // default: -1 | rem
//   weight: -1 // default: -1
// }

// const defaultTextDefinition: TextDefinition = {
//   color: defaultBgFgColorDefinition,
//   font: defaultFontDefinition
// }

// const defaultHeadingDesignDefinition: HeadingDesignDefinition = {
//   textDesign: defaultTextDefinition,
//   alignment: 'left',
//   spacing: {
//     inner: defaultSpacing,
//     outer: defaultSpacing
//   }
// }

// inlined most of the child objects below for better overview
// const defaultWidgetDesignDefinition: WidgetDesignDefinition = {
//   heading: defaultHeadingDesignDefinition,
//   text: defaultTextDefinition,
//   backgroundColor: '',
//   spacing: defaultWidgetSpacing,
//   borderRadius: 0,
//   borderWidth: 0,
//   borderColor: '',
//   outerShadow: false,
//   outlineWidth: 0,
//   outlineColor: '',
//   buttonColor: defaultBgFgColorDefinition,
//   highlightColor: defaultBgFgColorDefinition
// }

export const defaultCiElementDB: CiElementDB = {
  ...defaultBaseElementDB,
  public: {
    websiteTitle: { locales: { default: '' }, _ltType: true },
    widget: {
      base: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition,
        ...defaultTileWidgetDesignDefinition
      },
      inline: { ...defaultWidgetDesignDefinition, ...defaultExpandedWidgetDesignDefinition },
      tile: { ...defaultWidgetDesignDefinition, ...defaultTileWidgetDesignDefinition }
      // button: defaultWidgetDesignDefinition
    },
    header: {
      color: defaultBgFgColorDefinition,
      logo: { url: '', maxHeight: -999, maxWidth: -999, alignment: 'center', fullHeight: false, fullWidth: false },
      title: { locales: { default: '' }, _ltType: true }
    },
    body: {
      color: '',
      outerColor: '',
      spacing: {
        top: -999,
        bottom: -999
      }
    },
    footer: {
      color: defaultBgFgColorDefinition,
      logo: { url: '', maxHeight: -999, maxWidth: -999, alignment: 'center', fullHeight: false, fullWidth: false },
      title: { locales: { default: '' }, _ltType: true }
    },

    order: 0,
    groupID: 'default-group'
  }
}

// console.log(defaultCiElementDB)
// console.log(Object.keys(Transformer.flatten(defaultCiElementDB)))

// #endregion Ci Module

// #region File Module
export const defaultFileModuleDB: FileModuleDB = {
  activated: true,
  public: {
    type: 'File'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultFileGroupDB: FileGroupDB = {
  ...defaultBaseGroupDB,
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  public: {
    ...defaultBaseGroupDB.public,
    display: {
      displayType: 'inline',
      imageUrl: { locales: { default: '' }, _ltType: true },
      tintMode: 'partial',
      designExpanded: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition
      },
      designTile: {
        ...defaultWidgetDesignDefinition,
        ...defaultTileWidgetDesignDefinition
      }
    },
    displayType: 'list',
    openInNewWindow: false,
    order: -99999,
    searchable: false,
    numberOfFiles: 10,
    showDownloadFile: false,
    title: { locales: { default: '' }, _ltType: true },
    subtitle: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true }
  },
  _meta: defaultRecordMeta
}

export const defaultFileElementDB: FileElementDB = {
  ...defaultBaseElementDB,
  name: '',
  public: {
    groupID: 'default-group',
    order: 0,
    title: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true },
    url: { locales: { default: '' }, _ltType: true },
    thumbnailUrl: { locales: { default: '' }, _ltType: true },
    ressourceType: '',
    directory: { locales: { default: '' }, _ltType: true },
    options: {
      startPath: { locales: { default: '' }, _ltType: true },
      startPage: { locales: { default: 1 }, _ltType: true },
      singlePage: false,
      aspectRatio: 1.4 // A4 ratio
    }
  },
  publishingState: 'draft',
  _meta: defaultRecordMeta
}
// #endregion File Module

// #region Html Module
export const defaultHtmlModuleDB: HtmlModuleDB = {
  activated: true,
  public: {
    type: 'Html'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultHtmlGroupDB: HtmlGroupDB = {
  ...defaultBaseGroupDB
}

export const defaultHtmlElementDB: HtmlElementDB = {
  ...defaultBaseElementDB,
  name: '',
  public: {
    html: {
      _ltType: true,
      locales: {
        default: ''
      }
    },
    order: 0,
    groupID: 'default-group'
  },
  publishingState: 'draft',
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}
// #endregion Html Module

// #region data Module
export const defaultDataModuleDB: DataModuleDB = {
  activated: true,
  public: {
    type: 'Data'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultDataGroupDB: DataGroupDB = {
  ...defaultBaseGroupDB,
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  dataDefinition: {
    ...(Object.fromEntries(
      Array.from(Array(50).keys()) /// 0..49
        .map((i) => [
          'd' + (i + 1),
          {
            datatype: 'string' as const,
            name: '',
            order: 0,
            title: '',
            categories: [],
            allowEmpty: true,
            validatorType: 'validatorType_none',
            defaultValue: '',
            allowSavingInvalid: false,
            validator: {
              choices: [],
              minMax: [0, 0],
              regex: '',
              pattern: '',
              value: ''
            }
          } as DataDefinition
        ])
    ) as DataElementDataDefinition)
  },
  public: {
    ...defaultBaseGroupDB.public,
    order: -99999,
    display: {
      displayType: 'inline',
      imageUrl: { locales: { default: '' }, _ltType: true },
      tintMode: 'partial',
      designExpanded: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition
      },
      designTile: {
        ...defaultWidgetDesignDefinition,
        ...defaultTileWidgetDesignDefinition
      }
    },
    title: { locales: { default: '' }, _ltType: true },
    subtitle: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true }
  },
  _meta: defaultRecordMeta
}

export const defaultDataElementDB: DataElementDB = {
  ...defaultBaseElementDB,
  name: 'new Data Element',
  data: {
    ...(Object.fromEntries(
      Array.from(Array(50).keys()) /// 0..49
        .map((i) => ['d' + (i + 1), null])
    ) as DataKeyedObject)
  },
  public: {
    order: 0,
    groupID: 'default-group'
  },
  publishingState: 'draft',
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}
// #endregion data Module

// #region custom Module
export const defaultCustomModuleDB: CustomModuleDB = {
  activated: true,
  public: {
    type: 'Custom'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultCustomGroupDB: CustomGroupDB = {
  ...defaultBaseGroupDB,
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  publishedIdentifiers: [],
  publishCategories: false,
  public: {
    ...defaultBaseGroupDB.public,
    order: -99999,
    display: {
      displayType: 'inline',
      imageUrl: { locales: { default: '' }, _ltType: true },
      tintMode: 'partial',
      designExpanded: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition
      },
      designTile: {
        ...defaultWidgetDesignDefinition,
        ...defaultTileWidgetDesignDefinition
      }
    },
    title: { locales: { default: '' }, _ltType: true },
    subtitle: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true }
  },
  _meta: defaultRecordMeta
}

export const defaultCustomElementDB: CustomElementDB = {
  ...defaultBaseElementDB,
  name: 'new Custom Element',
  dataDefinition: {
    d1: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d2: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d3: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d4: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d5: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d6: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    }
  },
  public: {
    overview: {
      code: '',
      markup: ''
    },
    singleview: {
      code: '',
      markup: ''
    },
    order: 0,
    groupID: 'default-group'
  },
  publishingState: 'draft',
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}
// #endregion custom Module

export abstract class Database {
  // #region service Module
  public static get defaultServiceModuleDB(): ServiceModuleDB {
    return cloneObject<ServiceModuleDB>({
      activated: true,
      public: {
        type: 'Service'
      },
      customStates: [],
      customTags: [],

      textSnippets: [{
        shortcut: '/hello',
        text: 'Hello ((c.name))! \nHow can I help you?'
      }],

      defaultChannelID: '',

      presets: {
        initialTags: [],
        initialAssignees: [],
        initialVisibility: 'visibility_app_and_email',
        closeVisibility: 'visibility_none',
        initialCustomState: '',
        emailStartMessage: { locales: { default: '' }, _ltType: true },
        emailFromName: ''
      },

      ...defaultBaseElementComputed,
      _meta: defaultRecordMeta
    })
  }

  public static get defaultServiceGroupDB(): ServiceGroupDB {
    return cloneObject<ServiceGroupDB>({
      ...defaultBaseGroupDB,
      name: '',
      publishingState: 'published',
      protectionGroupID: '',
      public: {
        ...defaultBaseGroupDB.public,
        badgeMode: 'badgemode_unread'
      },
      _meta: defaultRecordMeta
    })
  }

  public static get defaultServiceElementDB(): ServiceElementDB {
    return cloneObject<ServiceElementDB>({
      ...defaultBaseElementDB,

      publishingState: 'draft',

      presets: {
        initialTags: [],
        initialAssignees: [],
        initialVisibility: 'visibility_inherit',
        closeVisibility: 'visibility_inherit',
        initialCustomState: '',
        emailStartMessage: { locales: { default: '' }, _ltType: true },
        emailFromName: 'emailfromname_inherit',
        isSummaryRequired: false
      },

      public: {
        ...defaultBaseElementDB.public,
        title: { locales: { default: '' }, _ltType: true },
        titleMode: 'titlemode_app',

        startFormGroupID: '',
        isStartFormRequired: false,

        closeFormGroupID: '',
        isCloseFormRequired: false,

        allowCcEmail: false,

        appAutoreply: {
          delay: 0,
          message: { locales: { default: '' }, _ltType: true },
          mode: 'autoreply_none'
        },
        contactInfo: {
          doAskForEmail: 'contactinfo_none',
          doAskForName: 'contactinfo_none',
          doAskForPhone: 'contactinfo_none',
          doAskForCompany: 'contactinfo_none',
          message: { locales: { default: '' }, _ltType: true }
        },
        appStartMessage: { locales: { default: '' }, _ltType: true },
        appCloseMessage: { locales: { default: '' }, _ltType: true },

        isAddMessageAfterCloseAllowed: false,

        createIncident: 'createincident_all'
      }
    })
  }

  public static get defaultServiceModuleResponseDB(): ServiceResponseIncidentDB {
    return cloneObject<ServiceResponseIncidentDB>({
      ...defaultBaseResponseDB,
      seenByUserID: {},
      public: {
        state: 'new',
        title: '',
        _dateLastAppRelevantActivity: new Date(),
        asidID: '',
        elementID: '',
        responseTransactionID: '',
        tenantID: '',
        data: {},
        locale: 'default'
      },
      visibility: 'visibility_app_and_email',
      customState: '',
      assignees: [],
      tags: [],
      referencedIncidentIDs: [],
      ccEmails: [],
      contact: {
        contactID: '',
        email: '',
        name: '',
        company: '',
        phone: '',
        note: ''
      },
      _dateLastActivity: new Date(),
      _dateFirstClosed: null,
      _durationFirstClosed: null,
      summary: ''
    })
  }

  public static get defaultServiceModuleResponseItemDB(): ServiceResponseIncidentItemDB {
    return cloneObject<ServiceResponseIncidentItemDB>({
      responseSession: '',
      seenByUserID: {},
      public: {
        responseTransactionID: '',
        tenantID: '',
        responseID: '',
        attachedFiles: [],
        formResponseTransactionID: '',
        formGroupID: '',
        message: '',
        title: '',
        locale: 'default'
      },
      publishingState: 'draft',
      action: {
        type: 'invalid',
        old: [],
        new: []
      },
      notificationState: {
        email: 'none'
      },
      replyToID: '',
      _meta: defaultRecordMeta
    })
  }
  // #endregion service Module

  // #region link Module
  public static get defaultLinkModuleDB(): LinkModuleDB {
    return cloneObject<LinkModuleDB>({
      activated: true,
      public: {
        type: 'Link'
      },
      ...defaultBaseElementComputed,
      _meta: defaultRecordMeta
    })
  }

  public static get defaultLinkGroupDB(): LinkGroupDB {
    return cloneObject<LinkGroupDB>({
      ...defaultBaseGroupDB,
      name: '',
      publishingState: 'published',
      protectionGroupID: '',
      public: {
        ...defaultBaseGroupDB.public,
        display: {
          ...defaultBaseGroupDB.public.display,
          displayType: 'tile-auto'
        }
      },
      _meta: defaultRecordMeta
    })
  }

  public static get defaultLinkElementDB(): LinkElementDB {
    return cloneObject<LinkElementDB>({
      ...defaultBaseElementDB,
      name: '',
      publishingState: 'draft',
      public: {
        order: 0,
        groupID: 'default-group',
        linkType: 'linktype_page',
        link: { locales: { default: '' }, _ltType: true },
        pageID: ''
      },
      _meta: defaultRecordMeta
    })
  }

  // #endregion link Module
}

// #region i18n Module
export const defaultI18nModuleDB: I18nModuleDB = {
  activated: true,
  public: {
    type: 'I18n',
    enabledLocales: ['en'],
    fallbackLocale: 'auto',
    langSelect: 'auto-manual'
  },
  ...defaultBaseElementComputed,
  _computed: {
    createdResponseCount: 0,
    deletedResponseCount: 0,
    responseCount: 0,
    responseCountPerLocaleInitial: {},
    responseCountPerLocaleSwiched: {},
    responseCountPerNavigatorLocale: {},
    statistics: getDefaultStatistics({ crc: 0, drc: 0, rc: 0 })
  },
  _meta: defaultRecordMeta
}

export const defaultI18nGroupDB: I18nGroupDB = {
  ...defaultBaseGroupDB,
  name: '',
  publishingState: 'published',
  protectionGroupID: '',
  _meta: defaultRecordMeta,
  public: {
    ...defaultBaseGroupDB.public,
    display: {
      displayType: 'inline',
      imageUrl: { locales: { default: '' }, _ltType: true },
      tintMode: 'partial',
      designExpanded: {
        ...defaultWidgetDesignDefinition,
        ...defaultExpandedWidgetDesignDefinition
      },
      designTile: {
        ...defaultWidgetDesignDefinition,

        ...defaultTileWidgetDesignDefinition
      }
    },
    title: { locales: { default: '' }, _ltType: true },
    subtitle: { locales: { default: '' }, _ltType: true },
    description: { locales: { default: '' }, _ltType: true },
    order: 0
  }
}

// #region Script Module
export const defaultScriptModuleDB: ScriptModuleDB = {
  activated: true,
  public: {
    type: 'Script'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultScriptGroupDB: ScriptGroupDB = {
  ...defaultBaseGroupDB,
  publishedIdentifiers: [],
  publishCategories: false
}

export const defaultScriptElementDB: ScriptElementDB = {
  ...defaultBaseElementDB,
  dataDefinition: {
    d1: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d2: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d3: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d4: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d5: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    },
    d6: {
      datatype: 'other',
      name: '',
      order: 0,
      title: '',
      categories: [],
      allowEmpty: true,
      validatorType: 'validatorType_none',
      defaultValue: '',
      allowSavingInvalid: false,
      validator: {
        choices: [],
        minMax: [0, 0],
        regex: '',
        pattern: '',
        value: ''
      }
    }
  },
  name: 'new Script Element',
  public: {
    data: `// add your code here
functions.log('context', ctx);
events.onPageChanged((d) => functions.log('onpageChange ',d))
events.onLocaleChanged((d) => functions.log('onLocaleChanged ',d))
events.onResponseCreated((d) => functions.log('onResponseCreated ',d))
functions.log('test')
    `,
    order: 0,
    groupID: 'default-group'
  },
  publishingState: 'draft',
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

// #endregion Script Module

// #region Protection Module
export const defaultProtectionModuleDB: ProtectionModuleDB = {
  activated: true,
  public: {
    type: 'Protection'
  },
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultProtectionGroupDB: ProtectionGroupDB = {
  ...defaultBaseGroupDB
}

export const defaultProtectionElementDB: ProtectionElementDB = {
  ...defaultBaseElementDB,
  name: '',
  mechanism: {
    identifier: '',
    password: ''
  },
  public: {
    pwHint: {
      _ltType: true,
      locales: {
        default: ''
      }
    },
    order: 0,

    groupID: 'default-group'
  },
  publishingState: 'draft',
  ...defaultBaseElementComputed,
  _meta: defaultRecordMeta
}

export const defaultProtectionResponseDB: ProtectionResponseDB = {
  ...defaultBaseResponseDB,
  public: {
    asidID: '',
    elementID: '',
    responseTransactionID: '',
    tenantID: '',
    data: {},
    locale: 'default'
  },
  data: {
    unlockedGroup: {
      id: '',
      name: '',
      state: 'unlocked',
      type: 'Session'
    }
  },
  _meta: defaultRecordMeta
}
// #endregion Protection Module

// #region i18n Module
export const defaultI18nElementDB: I18nElementDB = {
  ...defaultBaseElementDB,
  name: '',
  public: {
    i18n: '',
    order: 0,
    groupID: 'default-group'
  },
  publishingState: 'draft',
  _meta: defaultRecordMeta,
  ...defaultBaseElementComputed
}
// #endregion i18n Module

export function getDefaultStatistics<T>(events: T): Statistics<T> {
  return {
    time: {},
    date: {
      e: events,
      y: {}
    }
  }
}

export const defaultCodeConfig: CodeConfig = {
  id: '1',
  errorCorrectionLevel: 'Q',
  customText: '',
  customText2: '',
  color: '',
  height: 50,
  width: 50,
  svgTemplateUrl: 'ymyvCodeEchoCodeLogo',
  logoUrl: ''
}

export const defaultAsidDB: AsidDB = {
  activated: false,
  batchName: '',
  codeConfig: defaultCodeConfig,
  categoryIDs: [],
  batchIDs: [],
  identifierValue: {
    i1: null,
    i2: null,
    i3: null,
    i4: null,
    i5: null,
    i6: null
  },
  assetAttributeValue: {
    ...(Object.fromEntries(
      Array.from(Array(200).keys()) /// 0..49
        .map((i) => ['a' + (i + 1), null])
    ) as assetAttributeValue)
  },
  tenantID: null,
  dateAssigned: null,
  dateActivated: null,
  publishingState: 'published',
  state: 'generated',
  type: 'product-based',
  _computed: {
    responseCountPerModule: {
      file: 0,
      form: 0,
      custom: 0,
      script: 0,
      protection: 0,
      service: 0
    },
    pageviews: 0,
    statistics: getDefaultStatistics({
      to: 0,

      fo: 0,
      fi: 0,
      pr: 0,
      sc: 0,
      cu: 0,
      i8: 0,
      se: 0,

      pv: 0
    })
    // responseCountPerModuleResetable: {
    //   file: 0,
    //   form: 0,
    //   custom: 0,
    //   script: 0,
    //   protectionGroupID: 0
    // },

    // pageviewsResetable: 0
  },
  _meta: defaultRecordMeta
}

export const defaultPlanDB: PlanDB = {
  availableAsidSlots: 1,
  additionalInteractions: 0,
  availableStorageQuota: 0,
  activeFeatures: [],
  _computed: {
    activatedAsids: 0,
    assignedAsids: 0,
    totalUsedInteractions: 0,
    totalUsedStorageQuota: 0,
    totalUsedAppStorageQuota: 0,
    totalUsedBackendStorageQuota: 0,

    statistics: getDefaultStatistics({
      ac: 0, // activatedAsids
      as: 0, // assignedAsids
      ti: 0, // totalUsedInteractions
      ts: 0, // totalUsedStorageQuota
      tb: 0, // totalUsedBackendStorageQuota
      ta: 0, // totalUsedAppStorageQuota

      de: 0, // characters translated
      dr: 0 // characters response
    })
  },
  type: 'test_plan',
  expires: new Date(),
  _meta: defaultRecordMeta
}

export const defaultTerms: Terms = {
  acceptedTermsHash: '',
  acceptedByUserId: '',
  dateAccepted: new Date(new Date().setDate(new Date().getDate() - 31)),
  customTermsText: '',
  acceptCustomTermsUntil: new Date(new Date().setDate(new Date().getDate() + 2))
}

export const defaultTenantDB: TenantDB = {
  name: '',
  _number: 0,
  _nameLower: '',
  terms: defaultTerms,
  odp: defaultTerms,
  masterData: {
    phone: '',
    email: '',
    billingEmail: '',
    billingAddress: {
      tenantName: '',
      street: '',
      number: '',
      zip: 0,
      city: '',
      notes: '',
      country: ''
    },
    shippingAddress: {
      tenantName: '',
      street: '',
      number: '',
      zip: 0,
      city: '',
      notes: '',
      country: ''
    }
  },
  _meta: defaultRecordMeta
}

export const defaultCategoryItem: CategoryItem = {
  name: '',
  parentID: '',
  description: '',
  publishingState: 'published',
  _computed: {
    linkedAsids: 0,
    linkedElements: 0
  }
}

export const defaultCategory: Category = {
  ...defaultCategoryItem,
  id: 'defaultID'
}

export const defaultRootCategoryItem: CategoryItem = {
  ...defaultCategoryItem,
  name: 'All Categories'
}

const defaultCategoryCollectionDocDB: CategoryCollectionDocDB = {
  collection: {
    [ROOT_CATEGORY_ID]: defaultRootCategoryItem
  },
  _meta: defaultRecordMeta
}

export const defaultLegalConfig: LegalConfig = {
  imprint: {
    linkTitle: { _ltType: true, _hidden: false, locales: { default: '' } },
    text: { _ltType: true, _hidden: false, locales: { default: '' } }
  },
  other: {
    linkTitle: { _ltType: true, _hidden: false, locales: { default: '' } },
    text: { _ltType: true, _hidden: false, locales: { default: '' } }
  },
  privacy: {
    linkTitle: { _ltType: true, _hidden: false, locales: { default: '' } },
    text: { _ltType: true, _hidden: false, locales: { default: '' } }
  },
  terms: {
    linkTitle: { _ltType: true, _hidden: false, locales: { default: '' } },
    text: { _ltType: true, _hidden: false, locales: { default: '' } }
  }
}

// separated to accomodate rules 1000 expressions limit
const defaultAppConfigDB: AppConfigDB = {
  public: {
    legal: defaultLegalConfig,
    pages: []
  },
  _meta: defaultRecordMeta
}

const defaultUserDB: UserReferenceDB = {
  email: '',
  active: true,
  name: '',
  notes: '',
  privileges: [],
  settings: {},
  state: {
    seenUnseen: {}
  },
  visibleCategories: [],
  _meta: defaultRecordMeta
}

const defaultApiUserDB: ApiUserReferenceDB = {
  apiTokenHash: '',
  apiUserName: '',
  apiTokenID: '',
  active: true,
  notes: '',
  privileges: [],
  _meta: defaultRecordMeta
}

export const defaultBackendConfigDB: BackendConfigDB = {
  asid: {
    identifierDefinition: {
      i1: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      },
      i2: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      },
      i3: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      },
      i4: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      },
      i5: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      },
      i6: {
        allowEmpty: true,
        validatorType: 'validatorType_none',
        defaultValue: '',
        allowSavingInvalid: false,
        validator: {
          choices: [],
          minMax: [0, 0],
          regex: '',
          pattern: '',
          value: ''
        },
        datatype: 'other',
        name: '',
        order: 0,
        title: '',
        categories: []
      }
    },
    assetAttributeDefinitions: {
      ...(Object.fromEntries(
        Array.from(Array(200).keys()) /// 0..49
          .map((i) => [
            'a' + (i + 1),
            {
              allowEmpty: true,
              validatorType: 'validatorType_none',
              defaultValue: '',
              allowSavingInvalid: false,
              validator: {
                choices: [],
                minMax: [0, 0],
                regex: '',
                pattern: '',
                value: ''
              },
              datatype: 'other',
              name: '',
              order: 0,
              title: '',
              categories: []
            } as DataDefinition
          ])
      ) as assetAttributeDefinitions)
    },
    categoryDefinitions: {
      e1: {
        title: 'Categories',
        order: 1,
        validator: {
          pivotCategory: ROOT_CATEGORY_ID,
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      },
      e2: {
        title: '',
        order: 2,
        validator: {
          pivotCategory: '',
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      },
      e3: {
        title: '',
        order: 3,
        validator: {
          pivotCategory: '',
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      },
      e4: {
        title: '',
        order: 4,
        validator: {
          pivotCategory: '',
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      },
      e5: {
        title: '',
        order: 5,
        validator: {
          pivotCategory: '',
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      },
      e6: {
        title: '',
        order: 6,
        validator: {
          pivotCategory: '',
          constraint: 'categoryEntryConstraint_anyNode',
          maxCount: 10,
          minCount: 0
        }
      }
    },

    baseUrl: '',
    redirectUrl: '',
    customNumberRange: ''
  },
  codes: [defaultCodeConfig],
  activation: {
    confirmActivation: true,
    confirmUpdate: true,
    workflows: []
  },
  _meta: defaultRecordMeta
}

const defaultAdminDataPerTenantDB: AdminDataPerTenantDB = {
  notes: '',
  _meta: defaultRecordMeta
}

const defaultGlobalAdminNotificationDB: GlobalAdminNotificationDB = {
  backendNotification: {
    // shown on every page in backend
    text: '',
    show: false,
    type: 'info',
    blockBackend: false,
    reshowEverySeconds: 60 * 5
  },
  appNotification: {
    // shown on every page in backend
    text: '',
    show: false,
    type: 'info',
    blockBackend: false,
    reshowEverySeconds: 60 * 5
  },
  _meta: defaultRecordMeta
}

const defaultHelpRessourcesDB: HelpRessouceDB = {
  category: 'Manuals',
  ressourceType: '',
  order: 0,
  section: '',
  name: { locales: { en: '' }, _ltType: true },
  url: { locales: { en: '' }, _ltType: true },
  faqData: [],
  _meta: defaultRecordMeta
}

const defaultGlobalAdminCodeConfigDB: GlobalAdminCodeConfigDB = {
  config: {
    codes: [defaultCodeConfig],
    baseUrl: ''
  },
  _meta: defaultRecordMeta
}

const defaultGlobalAdminStatisticsDB: GlobalAdminStatisticsDB = {
  _computed: {
    statistics: getDefaultStatistics({
      ac: 0, // activatedAsids
      as: 0, // assignedAsids
      ti: 0, // totalUsedInteractions
      ts: 0, // totalUsedStorageQuota
      tb: 0, // totalUsedBackendStorageQuota
      ta: 0, // totalUsedAppStorageQuota

      // module created response counts
      to: 0, // total

      fo: 0, // form
      fi: 0, // file
      pr: 0, // protection
      sc: 0, // script
      cu: 0, // custom
      i8: 0, // i18n
      se: 0, // service

      pv: 0, // pageviews

      de: 0, // characters translated
      dr: 0 // characters response
    })
  }
}

const defaultBatchDB: BatchDB = {
  tenantID: '',
  name: '',

  type: '', // print oder, group, other

  notes: '',

  reference: '',
  orderConfNumber: '',
  invoiceNumber: '',
  orderNumber: '',

  responsible: '',
  state: '',

  print: {
    expectedDeliveryDate: new Date(),
    layoutNo: '',
    notesExported: '',
    state: ''
  },

  _computed: {
    asidCount: 0 // number of asids in batch
  },

  _meta: defaultRecordMeta
}

const defaultAuditLogDB: AuditLogDB = {
  tenantID: '',

  relatedDoc: {
    type: 'Other',
    subtype: '',
    docpath: '',
    docId: ''
  },

  operation: 'update',
  text: '',

  changes: [],

  _meta: defaultRecordMeta
}

const defaultAlarmLogDB: AlarmLogDB = {
  tenantID: '',

  code: '',
  message: '',
  scope: '',

  occurenceCount: 0,
  relatedDoc: {
    type: 'Other',
    subtype: '',
    docpath: '',
    docId: ''
  },
  severity: 'info',

  _meta: defaultRecordMeta
}

export type DatabaseSchemaCollection = {
  __NAME__: string

  __EMPTY_DOC__?: BaseDB
  __PRIVILEGES__?: RequiredDocPrivileges

  __DOCUMENT_PATH__: (...args: any[]) => string
  __COLLECTION_PATH__: (...args: any[]) => string
} & {
  [key: string]: DatabaseSchemaCollection // doc name
}

// type DatabaseSchema = {
//   COLLECTIONS: {
//     [key: string]: DatabaseSchemaCollection
//   }
// }

const databaseSchema = {
  COLLECTIONS: {
    ADMIN: {
      __NAME__: 'Admin',

      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.ADMIN.__NAME__}`,

      CODE_CONFIG: {
        __NAME__: 'CodeConfig',
        __EMPTY_DOC__: defaultGlobalAdminCodeConfigDB,
        __PRIVILEGES__: {
          r: [],
          w: []
        } as RequiredDocPrivileges,

        __DOCUMENT_PATH__: () =>
          `${databaseSchema.COLLECTIONS.ADMIN.__NAME__}/${databaseSchema.COLLECTIONS.ADMIN.CODE_CONFIG.__NAME__}`
      },

      STATISTICS: {
        __NAME__: 'Statistics',
        __EMPTY_DOC__: defaultGlobalAdminStatisticsDB,
        __PRIVILEGES__: {
          r: [],
          w: []
        } as RequiredDocPrivileges,

        __DOCUMENT_PATH__: () =>
          `${databaseSchema.COLLECTIONS.ADMIN.__NAME__}/${databaseSchema.COLLECTIONS.ADMIN.STATISTICS.__NAME__}`
      },

      BATCH: {
        __NAME__: 'Batch',
        __EMPTY_DOC__: {},
        __PRIVILEGES__: {
          r: [],
          w: []
        } as RequiredDocPrivileges,

        __DOCUMENT_PATH__: () =>
          `${databaseSchema.COLLECTIONS.ADMIN.__NAME__}/${databaseSchema.COLLECTIONS.ADMIN.BATCH.__NAME__}`,

        BATCHES: {
          __NAME__: 'Batches',
          __EMPTY_DOC__: defaultBatchDB,
          __PRIVILEGES__: {
            r: [],
            w: []
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (batchID: string) =>
            `${databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__()}/${batchID}`,
          __COLLECTION_PATH__: () =>
            `${databaseSchema.COLLECTIONS.ADMIN.BATCH.__DOCUMENT_PATH__()}/${databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__NAME__
            }`
        }
      },

      NOTIFICATION: {
        __NAME__: 'Notification',
        __EMPTY_DOC__: defaultGlobalAdminNotificationDB,
        __PRIVILEGES__: {
          r: [],
          w: []
        } as RequiredDocPrivileges,
        __DOCUMENT_PATH__: () =>
          `${databaseSchema.COLLECTIONS.ADMIN.__NAME__}/${databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__NAME__}`
      }
    },

    HELPRESSOURCES: {
      __NAME__: 'HelpRessources',
      __EMPTY_DOC__: defaultHelpRessourcesDB,
      __PRIVILEGES__: {
        r: [],
        w: []
      } as RequiredDocPrivileges,
      __DOCUMENT_PATH__: (objectId: objectID) =>
        `${databaseSchema.COLLECTIONS.HELPRESSOURCES.__COLLECTION_PATH__()}/${objectId}`,
      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.HELPRESSOURCES.__NAME__}`
    },

    ASID: {
      __NAME__: 'Asids',
      __EMPTY_DOC__: defaultAsidDB as AsidDB,
      __PRIVILEGES__: {
        r: [dbPrivileges.ASID_READ],
        w: [dbPrivileges.ASID_WRITE]
      } as RequiredDocPrivileges,

      __DOCUMENT_PATH__: (asidId: string) => `${databaseSchema.COLLECTIONS.ASID.__NAME__}/${asidId}`,
      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.ASID.__NAME__}`
    },

    TENANTS: {
      __NAME__: 'Tenants',
      __EMPTY_DOC__: defaultTenantDB,
      __PRIVILEGES__: {
        r: [dbPrivileges.ANY],
        w: [dbPrivileges.MASTERDATA_WRITE]
      } as RequiredDocPrivileges,

      __DOCUMENT_PATH__: (tenantId: string) => `${databaseSchema.COLLECTIONS.TENANTS.__NAME__}/${tenantId}`,
      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.TENANTS.__NAME__}`,

      DATA: {
        __NAME__: 'Data',
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__
          }`,
        __DOCUMENT_PATH__: (tenantId: string, dataID: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${dataID}`,

        CATEGORIES: {
          __NAME__: 'Categories',
          __EMPTY_DOC__: defaultCategoryCollectionDocDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.CATEGORIES_READ],
            w: [dbPrivileges.CATEGORIES_WRITE]
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__NAME__
            }`
        },

        PLAN: {
          __NAME__: 'Plan',
          __EMPTY_DOC__: defaultPlanDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.CONFIG_READ],
            w: [dbPrivileges.CONFIG_WRITE]
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__NAME__
            }`
        },

        BACKEND_CONFIG: {
          __NAME__: 'BackendConfig',
          __EMPTY_DOC__: defaultBackendConfigDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.CONFIG_READ],
            w: [dbPrivileges.CONFIG_WRITE]
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__NAME__
            }`
        },

        APP_CONFIG: {
          __NAME__: 'AppConfig',
          __EMPTY_DOC__: defaultAppConfigDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.CONFIG_READ],
            w: [dbPrivileges.CONFIG_WRITE]
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONFIG.__NAME__
            }`
        },

        ADMIN_DATA: {
          // data only readable by super admins
          __NAME__: 'AdminData',
          __EMPTY_DOC__: defaultAdminDataPerTenantDB,
          __PRIVILEGES__: {
            r: [],
            w: []
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.ADMIN_DATA.__NAME__
            }`
        },

        // deprecated - TODO remove - it is not used currently
        STATISTICS: {
          __NAME__: 'Statistics',
          __PRIVILEGES__: {
            r: ['statistics:read'],
            w: []
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.STATISTICS.__NAME__
            }`
        },

        APP_SESSION: {
          __NAME__: 'AppSession',
          __EMPTY_DOC__: defaultAppSessionDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.ANY],
            w: []
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__NAME__
            }`,

          SESSIONS: {
            __NAME__: 'Sessions',
            __EMPTY_DOC__: defaultSessionsDB,
            __PRIVILEGES__: {
              r: [dbPrivileges.ANY],
              w: []
            } as RequiredDocPrivileges,

            __DOCUMENT_PATH__: (tenantId: string, sessionID: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.SESSIONS.__COLLECTION_PATH__(
                tenantId
              )}/${sessionID}`,
            __COLLECTION_PATH__: (tenantId: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.SESSIONS.__NAME__
              }`
          }
        },

        APP_CONTACTS: {
          __NAME__: 'AppContacts',
          __EMPTY_DOC__: defaultAppContactDB,
          __PRIVILEGES__: {
            r: [dbPrivileges.ANY],
            w: []
          } as RequiredDocPrivileges,

          __DOCUMENT_PATH__: (tenantId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.DATA.__COLLECTION_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONTACTS.__NAME__
            }`,

          CONTACTS: {
            __NAME__: 'Contacts',
            __EMPTY_DOC__: defaultContactDB,
            __PRIVILEGES__: {
              r: [dbPrivileges.CONTACT_READ],
              w: [dbPrivileges.CONTACT_WRITE]
            } as RequiredDocPrivileges,

            __DOCUMENT_PATH__: (tenantId: string, contactID: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONTACTS.CONTACTS.__COLLECTION_PATH__(
                tenantId
              )}/${contactID}`,
            __COLLECTION_PATH__: (tenantId: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONTACTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONTACTS.CONTACTS.__NAME__
              }`
          }
        }
      },

      USERS: {
        __NAME__: 'Users',
        __EMPTY_DOC__: defaultUserDB,
        __PRIVILEGES__: {
          r: [dbPrivileges.USER_READ],
          w: [dbPrivileges.USER_WRITE]
        } as RequiredDocPrivileges,

        __DOCUMENT_PATH__: (tenantId: string, userID: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.USERS.__COLLECTION_PATH__(tenantId)}/${userID}`,
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.USERS.__NAME__
          }`
      },

      APIUSERS: {
        __NAME__: 'ApiUsers',
        __EMPTY_DOC__: defaultApiUserDB,
        __PRIVILEGES__: {
          r: [dbPrivileges.USER_READ],
          w: [dbPrivileges.USER_WRITE]
        } as RequiredDocPrivileges,

        __DOCUMENT_PATH__: (tenantId: string, userID: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.APIUSERS.__COLLECTION_PATH__(tenantId)}/${userID}`,
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.APIUSERS.__NAME__
          }`
      },

      MODULES: {
        __NAME__: 'Modules',

        __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType) =>
          `${databaseSchema.COLLECTIONS.TENANTS.MODULES.__COLLECTION_PATH__(tenantId)}/${moduleType}`,
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.__NAME__
          }`,

        ELEMENTS: {
          __NAME__: 'Elements',
          __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, elementId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.__COLLECTION_PATH__(
              tenantId,
              moduleType
            )}/${elementId}`,
          __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.__DOCUMENT_PATH__(tenantId, moduleType)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.__NAME__
            }`,

          AUDIT_LOGS: {
            __NAME__: 'Auditlog',
            __EMPTY_DOC__: defaultAuditLogDB,

            __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, elementId: string, logID: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.AUDIT_LOGS.__COLLECTION_PATH__(
                tenantId,
                moduleType,
                elementId
              )}/${logID}`,
            __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType, elementId: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.__DOCUMENT_PATH__(
                tenantId,
                moduleType,
                elementId
              )}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.AUDIT_LOGS.__NAME__}`
          }
        },

        GROUPS: {
          __NAME__: 'Groups',
          __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, groupId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.__COLLECTION_PATH__(tenantId, moduleType)}/${groupId}`,
          __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.__DOCUMENT_PATH__(tenantId, moduleType)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.__NAME__
            }`,

          AUDIT_LOGS: {
            __NAME__: 'Auditlog',
            __EMPTY_DOC__: defaultAuditLogDB,

            __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, groupID: string, logID: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.AUDIT_LOGS.__COLLECTION_PATH__(
                tenantId,
                moduleType,
                groupID
              )}/${logID}`,
            __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType, groupID: string) =>
              `${databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.__DOCUMENT_PATH__(tenantId, moduleType, groupID)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.AUDIT_LOGS.__NAME__
              }`
          }
        },

        RESPONSES: {
          __NAME__: 'Responses',
          __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, responseId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__COLLECTION_PATH__(
              tenantId,
              moduleType
            )}/${responseId}`,
          __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.__DOCUMENT_PATH__(tenantId, moduleType)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__NAME__
            }`
        },

        RESPONSE_ITEMS: {
          __NAME__: 'ResponseItems',
          __DOCUMENT_PATH__: (tenantId: string, moduleType: ModuleType, responseItemId: string) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSE_ITEMS.__COLLECTION_PATH__(
              tenantId,
              moduleType
            )}/${responseItemId}`,
          __COLLECTION_PATH__: (tenantId: string, moduleType: ModuleType) =>
            `${databaseSchema.COLLECTIONS.TENANTS.MODULES.__DOCUMENT_PATH__(tenantId, moduleType)}/${databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSE_ITEMS.__NAME__
            }`
        }
      },

      AUDIT_LOGS: {
        __NAME__: 'Auditlog',
        __EMPTY_DOC__: defaultAuditLogDB,

        __DOCUMENT_PATH__: (tenantId: string, logID: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.AUDIT_LOGS.__COLLECTION_PATH__(tenantId)}/${logID}`,
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.AUDIT_LOGS.__NAME__
          }`
      },

      ALARM_LOGS: {
        __NAME__: 'Alarmlog',
        __EMPTY_DOC__: defaultAlarmLogDB,

        __DOCUMENT_PATH__: (tenantId: string, logID: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.ALARM_LOGS.__COLLECTION_PATH__(tenantId)}/${logID}`,
        __COLLECTION_PATH__: (tenantId: string) =>
          `${databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(tenantId)}/${databaseSchema.COLLECTIONS.TENANTS.ALARM_LOGS.__NAME__
          }`
      }
    },

    EMAILS: {
      __NAME__: 'Emails',

      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.EMAILS.__NAME__}`,
      __DOCUMENT_PATH__: (emailID: string) => `${databaseSchema.COLLECTIONS.EMAILS.__NAME__}/${emailID}`
    },

    AGGREGATION_UPDATE: {
      __NAME__: 'UpdateQueue',

      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.AGGREGATION_UPDATE.__NAME__}`,
      __DOCUMENT_PATH__: () => `${databaseSchema.COLLECTIONS.AGGREGATION_UPDATE.__NAME__}/UpdateQueueControl`,

      AGGREGATION_UPDATES: {
        __NAME__: 'UpdateQueues',

        __DOCUMENT_PATH__: (updateID: string) =>
          `${databaseSchema.COLLECTIONS.AGGREGATION_UPDATE.AGGREGATION_UPDATES.__COLLECTION_PATH__()}/${updateID}`,
        __COLLECTION_PATH__: () =>
          `${databaseSchema.COLLECTIONS.AGGREGATION_UPDATE.__DOCUMENT_PATH__()}/${databaseSchema.COLLECTIONS.AGGREGATION_UPDATE.AGGREGATION_UPDATES.__NAME__
          }`
      }
    },

    AI_REQUEST: {
      __NAME__: 'Ai',

      __COLLECTION_PATH__: () => `${databaseSchema.COLLECTIONS.AI_REQUEST.__NAME__}`,
      __DOCUMENT_PATH__: () => `${databaseSchema.COLLECTIONS.AI_REQUEST.__NAME__}/AiQueueControl`,

      AI_REQUESTS: {
        __NAME__: 'AiRequests',

        __DOCUMENT_PATH__: (aiID: string) =>
          `${databaseSchema.COLLECTIONS.AI_REQUEST.AI_REQUESTS.__COLLECTION_PATH__()}/${aiID}`,
        __COLLECTION_PATH__: () =>
          `${databaseSchema.COLLECTIONS.AI_REQUEST.__DOCUMENT_PATH__()}/${databaseSchema.COLLECTIONS.AI_REQUEST.AI_REQUESTS.__NAME__
          }`
      }
    }
  }
}

export default databaseSchema
