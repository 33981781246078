<template>
  <section>
    <div class="columns qr-generator">
      <div class="column">
        <!-- <VEchoCode
          :show-print="formShowPrint"
          :code-config="codeConfig"
          :asid="formSampleAsid"
          :svg-template-text="interpolatedSVG"
        />-->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div ref="qr_code" class="qr_code-preview" v-html="qrCode" />

        <div class="esil-label">{{ esilLabel }}</div>
      </div>
      <div class="column box">
        <h1 class="subtitle">ESIL</h1>

        <!-- dropdown for esil commands -->
        <b-field label="ESIL Commands">
          <b-select v-model="formSelectedEsilCmd">
            <option v-for="cmd in esilComands" :key="cmd.cmdName" :value="cmd">{{ cmd.title }}</option>
          </b-select>
        </b-field>

        <!-- if formSelectedEsilCmd has an argumentDescription, show the description and a input field for the argument -->
        <b-field v-if="formSelectedEsilCmd.argumentDescription" label="Argument">
          <template #label>
            Argument
            <VTooltipIconHelp :text="formSelectedEsilCmd.argumentDescription " />
          </template>
          <b-field>
            <b-input v-model="formArgument" expanded />
          </b-field>
        </b-field>

        <!-- if formSelectedEsilCmd has an valueDescription, show the description and a input field for the value -->
        <b-field v-if="formSelectedEsilCmd.valueDescription" label="Value">
          <template #label>
            Value
            <VTooltipIconHelp :text="formSelectedEsilCmd.valueDescription " />
          </template>
          <b-field>
            <b-input v-model="formValue" expanded />

            <p class="control">
              <b-button @click="formValue = 'PREVIOUS_DATA'">Use Previous Data</b-button>
            </p>
          </b-field>
        </b-field>

        <hr />

        <b-field label="ESIL String">
          <b-field>
            <b-input v-model="esilString" expanded />

            <p class="control">
              <b-button @click="url = esilString">Copy To QR</b-button>
            </p>
          </b-field>
        </b-field>

        <hr />
        <h1 class="subtitle">Input</h1>

        <b-field label="QR Data">
          <b-field>
            <b-input v-model="url" expanded />

            <p class="control">
              <b-button @click="url = url.toUpperCase()">Optimize</b-button>
            </p>
          </b-field>
        </b-field>

        <b-field label="ECL">
          <b-select v-model="ecl">
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="Q">Q</option>
            <option value="H">H</option>
          </b-select>
        </b-field>

        <b-field label="Background">
          <VInputColorpicker v-model="bgColor" allow-reset color-format="hex" />
        </b-field>
        <b-field label="Foreground">
          <VInputColorpicker v-model="fgColor" allow-reset color-format="hex" />
        </b-field>

        <b-button @click="onDownload">Download QR Code</b-button>

        <hr />
      </div>

      <div class="column box">
        <h1 class="subtitle">Test</h1>
        <VScanInterface :dry-run="false" :cmd-callbacks="cmdCallbacks" />
        <h1 class="subtitle">QR Info</h1>
        <div>
          <div class="label">Version: {{ qrStats.version }}</div>
          <div class="label">Mode: {{ qrStats.mode }}</div>
          <div class="label">Mask: {{ qrStats.mask }}</div>
          <div class="label">ECL: {{ qrStats.ecl }}</div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import VInputColorpicker from '@/components/VInputColorpicker.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { saveAs } from 'file-saver'
import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import AsidManager from '@/database/asidManager'
import { allCmds, createCmdString, ScanInterfaceCmdCallback, ScanInterfaceCmdName } from '@/helpers/scanInterface'
import VScanInterface from '@/components/VScanInterface.vue'

library.add(faUndo)


@Component({
  components: { VInputColorpicker, VScanInterface }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminESILGenerator extends Vue {
  // #region esil


  get esilString() {
    return createCmdString({
      name: this.formSelectedEsilCmd.cmdName,
      argument: this.formArgument,
      value: this.formValue
    })
  }

  get esilLabel() {
    return `${this.formSelectedEsilCmd.title} ${this.formArgument} ${this.formValue}`
  }

  get esilFileName() {
    return `${this.formSelectedEsilCmd.cmdName}${this.formArgument ? '-' + this.formArgument.replace(' ', '_') : ''}${this.formValue ? '-' + this.formValue.replace(' ', '_') : ''}.esil`
  }

  public esilComands: { title: string, cmdName: ScanInterfaceCmdName, description: string, argumentDescription?: string, valueDescription?: string }[] = [
    { title: 'Set Identifier', cmdName: 'set-identifier', description: 'Set the identifier specified as argument to the given value', argumentDescription: 'select identifier name or id', valueDescription: 'set the identifier value' },
    { title: 'Set Attribute', cmdName: 'set-attribute', description: 'Set the attribute specified as argument to the given value', argumentDescription: 'select attribute name or id', valueDescription: 'set the attribute value' },
    { title: 'Set Category', cmdName: 'set-category', description: 'Set the category specified as argument to the given value', argumentDescription: '', valueDescription: 'set the category id or name' },
    { title: 'Add Category', cmdName: 'add-category', description: 'Add the category specified as argument to the given value', argumentDescription: '', valueDescription: 'add the category id or name' },
    { title: 'Activate Asid', cmdName: 'activate-asid', description: 'Activate the asid specified as argument', argumentDescription: 'select asid id', valueDescription: '' },
    { title: 'Open Asid', cmdName: 'open-asid', description: 'Open the asid specified as argument', argumentDescription: 'select asid id', valueDescription: '' },
    { title: 'Show Message', cmdName: 'show-message', description: 'Show the message specified as argument', argumentDescription: '', valueDescription: 'Message to display' }
  ]

  public cmdCallbacks: Array<ScanInterfaceCmdCallback> = allCmds

  // when formSelectedEsilCmd is changed, argument and value are reset
  @Watch('formSelectedEsilCmd')
  public onEsilCmdChange() {
    this.formArgument = ''
    this.formValue = ''
  }

  public formSelectedEsilCmd: typeof this.esilComands[0] = this.esilComands[0]

  public formArgument: string = ''
  public formValue: string = ''

  // #endregion esil


  public qrStats: {
    mask: number
    version: number
    ecl: string
    mode: string
    QRsvg: string
  } = {
      mask: 0,
      version: 0,
      ecl: '',
      mode: '',
      QRsvg: ''
    }

  public url: string = ''
  public ecl: 'L' | 'M' | 'Q' | 'H' = 'L'
  public bgColor: string = 'black'
  public fgColor: string = 'white'

  get qrCode() {
    this.qrStats = AsidManager.getDebugQrCodeSvg(
      this.url,
      this.ecl,
      this.bgColor,
      this.fgColor
    )

    return this.qrStats.QRsvg
  }

  public onDownload() {
    const svgText = (this.$refs.qr_code as Element).innerHTML
    const svgBlob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' })
    console.log('sdsdsdsdsd')

    saveAs(svgBlob, `QR_CODE-${this.esilFileName}.svg`)
  }

  public async mounted() {
    // this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })
    // this.isLoading = false
  }
}
</script>

<style lang="scss">
.qr-generator {
  .column {
    margin: 0 0.5rem;
  }
}
</style>
