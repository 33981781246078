/**
 * the Scan Activation Interface allows to use a 1,2-d barcode scanner to perform the activation and assignment process
 * this enables quicker and less error prone activation and assignment of devices
 *
 * the core of this process is the ECHO SCAN INTERFACE LANGUAGE (ESIL)
 * it alows to create text based cmds, encoded in qr codes, that can be scanned by the scanner amd are then executed on the activation page
 *
 * design goals:
 * - the cmds should be easy to read and understand
 * - the cmds should be easy to create
 * - it shall be possible to add arbitrary barcode/qr-code data where the user has no control over the content
 * - the parsing and error handling should be as robust as possible
 *
 * the syntax is as follows:
 * <CMD_START><CMD_NAME>{<CMD_ARGUMENT_START><CMD_ARGUMENT><CMD_ARGUMENT_END>}{<CMD_VALUE_SEPARATOR><CMD_VALUE>}<CMD_END>
 *
 * CMD_START: the start of a cmd
 * CMD_NAME: the name of the cmd
 * CMD_ARGUMENT: the argument of the cmd, optional
 * CMD_VALUE_SEPARATOR: the separator between the cmd name and the cmd value, optional
 * CMD_VALUE: the value of the cmd, optional
 * CMD_END: the end of a cmd
 *
 */

// the cmd start and end chars
const CMD_START = 'CMD_START:'
const CMD_END = ':CMD_END'
const CMD_ARGUMENT_START = '('
const CMD_ARGUMENT_END = ')'
const CMD_VALUE_SEPARATOR = '='

const CMD_MAGIC_VALUE_PREVIOUS_DATA = 'PREVIOUS_DATA' // will be replaced with tha data before the cmd

export type ScanInterfaceCmdName =
  | 'set-identifier'
  | 'set-attribute'
  | 'activate-asid'
  | 'open-asid'
  | 'show-message'
  | 'set-category'
  | 'add-category'

export interface ScanInterfaceCmd {
  name: ScanInterfaceCmdName
  argument: string
  value: string
}

export type ScanInterfaceCmdCallback = { name: ScanInterfaceCmd['name'], fn: (cmd: ScanInterfaceCmd) => void | Promise<void> }


export const allCmds: Array<ScanInterfaceCmdCallback> = [
  {
    name: 'set-identifier',
    fn: (cmd: ScanInterfaceCmd) => {
      // set the identifier
      console.log('executing set-identifier', cmd)
    }
  },
  {
    name: 'set-attribute',
    fn: (cmd: ScanInterfaceCmd) => {
      // set the attribute
      console.log('executing set-attribute', cmd)
    }
  },
  {
    name: 'activate-asid',
    fn: (cmd: ScanInterfaceCmd) => {
      // activate the asid
      console.log('executing activate-asid', cmd)
    }
  },
  {
    name: 'open-asid',
    fn: (cmd: ScanInterfaceCmd) => {
      // open the asid
      console.log('executing open-asid', cmd)
    }
  },
  {
    name: 'show-message',
    fn: (cmd: ScanInterfaceCmd) => {
      // show the message
      console.log('executing show-message', cmd)
    }
  },
  {
    name: 'set-category',
    fn: (cmd: ScanInterfaceCmd) => {
      // set the category
      console.log('executing set-category', cmd)
    }
  },
  {
    name: 'add-category',
    fn: (cmd: ScanInterfaceCmd) => {
      // add the category
      console.log('executing add-category', cmd)
    }
  }
]

// const sampleCMD_1 = 'CMD_START:CMD_START:set-identifier(i2)=some random value:CMD_END'
// const sampleCMD_2 = 'CMD_START:set-identifier(i3)=some random value:CMD_END CMD_START:set-identifier(i2)=123456:CMD_END'
// const sampleCMD_21 =
//   'CMD_START:set-identifier(i3)=some random value:CMD_END previous data CMD_START:set-identifier(i2)=PREVIOUS_DATA:CMD_END'
// const sampleCMD_3 =
//   'CMD_START:set-identifier(i1)=some random value:CMD_END asdasdsa CMD_START:cmd-with-arg(hello world):CMD_ENDddfgddgdg https://127.0.0.1:8088/aaaaa-tests-asids-00001 CMD_START:activate-asid:CMD_END'
// const sampleCMD_4 = createCmdString({
//   name: 'set-identifier',
//   argument: 'i3',
//   value: '1234'
// })

export function createCmdString(cmd: ScanInterfaceCmd): string {
  let cmdString = `${CMD_START}${cmd.name}`
  if (cmd.argument) {
    cmdString += `${CMD_ARGUMENT_START}${cmd.argument}${CMD_ARGUMENT_END}`
  }
  if (cmd.value) {
    cmdString += `${CMD_VALUE_SEPARATOR}${cmd.value}`
  }
  cmdString += CMD_END
  return cmdString
}

function parseCmd(cmdString: string): ScanInterfaceCmd {
  // regex to parse the pattern  <CMD_START><CMD_NAME>{<CMD_ARGUMENT_START><CMD_ARGUMENT><CMD_ARGUMENT_END>}{<CMD_VALUE_SEPARATOR><CMD_VALUE>}<CMD_END>
  const cmdRegex = new RegExp(
    `^(?<name>.+?)(\\${CMD_ARGUMENT_START}(?<argument>.+?)\\${CMD_ARGUMENT_END})?(${CMD_VALUE_SEPARATOR}(?<value>.+?))?$`,
    'i'
  )

  const match = cmdString.match(cmdRegex) // ?
  if (!match) {
    throw `invalid cmd: ${cmdString}`
  }
  const { name, argument, value } = match.groups as { name: string, argument: string, value: string }

  return {
    name: name.toLowerCase() as ScanInterfaceCmdName,
    argument: (argument || '').trim().toLowerCase(),
    value: (value || '').trim()
  }
}

/**
 * a string of chars is provided as input
 * tries to parse all cmds from the string
 * returns the parsed cmds and the remaining string
 */
export function parseCmdString(input: string): { cmds: Array<ScanInterfaceCmd>, remaining: string, errors: string[] } {
  const cmds: Array<ScanInterfaceCmd> = []
  let remainingIput = input
  let otherData: string = ''
  const errors: string[] = []

  /**
   *
   * an input string may be formatted like this:
   * <CMD_START><CMD><CMD_END> <CMD_START><CMD><CMD_END> <CMD_START><CMD><CMD_END>
   *
   * it may conatin invalid formatting, like multiple CMD_STARTs without a CMD_END
   * <CMD_START><CMD> <CMD_START><CMD><CMD_END>
   * => the first CMD_START is invalid, because it is not followed by a CMD_END
   *
   * it may contain data between the cmds
   * <CMD_START><CMD><CMD_END> someData <CMD_START><CMD><CMD_END>
   * => the data between the cmds is captured in the otherData string
   *
   */

  /**
   * the input string is parsed in three parts:
   * 1. commands (complete and valid commands, that can be executed)
   * 2. other data (data between the commands, that is not part of a command)
   * 3. remainingIput data (data that is not part of a command and not between commands)
   */
  while (remainingIput.length > 0) {
    // every iteration consumes a part of the remainingIput input. at most one cmd is consumed per iteration

    // if the string contains a url with an asid, add the asid to the cmds
    // url is e.g. https://app.echoprm.com/xxxxx-xxxxx-xxxxx-xxxxx
    // domain may vary, but the asid is always 5 groups of 5 chars, separated by a dash
    const echoCodeRegex = new RegExp('^https?://([a-z0-9]+.)*/(?<asid>([a-zA-Z0-9]{5}-){3}[a-zA-Z0-9]{5})', 'i')
    const match = remainingIput.match(echoCodeRegex) // ?
    if (match) {
      const { asid } = match.groups as { asid: string }
      cmds.push({
        name: 'open-asid',
        argument: asid.toLowerCase(),
        value: ''
      })

      remainingIput = remainingIput.replace(echoCodeRegex, '')
    }

    // find the first CMD_START
    const cmdStartIndex = remainingIput.indexOf(CMD_START)
    if (cmdStartIndex === -1) {
      // no CMD_START found, the remainingIput input is not a valid cmd
      break
    } else if (cmdStartIndex > 0) {
      // there is data before the CMD_START
      // add the data to the otherDatas
      otherData = remainingIput.substring(0, cmdStartIndex)
      remainingIput = remainingIput.substring(cmdStartIndex)
    }

    // the remainingIput now starts with a CMD_START

    // find the first CMD_END
    const cmdEndIndex = remainingIput.indexOf(CMD_END)

    // check if there are multiple CMD_STARTs before the CMD_END
    const cmdStartIndex2 = remainingIput.indexOf(CMD_START, CMD_START.length)

    // if there are multiple start index and no end index, the first start index is invalid
    // or if the second start index is before the end index, the first start index is invalid
    if ((cmdStartIndex2 !== -1 && cmdEndIndex === -1) || (cmdStartIndex2 !== -1 && cmdStartIndex2 < cmdEndIndex)) {
      // there are multiple CMD_STARTs and no CMD_END
      // the first CMD_START is invalid
      // remove everything up to the second CMD_START
      errors.push(`invalid , multiple ${CMD_START} found: ${remainingIput.substring(0, cmdStartIndex2)}`)
      remainingIput = remainingIput.substring(cmdStartIndex2)
      continue
    }

    if (cmdEndIndex === -1) {
      // no CMD_END found, the remainingIput input is not a valid cmd
      break
    }

    // the remainingIput now starts with a CMD_START and ends with a CMD_END

    // extract the cmd string
    const cmdString = remainingIput.substring(CMD_START.length, cmdEndIndex)
    try {
      // parse the cmd string
      const cmd = parseCmd(cmdString)
      // if the cmd value is CMD_MAGIC_VALUE_PREVIOUS_DATA, replace it with the otherData
      if (cmd.value.includes(CMD_MAGIC_VALUE_PREVIOUS_DATA)) {
        // if no otherData is available, throw an error
        if (!otherData) {
          throw `the comando requires ${CMD_MAGIC_VALUE_PREVIOUS_DATA}, but no other data is available`
        }
        cmd.value = cmd.value.replace(CMD_MAGIC_VALUE_PREVIOUS_DATA, otherData)
        otherData = ''
      }
      // add the cmd to the cmds
      cmds.push(cmd)
    } catch (error: any) {
      errors.push(error.toString())
    }
    // remove the cmd from the remainingIput
    remainingIput = remainingIput.substring(cmdEndIndex + CMD_END.length)
  }

  return { cmds, remaining: remainingIput, errors }
}

// function simulateScan(cmdString: string) {
//   let input = ''
//   const executedCmds: Array<ScanInterfaceCmd> = []
//   // add char by crar to the input field
//   const interval = setInterval(() => {
//     const char = cmdString[0]
//     input += char
//     cmdString = cmdString.substring(1)

//     const parsedString = parseCmdString(input) //?

//     // execute each cmd
//     parsedString.cmds.forEach((cmd) => {
//       const cmdFn = cmds.find((c) => c.name === cmd.name)?.fn
//       if (cmdFn) {
//         cmdFn(cmd)
//         executedCmds.push(cmd)
//       }
//     })

//     console.log(parsedString)

//     input = parsedString.remaining

//     // clear the interval once the cmdString is empty
//     if (cmdString.length === 0) {
//       clearInterval(interval)
//       console.log('executed cmds', executedCmds)
//     }
//   }, 200)
// }

/**
 * tries to find valid cmds in the input string and executes them
 *
 * @param cmdCallbacks
 * @returns
 */
export function ScanInterface(
  cmdCallbacks: Array<{
    name: ScanInterfaceCmd['name']
    fn: (cmd: ScanInterfaceCmd) => void
  }>,
  cmdCallback: (cmd: ScanInterfaceCmd) => void,
  errorCallback: (error: string) => void
) {
  function processText(cmdString: string) {
    const executedCmds: Array<ScanInterfaceCmd> = []

    const parsedString = parseCmdString(cmdString) // ?

    // execute each cmd
    parsedString.cmds.forEach((cmd) => {
      const cmdFn = cmdCallbacks.find((c) => c.name === cmd.name)?.fn
      if (cmdFn) {
        cmdCallback(cmd)

        try {
          cmdFn(cmd)
        } catch (error: any) {
          errorCallback(error.toString())
        }

        executedCmds.push(cmd)
      } else {
        errorCallback(`cmd not found: ${cmd.name}`)
      }
    })

    parsedString.errors.forEach((error) => {
      errorCallback(error)
    })

    return parsedString.remaining
  }

  return {
    processText
  }
}

// simulateScan(sampleCMD_3)

// console.log(parseCmdString(sampleCMD_1))
// console.log(parseCmdString(sampleCMD_2))
// console.log(parseCmdString(sampleCMD_3))
