<template>
  <section class="config-asid">
    <div class="level record-meta">
      <div class="level-left" />
      <VRecordMeta
        position="is-bottom-left"
        class="level-right"
        :record-meta="backendConfig._meta"
        :required-privileges="documentPrivileges"
        :document-path="documentPath"
      />
    </div>
    <!-- configure identifier, custom domain, -->
    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO CODE</h3>
        <span class="setting-description">Define parameters for your printed ECHO CODE</span>
      </div>

      <div class="column is-three-quarters setting-column">
        <div class="setting-box card">
          <h3 class="title is-5">Sticker Design Presets</h3>

          <b-field grouped>
            <b-field>
              <b-select v-model="formSelectedCodeConfigIndex" placeholder="Default">
                <option
                  v-for="(code,index) in backendConfig.codes"
                  :key="code.id"
                  :value="index"
                >Design {{ code.id }}</option>
              </b-select>

              <p v-if="backendConfig.codes.length > 1" class="control">
                <b-button
                  :disabled="!$auth.isEchoPrmStaff"
                  icon-left="trash"
                  type="is-danger"
                  @click="onRemoveCodeConfig"
                >remove</b-button>
              </p>
              <!-- <p class="control">
                <b-button type="is-success" @click="$saveCodeConfig">update</b-button>
              </p>-->
            </b-field>
            <b-field>
              <p class="control">
                <b-button :disabled="!$auth.isEchoPrmStaff" @click="onAddCodeConfig">add design</b-button>
              </p>
            </b-field>
          </b-field>

          <VFormConfigureSticker
            v-model="backendConfig.codes[formSelectedCodeConfigIndex]"
            :disabled="!$auth.isEchoPrmStaff"
            :base-url="backendConfig.asid.baseUrl"
            :disable-modify-error-correction-level="true"
            :disable-modify-width="true"
            :disable-custom-logo="true"
          />

          <hr />

          <b-field>
            <template slot="label">
              App domain name
              <VTooltipIconHelp
                :text="`When active, newly generated echo Codes point to ${AsidManager.createLink('<code>',backendConfig.asid.baseUrl||'<yourDomain>')}. Ask your ECHO PRM contact for more information. ` + ((backendConfig.asid.baseUrl)?`Point your ${backendConfig.asid.baseUrl} DNS record CNAME to app.echoprm.com `:'')"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.baseUrl"
              disabled
              placeholder="app.your-custom-domain.de"
            />
          </b-field>

          <b-field v-if="backendConfig.asid.customNumberRange">
            <template slot="label">
              Custom ECHO CODE Number Range
              <VTooltipIconHelp
                :text="`You can create ECHO CODES that match the format c${backendConfig.asid.customNumberRange}-xxxxx-xxxxx-xxxxx, where x may be [a-z,0-9]`"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.customNumberRange"
              disabled
            />
          </b-field>

          <b-field v-if="backendConfig.asid.baseUrl">
            <template slot="label">
              App landing page redirect url
              <VTooltipIconHelp
                :text="`When ${backendConfig.asid.baseUrl} is called without scanning an ECHO CODE (without ECHO ID parameters) the user may be redirected to a default page`"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.redirectUrl"
              type="url"
              placeholder="https://redirect-url"
            />
          </b-field>
        </div>

        <!-- <div class="setting-box card">
          <h3 class="title is-5">QR Code</h3>
           <b-field>
            <template slot="label">
              qr error correction level
              <VTooltipIconHelp
                text="Changes the QR error correction level according to QR code specification"
              />
            </template>
            <b-select placeholder="Error Correction">
              <option
                v-for="option in ERROR_CORRECTION_LEVELS"
                :key="option.key"
                :value="option.key"
              >{{ option.value }}</option>
            </b-select>
          </b-field>
        -->

        <!--
          <b-field>
            <template slot="label">
              use custom domain
              <VTooltipIconHelp text="Hides all echo PRM related branding from the app by using your own domain. Contact us for more information" />
            </template>
            <b-switch disabled :rounded="false" />
          </b-field>
        </div>-->
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO ID Identifier</h3>
        <span class="setting-description">
          One or multiple Identifiers can be added to an ECHO ID to uniquely identify the assigned physical object
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Identifiers</h3>

        <VFormDataDefinition
          ref="identifier-definition"
          v-model="backendConfig.asid.identifierDefinition"
          show-category-visibility
          show-identifier-warning
          :hide-types="['gps', 'image']"
        />
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO ID Attributes</h3>
        <span class="setting-description">
          One or multiple Attributes can be added to an ECHO ID to store ECHO ID specific values.
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Attributes</h3>

        <VFormDataDefinition
          ref="asset-attribute-definition"
          v-model="backendConfig.asid.assetAttributeDefinitions"
          show-category-visibility
          :hide-types="['gps']"
        />
      </div>
    </div>

    <hr />
    <!-- {{ testCatSelection.join(', ') }}
    <VInputMultiCategoryEntry
      v-model="testCatSelection"
      :category-entry-definitions="backendConfig.asid.categoryDefinitions"
    />-->

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Category Entry Definition</h3>
        <span class="setting-description">
          Define how categories shall be entered when assigning an ECHO CODE.
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Categories</h3>

        <VFormCategoryEntryDefinition
          ref="category-definition"
          v-model="backendConfig.asid.categoryDefinitions"
          :hide-types="['gps']"
        />
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Assignment Workflows</h3>
        <span class="setting-description">
          Define workflows to guide the activation and assignment
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Workflows</h3>

        <!-- show ui to create and modify workflows and steps -->
        <!-- iterate sortedWorkflows -->
        <SlickList
          v-model="sortedWorkflows"
          lock-axis="y"
          :use-drag-handle="true"
          @sort-end="(data)=>onWorkflowOrderChanged(data, sortedWorkflows)"
        >
          <SlickItem
            v-for="(workflow, workflowIndex) in sortedWorkflows"
            :key="workflowIndex"
            :index="workflowIndex"
            class="workflow-wrapper box"
          >
            <b-collapse
              animation="slide"
              :open="workflowOpenIndex == workflowIndex"
              :aria-id="'contentIdForA11y5-' + workflowIndex"
              @open="workflowOpenIndex = workflowIndex"
              @close="workflowOpenIndex = -1"
            >
              <template #trigger="props">
                <div class="level">
                  <div
                    class="level-left
                      ">
                    <p
                      class="card-header-title"
                    >{{ workflow.title ? workflow.title : 'Workflow ' + (workflowIndex + 1) }}</p>
                    <p />
                  </div>

                  <div class="level-right">

                    <b-button
                      class="workflow-actions"
                      icon-left="trash"
                      size="is-small"
                      style="margin-right: 0.5rem; margin-left: 0.5rem;"
                      @click.native.stop="onRemoveWorkflow(workflowIndex)"
                    />

                    <span class="card-header-title">
                      <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'" size="is-small" />
                    </span>

                    <b-icon
                      v-handle
                      icon="grip-vertical"
                      size="is-normal"
                      class="handle"
                      title="Drag to reorder"
                    />
                  </div>
                </div>
              </template>

              <div class="card-content">

                <b-field>
                  <template slot="label">
                    Workflow Title
                    <VTooltipIconHelp text="The name of the workflow" />
                  </template>
                  <b-input v-model="workflow.title" />
                </b-field>

                <b-field>
                  <template slot="label">
                    Workflow Description
                    <VTooltipIconHelp text="The description of the workflow" />
                  </template>
                  <b-input v-model="workflow.description" />
                </b-field>

                <b-field>
                  <template slot="label">
                    Workflow Categories
                    <VTooltipIconHelp text="The categories to which the workflow is assigned. If no category is selected, the workflow is available for all categories" />
                  </template>

                  <VInputMultiCategorySelection
                    v-model="workflow.categories"
                    :categories-doc="$categories"
                  />

                </b-field>

                <!-- iterate steps -->
                <div class="steps-container">
                  <span class="label">Steps</span>

                  <SlickList
                    v-model="workflow.steps"
                    lock-axis="y"
                    :use-drag-handle="true"
                    @sort-end="(data)=>onWorkflowStepOrderChanged(data, workflow.steps)"
                  >
                    <SlickItem
                      v-for="(step, stepIndex) in workflow.steps"
                      :key="stepIndex"
                      :index="stepIndex"
                      class="workflow-step-wrapper box"
                      :class="{
                        'show-with-previous-step': (step.showWithPreviousStep && stepIndex > 0),
                        'show-with-next-step': (workflow.steps[stepIndex + 1] && workflow.steps[stepIndex + 1].showWithPreviousStep)
                      }"
                    >
                      <b-collapse
                        animation="slide"
                        :open="workflowStepOpenIndex === stepIndex"
                        :aria-id="'contentIdForA11y5-' + stepIndex"
                        @open="workflowStepOpenIndex = stepIndex"
                        @close="workflowStepOpenIndex = -1"
                      >
                        <template #trigger="props">
                          <div class="level">
                            <div class="level-left">
                              <p
                                class="card-header-title"
                              >{{ step.title ? step.title : 'Step ' + (stepIndex + 1) }}</p>
                              <p>
                                <b-tag>
                                  {{ ACTION_NAME_MAP[step.action.type] }}
                                  <span
                                    v-if="step.action.value"
                                  >: {{ getHumanReadableActionValue(step.action.type, step.action.value) }}</span>
                                </b-tag>
                              </p>
                            </div>

                            <div class="level-right">

                              <!-- icon show with previous (level-up-alt) or unlink -->
                              <b-field class="step-actions" @click.native.stop>

                                <p class="control">
                                  <b-button
                                    v-if="stepIndex > 0"
                                    class="level-right"
                                    :icon-left="!step.showWithPreviousStep ? 'level-up-alt' : 'unlink'"
                                    size="is-small"
                                    style="margin-right: 0.5rem; margin-left: 0.5rem;"
                                    :title="step.showWithPreviousStep ? 'Unlink' : 'Show with previous step'"
                                    @click="workflow.steps[stepIndex].showWithPreviousStep = !workflow.steps[stepIndex].showWithPreviousStep"
                                  />
                                </p>

                                <!-- dropdown with options to add step below or above -->
                                <p class="control">
                                  <b-dropdown position="is-bottom-left">
                                    <template #trigger>
                                      <b-button
                                        title="Bulk actions"
                                        size="is-small"
                                        class="options-button"
                                        icon-left="ellipsis-h"
                                      />
                                    </template>

                                    <b-dropdown-item @click="onAddWorkflowStep(workflowIndex, stepIndex + 0)">Add step above</b-dropdown-item>
                                    <b-dropdown-item @click="onAddWorkflowStep(workflowIndex, stepIndex + 1)">Add step below</b-dropdown-item>

                                    <!-- delete -->
                                    <b-dropdown-item
                                      v-if="workflow.steps.length > 1"
                                      @click="onRemoveWorkflowStep(workflowIndex, stepIndex)"
                                    >Delete</b-dropdown-item>
                                  </b-dropdown>
                                </p>

                              </b-field>

                              <span class="card-header-title">
                                <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'" size="is-small" />
                              </span>

                              <b-icon
                                v-handle
                                icon="grip-vertical"
                                size="is-normal"
                                class="handle"
                                title="Drag to reorder"
                              />
                            </div>
                          </div>
                        </template>

                        <div class="card-content">

                          <!-- key to recompute the field and add the has-addons class -->
                          <b-field :key="step.action.type" grouped>
                            <b-field>
                              <template slot="label">
                                Step Action
                                <VTooltipIconHelp text="The action of the step" />
                              </template>
                              <b-select
                                v-model="step.action.type"
                                placeholder="Select action"
                                @input="step.action.value = ''"
                              >
                                <option value="workflowStepType_setCategory">set category</option>
                                <option value="workflowStepType_setAttribute">set attribute</option>
                                <option value="workflowStepType_setIdentifier">set identifier</option>
                              </b-select>
                            </b-field>

                            <b-field v-if="step.action.type === 'workflowStepType_setCategory'">
                              <template slot="label">
                                Select Category Entry Definition
                                <VTooltipIconHelp :text="(Object.entries(backendConfig.asid.categoryDefinitions).filter(([key, value]) => value.title !== '').length > 0) ? 'Select the Category Entry Definition to be used in this step' : 'No Category Entry Definitions available'" />
                              </template>
                              <b-select v-model="step.action.value" placeholder="Select category">
                                <option
                                  v-for="(categoryDefinition, catDefKey) in Object.fromEntries(Object.entries(backendConfig.asid.categoryDefinitions).filter(([key, value]) => value.title !== ''))"
                                  :key="catDefKey"
                                  :value="catDefKey"
                                >{{ `${categoryDefinition.title}` }}</option>
                              </b-select>
                            </b-field>


                            <b-field v-else-if="step.action.type === 'workflowStepType_setAttribute'">
                              <template slot="label">
                                Select Attribute
                                <VTooltipIconHelp text="Select the attribute to be used in this step" />
                              </template>
                              <b-select v-model="step.action.value" placeholder="Select Attribute">
                                <option
                                  v-for="(attrDefKey) in sortedAssetAttributeKeysLocal"
                                  :key="attrDefKey"
                                  :value="attrDefKey"
                                >{{ `${backendConfig.asid.assetAttributeDefinitions[attrDefKey].title} (${attrDefKey})` }}</option>
                              </b-select>
                              <!-- edit button -->
                              <p class="control">
                                <b-button
                                  v-if="backendConfig.asid.assetAttributeDefinitions[step.action.value]"
                                  icon-left="edit"
                                  title="Edit Attribute Definition"
                                  @click="openEditDefinitionModal('assetAttributeDefinitions', step.action.value)"
                                />
                              </p>

                              <!-- find not used attribute button -->
                              <p class="control">
                                <b-button
                                  icon-left="magic"
                                  title="Find not used Attribute"
                                  @click="setNotUsedAttribute(workflowIndex, stepIndex)"
                                />
                              </p>
                            </b-field>

                            <b-field v-else-if="step.action.type === 'workflowStepType_setIdentifier'">
                              <template slot="label">
                                Select Identifier
                                <VTooltipIconHelp text="Select the identifier to be used in this step" />
                              </template>
                              <b-select v-model="step.action.value" placeholder="Select Identifier">
                                <option
                                  v-for="(attrDefKey) in sortedIdentifierKeysLocal"
                                  :key="attrDefKey"
                                  :value="attrDefKey"
                                >{{ `${backendConfig.asid.identifierDefinition[attrDefKey].title} (${attrDefKey})` }}</option>
                              </b-select>
                              <!-- edit button -->
                              <p class="control">
                                <b-button
                                  v-if="backendConfig.asid.identifierDefinition[step.action.value]"
                                  icon-left="edit"
                                  title="Edit Identifier Definition"
                                  @click="openEditDefinitionModal('identifierDefinition', step.action.value)"
                                />
                              </p>
                              <!-- find not used identifier button -->
                              <p class="control">
                                <b-button
                                  icon-left="magic"
                                  title="Find not used Identifier"
                                  @click="setNotUsedIdentifier(workflowIndex, stepIndex)"
                                />
                              </p>
                            </b-field>

                            <b-field v-else>
                              <template slot="label">
                                step action value
                                <VTooltipIconHelp text="The value of the action" />
                              </template>
                              <b-input v-model="step.action.value" />
                            </b-field>
                          </b-field>


                          <b-field grouped>
                            <b-field expanded>
                              <template slot="label">
                                Step Title
                                <VTooltipIconHelp text="The title of the step" />
                              </template>
                              <b-input v-model="step.title" expanded />
                            </b-field>

                          <!-- <b-field>
                        <template slot="label">
                          show with previous step
                          <VTooltipIconHelp
                            text="If true, the step is shown together with the previous step"
                          />
                        </template>
                        <b-switch v-model="step.showWithPreviousStep" :rounded="false" />
                      </b-field> -->
                          </b-field>

                          <b-field>
                            <template slot="label">
                              Step Description
                              <VTooltipIconHelp text="The description of the step" />
                            </template>
                            <b-input v-model="step.description" />
                          </b-field>

                        <!-- <b-field>
                      <template slot="label">
                        step action mandatory
                        <VTooltipIconHelp text="If true, the action is mandatory" />
                      </template>
                      <b-switch v-model="step.action.mandatory" :rounded="false" />
                    </b-field> -->
                        </div>
                      </b-collapse>
                    </SlickItem>
                  </SlickList>

                  <!-- add step button -->
                  <b-field class="add-step-button">
                    <b-button expanded icon-left="plus" @click="onAddWorkflowStep(workflowIndex)">add step</b-button>
                  </b-field>
                </div>

              </div>

            </b-collapse>
          </SlickItem>
        </SlickList>


        <!-- add workflow button -->
        <b-field>
          <b-button expanded icon-left="plus" @click="onClickAddWorkflow">add workflow</b-button>
        </b-field>
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Activation</h3>
        <span class="setting-description">Configure the ECHO ID activation screen</span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Activation</h3>

        <b-field>
          <template slot="label">
            activation confirmation
            <VTooltipIconHelp
              text="shows an additional promt to confirm the activation of an ECHO Code to confirm the changes."
            />
          </template>
          <b-switch v-model="backendConfig.activation.confirmActivation" :rounded="false" />
        </b-field>

        <b-field>
          <template slot="label">
            update confirmation
            <VTooltipIconHelp
              text="shows an additional promt to confirm the update of an ECHO Code to confirm the changes."
            />
          </template>
          <b-switch v-model="backendConfig.activation.confirmUpdate" :rounded="false" />
        </b-field>
      </div>
    </div>

    <hr />

    <VCrudControl
      hide-remove
      save-button-text="Update"
      :is-saving="isLoading"
      @cancel="loadData"
      @save="$save"
    />

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'


import AsidManager from '@/database/asidManager'

import VRecordMeta from '@/components/VRecordMeta.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB, Workflow, WorkflowStep } from '@/types/typeBackendConfig'
import databaseSchema, { defaultCodeConfig } from '@/database/databaseSchema'
import VFormDataDefinition from '@/components/VFormDataDefinition.vue'
import VFormDataDefinitionModal from '@/components/VFormDataDefinitionModal.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryID } from '@/types/typeCategory'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VFormCategoryEntryDefinition from '@/components/VFormCategoryEntryDefinition.vue'
import VInputMultiCategoryEntry from '@/components/VInputMultiCategoryEntry.vue'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { SortingOrderHelper } from '@/helpers/sortingOrderHelper'
import { handlePromiseError } from '@/helpers/notificationHelper'
import { isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'

library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faChevronUp, faChevronDown)

@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VFormConfigureSticker,
    VFormDataDefinition,
    VInputMultiCategorySelection,
    VFormCategoryEntryDefinition,
    VInputMultiCategoryEntry
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendConfigAsid extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public testCatSelection: CategoryID[] = ['featureExt2Id', 'featureInt3Id']


  public AsidManager = AsidManager
  // public formSampleAsid = AsidManager.createID('a')


  // #region workflows
  /**
   * Workflows can be created to guide the user through the activation and assignement process
   * each workflow has a name and a description
   * a workflow consists of on or multiple steps
   * a workflow can be assigned to a category
   *  each step has a name and a description
   *  each step can have different actions:
   *  - set a category
   *  - set an attribute
   *  - set an identifier
   *
   * at the end of the workflow it is possible to change the categories to advance the process step
   */

  public workflowStepOpenIndex = -1
  public workflowOpenIndex = -1

  @Watch('workflowOpenIndex')
  public onWorkflowOpenIndexChange() {
    if (this.workflowOpenIndex === -1) {
      this.workflowStepOpenIndex = -1
    }
  }

  public get sortedWorkflows() {
  // return data sorted by order and by step order
    return this.workflows_db.sort((a, b) => a.order - b.order).map((workflow) => {
      workflow.steps = workflow.steps.sort((a, b) => a.order - b.order)
      return workflow
    })
  }

  get workflows_db(): Workflow[] {
    return this.backendConfig.activation.workflows
  }

  get sortedAssetAttributeKeysLocal(): isAssetAttributeKey[] {
    return Object.keys(this.backendConfig.asid.assetAttributeDefinitions)
      .sort((a, b) => {
        // sort first by order, then by key
        if (this.backendConfig.asid.assetAttributeDefinitions[a as isAssetAttributeKey].order !== this.backendConfig.asid.assetAttributeDefinitions[b as isAssetAttributeKey].order) {
          return this.backendConfig.asid.assetAttributeDefinitions[a as isAssetAttributeKey].order - this.backendConfig.asid.assetAttributeDefinitions[b as isAssetAttributeKey].order
        } else {
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        }
      }) as isAssetAttributeKey[]
  }

  get sortedIdentifierKeysLocal(): isIdentifierKey[] {
    return Object.keys(this.backendConfig.asid.identifierDefinition)
      .sort((a, b) => {
        // sort first by order, then by key
        if (this.backendConfig.asid.identifierDefinition[a as isIdentifierKey].order !== this.backendConfig.asid.identifierDefinition[b as isIdentifierKey].order) {
          return this.backendConfig.asid.identifierDefinition[a as isIdentifierKey].order - this.backendConfig.asid.identifierDefinition[b as isIdentifierKey].order
        } else {
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        }
      }) as isIdentifierKey[]
  }

  // public workflows_db: Workflow[] = [
  //   {
  //     title: 'ENTHALTENE KOMPONENTEN',
  //     description: 'Workflow for ENTHALTENE KOMPONENTEN',
  //     categories: [],
  //     order: 1,
  //     steps: [
  //       {
  //         title: 'ECU (5040.0003)',
  //         description: 'Processing ECU (5040.0003)',
  //         showWithPreviousStep: false,
  //         order: 2,
  //         stepId: 1000,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a2'
  //         }
  //       },
  //       {
  //         title: 'Compressor (6050.0002)',
  //         description: 'Processing Compressor (6050.0002)',
  //         showWithPreviousStep: false,
  //         order: 3,
  //         stepId: 1001,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a3'
  //         }
  //       },
  //       {
  //         title: 'Compressor (6050.0005)',
  //         description: 'Processing Compressor (6050.0005)',
  //         showWithPreviousStep: false,
  //         order: 4,
  //         stepId: 1002,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a4'
  //         }
  //       },
  //       {
  //         title: 'Compressor Battery (6050.0005)',
  //         description: 'Processing Compressor Battery (6050.0005)',
  //         showWithPreviousStep: false,
  //         order: 5,
  //         stepId: 1003,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a5'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'DICHTIGKEIT TESTS',
  //     description: 'Workflow for DICHTIGKEIT TESTS',
  //     categories: [],
  //     order: 2,
  //     steps: [
  //       {
  //         title: 'AC Druck Start-Test (bar)',
  //         description: 'Processing AC Druck Start-Test (bar)',
  //         showWithPreviousStep: false,
  //         order: 27,
  //         stepId: 1004,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a27'
  //         }
  //       },
  //       {
  //         title: 'AC Druck Ende-Test (bar)',
  //         description: 'Processing AC Druck Ende-Test (bar)',
  //         showWithPreviousStep: false,
  //         order: 28,
  //         stepId: 1005,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a28'
  //         }
  //       },
  //       {
  //         title: 'KM BL Druck Start-Test (bar)',
  //         description: 'Processing KM BL Druck Start-Test (bar)',
  //         showWithPreviousStep: false,
  //         order: 36,
  //         stepId: 1006,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a36'
  //         }
  //       },
  //       {
  //         title: 'KM BL Druck Ende-Test (bar)',
  //         description: 'Processing KM BL Druck Ende-Test (bar)',
  //         showWithPreviousStep: false,
  //         order: 37,
  //         stepId: 1007,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a37'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'COOLING SYSTEM TESTS',
  //     description: 'Workflow for COOLING SYSTEM TESTS',
  //     categories: [],
  //     order: 3,
  //     steps: [
  //       {
  //         title: 'Kühlkreislauf Batterie Dichtigkeit geprüft und OK',
  //         description: 'Processing Kühlkreislauf Batterie Dichtigkeit geprüft und OK',
  //         showWithPreviousStep: false,
  //         order: 38,
  //         stepId: 1008,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a38'
  //         }
  //       },
  //       {
  //         title: 'Heizkreislauf Dichtigkeit geprüft und OK',
  //         description: 'Processing Heizkreislauf Dichtigkeit geprüft und OK',
  //         showWithPreviousStep: false,
  //         order: 41,
  //         stepId: 1009,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a41'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'SOFTWARE',
  //     description: 'Workflow for SOFTWARE',
  //     categories: [],
  //     order: 4,
  //     steps: [
  //       {
  //         title: 'Software Revision',
  //         description: 'Processing Software Revision',
  //         showWithPreviousStep: false,
  //         order: 44,
  //         stepId: 1010,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a44'
  //         }
  //       },
  //       {
  //         title: 'Config Revision',
  //         description: 'Processing Config Revision',
  //         showWithPreviousStep: false,
  //         order: 45,
  //         stepId: 1011,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a45'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'HV TESTS',
  //     description: 'Workflow for HV TESTS',
  //     categories: [],
  //     order: 5,
  //     steps: [
  //       {
  //         title: 'ECU flashed',
  //         description: 'Processing ECU flashed',
  //         showWithPreviousStep: false,
  //         order: 46,
  //         stepId: 1012,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a46'
  //         }
  //       },
  //       {
  //         title: 'HV-TESTS',
  //         description: 'Processing HV-TESTS',
  //         showWithPreviousStep: false,
  //         order: 48,
  //         stepId: 1013,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a48'
  //         }
  //       },
  //       {
  //         title: 'Polarität Kompressor HV+/HV- ist korrekt',
  //         description: 'Processing Polarität Kompressor HV+/HV- ist korrekt',
  //         showWithPreviousStep: false,
  //         order: 49,
  //         stepId: 1014,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a49'
  //         }
  //       },
  //       {
  //         title: 'Polarität Heizer HV+/HV- ist korrekt',
  //         description: 'Processing Polarität Heizer HV+/HV- ist korrekt',
  //         showWithPreviousStep: false,
  //         order: 50,
  //         stepId: 1015,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a50'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'HVIL TESTS',
  //     description: 'Workflow for HVIL TESTS',
  //     categories: [],
  //     order: 6,
  //     steps: [
  //       {
  //         title: 'HVIL Kreis ist getestet und i.O.',
  //         description: 'Processing HVIL Kreis ist getestet und i.O.',
  //         showWithPreviousStep: false,
  //         order: 52,
  //         stepId: 1016,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a52'
  //         }
  //       },
  //       {
  //         title: 'PE Widerstand zwischen Massepunkt und ... ist <0,1Ohm',
  //         description: 'Processing PE Widerstand zwischen Massepunkt und ... ist <0,1Ohm',
  //         showWithPreviousStep: false,
  //         order: 53,
  //         stepId: 1017,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a53'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'OBERFLÄCHENTESTS',
  //     description: 'Workflow for OBERFLÄCHENTESTS',
  //     categories: [],
  //     order: 7,
  //     steps: [
  //       {
  //         title: '... Heizer Oberfläche',
  //         description: 'Processing Heizer Oberfläche',
  //         showWithPreviousStep: false,
  //         order: 54,
  //         stepId: 1018,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a54'
  //         }
  //       },
  //       {
  //         title: '... Kompressor Oberfläche',
  //         description: 'Processing Kompressor Oberfläche',
  //         showWithPreviousStep: false,
  //         order: 55,
  //         stepId: 1019,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a55'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'HV TESTS',
  //     description: 'Workflow for HV TESTS',
  //     categories: [],
  //     order: 8,
  //     steps: [
  //       {
  //         title: '... HV Test durchgeführt & OK',
  //         description: 'Processing HV Test durchgeführt & OK',
  //         showWithPreviousStep: false,
  //         order: 75,
  //         stepId: 1020,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a75'
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     title: 'FUNCTIONSTEST',
  //     description: 'Workflow for FUNCTIONSTEST',
  //     categories: [],
  //     order: 9,
  //     steps: [
  //       {
  //         title: 'Functionstest durchgeführt & OK',
  //         description: 'Processing Functionstest durchgeführt & OK',
  //         showWithPreviousStep: false,
  //         order: 78,
  //         stepId: 1021,
  //         action: {
  //           type: 'workflowStepType_setAttribute',
  //           value: 'a78'
  //         }
  //       }
  //     ]
  //   }

  // ]

  public async onWorkflowStepOrderChanged(
    { event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number },
    workflowSteps: WorkflowStep[]
  ) {
    SortingOrderHelper.manageOrder(
      workflowSteps,
      oldIndex,
      newIndex,
      async (el, order, currentIndex) => {
        console.log('updating', el, order, newIndex)

        // update the order of the element
        workflowSteps[currentIndex].order = order

        // if the next item to the currentIndex has the showWithPreviousStep flag, but the currentIndex does not, the next item should not have the showWithPreviousStep flag
        if (workflowSteps[currentIndex + 1] && workflowSteps[currentIndex + 1].showWithPreviousStep
          && !workflowSteps[currentIndex].showWithPreviousStep) {
          workflowSteps[currentIndex + 1].showWithPreviousStep = false
        }

        // if the element is moved above an element which is shown with the previous step, the element should be shown with the previous step
        if (newIndex > oldIndex) {
        // Moving DOWN: The item at `newIndex` has been shifted down
          workflowSteps[currentIndex].showWithPreviousStep = workflowSteps[newIndex + 1] && workflowSteps[newIndex + 1].showWithPreviousStep
        } else {
        // Moving UP: The item at `newIndex` has been replaced
          workflowSteps[currentIndex].showWithPreviousStep = workflowSteps[newIndex] && workflowSteps[newIndex].showWithPreviousStep
        }
      },
      (el) => el.order,
      (el, order) => el.order = order)
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
  }

  public onClickAddWorkflow() {
    const maximumOrder = this.sortedWorkflows.reduce((acc, workflow) => {
      return Math.max(acc, workflow.order)
    }, 0)
    this.workflows_db.push({
      title: 'New workflow',
      description: 'New workflow',
      order: maximumOrder + 1,
      categories: [],
      steps: [
        {
          title: '',
          description: '',
          showWithPreviousStep: false,
          order: 0,
          stepId: 0,
          action: {
            type: 'workflowStepType_setAttribute',
            // mandatory: true,
            value: ''
          }
        }
      ]
    })
  }

  public onRemoveWorkflow(workflowIndex: number) {
    this.workflows_db.splice(workflowIndex, 1)

    // adjust the expanded workflow index
    if (this.workflowOpenIndex === workflowIndex) {
      this.workflowOpenIndex = -1
    } else if (this.workflowOpenIndex > workflowIndex) {
      this.workflowOpenIndex--
    }
  }

  public onWorkflowOrderChanged(
    { event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number },
    workflows: Workflow[]
  ) {
    SortingOrderHelper.manageOrder(
      workflows,
      oldIndex,
      newIndex,
      async (el, order, currentIndex) => {
        console.log('updating', el, order, newIndex)

        // update the order of the element
        workflows[currentIndex].order = order
      },
      (el) => el.order,
      (el, order) => el.order = order)
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
  }


  public onAddWorkflowStep(workflowIndex: number, newStepIndex?: number) {
    // if no newStepIndex is provided, add the step at the end
    if (newStepIndex && newStepIndex < 0) newStepIndex = this.sortedWorkflows[workflowIndex].steps.length

    const workflow = this.sortedWorkflows[workflowIndex]

    // compute the highest step id to create a new step
    const hightestID = workflow.steps.reduce((acc, step) => {
      return Math.max(acc, step.stepId)
    }, 0)

    workflow.steps.push({
      title: '',
      description: '',
      showWithPreviousStep: false,
      order: workflow.steps.length,
      stepId: hightestID + 1,
      action: {
        type: 'workflowStepType_setAttribute',
        // mandatory: true,
        value: ''
      }
    })

    if (newStepIndex == undefined) newStepIndex = workflow.steps.length - 1

    // adjust the expanded workflow step index by keeping the open index open
    if (this.workflowStepOpenIndex >= newStepIndex) {
      this.workflowStepOpenIndex++
    }

    handlePromiseError(
      this.onWorkflowStepOrderChanged(
        { event: null, newIndex: newStepIndex, oldIndex: workflow.steps.length - 1 },
        workflow.steps
      )
    )
  }

  public onRemoveWorkflowStep(workflowIndex: number, stepIndex: number) {
    const workflow = this.sortedWorkflows[workflowIndex]
    workflow.steps.splice(stepIndex, 1)

    // adjust the expanded workflow step index
    if (this.workflowStepOpenIndex === stepIndex) {
      this.workflowStepOpenIndex = -1
    } else if (this.workflowStepOpenIndex > stepIndex) {
      this.workflowStepOpenIndex--
    }

    if (workflow.steps.length > 0)
      handlePromiseError(
        this.onWorkflowStepOrderChanged(
          // use index 0 just to trigger the update. index 0 always exists
          { event: null, newIndex: 0, oldIndex: 0 },
          workflow.steps
        )
      )
  }

  public ACTION_NAME_MAP = {
    workflowStepType_setCategory: 'Set category',
    workflowStepType_setAttribute: 'Set attribute',
    workflowStepType_setIdentifier: 'Set identifier',
    workflowStepType_dummy: 'Dummy'
  }

  public getHumanReadableActionValue(actionType: string, actionValue: string) {
    if (actionType === 'workflowStepType_setCategory') {
      return this.backendConfig.asid.categoryDefinitions[actionValue as keyof typeof this.backendConfig.asid.categoryDefinitions].title + ' (' + actionValue + ')'
    } else if (actionType === 'workflowStepType_setAttribute') {
      return this.backendConfig.asid.assetAttributeDefinitions[actionValue as keyof typeof this.backendConfig.asid.assetAttributeDefinitions].title + ' (' + actionValue + ')'
    } else if (actionType === 'workflowStepType_setIdentifier') {
      return this.backendConfig.asid.identifierDefinition[actionValue as keyof typeof this.backendConfig.asid.identifierDefinition].title + ' (' + actionValue + ')'
    }
  }

  /**
   * Set the action value to the first not used attribute
   * attribute is not used if it does not have any name set
   */
  public setNotUsedAttribute(workflowIndex: number, stepIndex: number) {
    this.setNotUsedActionValue(workflowIndex, stepIndex, 'assetAttributeDefinitions')
  }

  /**
   * Set the action value to the first not used identifier
   * identifier is not used if it does not have any name set
   */
  public setNotUsedIdentifier(workflowIndex: number, stepIndex: number) {
    this.setNotUsedActionValue(workflowIndex, stepIndex, 'identifierDefinition')
  }

  /**
   * Helper function to set the action value to the first not used item
   * item is not used if it does not have the specified property set
   */
  private setNotUsedActionValue(workflowIndex: number, stepIndex: number, definitionType: keyof BackendConfigDB['asid']) {
    const workflow = this.sortedWorkflows[workflowIndex]
    const step = workflow.steps[stepIndex]

    let notUsedItem = null

    if (definitionType === 'assetAttributeDefinitions') {
      notUsedItem = this.sortedAssetAttributeKeysLocal
        .find((key) => {
          return !this.backendConfig.asid.assetAttributeDefinitions[key].name
        })
    } else {
      notUsedItem = this.sortedIdentifierKeysLocal
        .find((key) => {
          return !this.backendConfig.asid.identifierDefinition[key].name
        })
    }

    if (!notUsedItem) {
      this.$helpers.notification.Error(`No not used ${definitionType.slice(0, -1)} found`)
      return
    }

    step.action.value = notUsedItem

    // open modal with component VFormDataDefinitionModal
    this.openEditDefinitionModal(definitionType, notUsedItem)
  }

  // open edit definition modal
  public openEditDefinitionModal(definitionType: keyof BackendConfigDB['asid'], key: string) {
    this.$buefy.modal.open({
      component: VFormDataDefinitionModal,
      customClass: 'data-definition-modal',
      props: {
        dataDefinitionObject: (this.backendConfig.asid[definitionType] as any),
        modalDataDefinitionKey: key,
        hideTypes: ['gps'],
        showCategoryVisibility: true,
        showIdentifierWarning: true
      },
      events: {
        update: (value: any) => {
          (this.backendConfig.asid[definitionType] as any)[key] = value[key]
        }
      }
    })
  }

  // #endregion workflows

  // public formPreviewWidth = false
  public formSelectedCodeConfigIndex = 0
  // public formSvgText = ''

  public backendConfig: BackendConfigDB = { ...BackendConfigManager.defaultDocDB }

  public get documentPath() {
    return BackendConfigManager.getDbDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__
  }

  // #region code config
  public onAddCodeConfig() {
    const hightestID = this.backendConfig.codes.reduce((acc, code) => {
      return Math.max(acc, parseInt(code.id))
    }, 0)
    this.backendConfig.codes.push({ ...defaultCodeConfig, id: String(hightestID + 1) })
  }

  public onRemoveCodeConfig() {
    if (this.backendConfig.codes.length === 1) return

    const removeIndex = this.formSelectedCodeConfigIndex
    this.backendConfig.codes.splice(removeIndex, 1)
  }

  @Watch('backendConfig.codes', { immediate: true })
  private onBackendConfigUpdate() {
    this.formSelectedCodeConfigIndex = this.backendConfig.codes.length - 1
  }

  // #endregion code config

  // @Watch('formSelectedCodeConfigIndex')
  // private async onSelectedTemplateChanged() {
  //   if (this.formSelectedCodeConfigID === 'Default') {
  //     this.formSvgText = ''
  //     return
  //   }

  //   // eslint-disable-next-line @typescript-eslint/no-var-requires
  //   const template = await require('!!raw-loader!@/assets/echoCodeTemplates/' + this.formSelectedCodeConfigID + '.svg')
  //   if (!template) {
  //     this.$helpers.notification.Error(`template ${this.formSelectedCodeConfigID} not found`)
  //   } else {
  //     this.formSvgText = template.default
  //   }
  // }


  // #region identifier

  public isLoading = false

  public async $save() {
    this.isLoading = true

    try {
      // try to save the identifier definition
      const identifierDefSaveError = (this.$refs['identifier-definition'] as VFormDataDefinition).$externalSave()
      if (identifierDefSaveError) {
        throw 'Error in identifier definition: ' + identifierDefSaveError
      }
      // try to save the asset attribute definition
      const assetAttributeDefSaveError = (this.$refs['asset-attribute-definition'] as VFormDataDefinition).$externalSave()
      if (assetAttributeDefSaveError) {
        throw 'Error in attribute definition: ' + assetAttributeDefSaveError
      }
      // try to save category definition
      const categoryDefSaveError = (this.$refs['category-definition'] as VFormCategoryEntryDefinition).$externalSave()
      if (categoryDefSaveError) {
        throw 'Error in category definition: ' + categoryDefSaveError
      }

      const backendConfigClone = cloneObject(this.backendConfig)

      await BackendConfigManager.update(this.$auth.tenant.id, this.$auth.userEmail, backendConfigClone)
      this.$helpers.notification.Success('identifierDefinitions updated & asset attibutes updated')
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
      return false
    } finally {
      this.isLoading = false
    }
  }


  // #endregion identifier


  public async loadData() {
    this.isLoading = true

    await this.$bindSnapshot('backendConfig', BackendConfigManager.getDbDocReference(this.$auth.tenant.id))

    this.isLoading = false
  }


  public async created() {
    await this.loadData()
  }
}
</script>

<style lang="scss">
.data-definition-modal {
  .modal-content {
    overflow: visible;

    .modal-card.data-definition-wrapper {
      overflow: visible;
    }
  }
}

.workflow-wrapper.box {
  padding: 0;
  margin-bottom: 1rem !important;

  .card-header-title {
    font-weight: normal;
  }

  .handle {
    cursor: row-resize;
    margin-right: 0.5rem;
  }

  .collapse .collapse-content {
    display: inherit;
    border-top: 1px solid #e1e1e1;
  }

  .steps-container {
    background: #eaeaea;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;

    .add-step-button {
      margin-top: 1rem;
    }
  }
}

// css at root level, to also apply while dragging
.workflow-step-wrapper.box {
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0 !important;

  .handle {
    cursor: row-resize;
    margin-right: 0.5rem;
  }

  .field.step-actions {
    margin-bottom: 0 !important;
  }

  &.show-with-previous-step {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.show-with-next-step {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.config-asid {
  .table-wrapper {
    overflow: visible;
  }
}

.identifier-field {
  margin-bottom: 1em;

  .handle {
    cursor: row-resize;
  }
}

.level.record-meta {
  margin-top: -1em;
}

.echo-code-container {
  max-width: 100%;
  margin: auto;
  width: 14em;
  padding: 1em 0;
}
</style>
