import Vue from 'vue'
import Router, { Route } from 'vue-router'

import Admin from '@/pages/admin/Admin.vue'
import AdminAsidList from '@/pages/admin/Admin_Asid_List.vue'
import AdminBatchList from '@/pages/admin/Admin_Batch_List.vue'
import AdminBatchSingle from '@/pages/admin/Admin_Batch_Single.vue'
import AdminTenantList from '@/pages/admin/Admin_Tenant_List.vue'
import AdminTenantSingle from '@/pages/admin/Admin_Tenant_Single.vue'
import AdminDashboard from '@/pages/admin/Admin_Dashboard.vue'
import AdminMockData from '@/pages/admin/Admin_MockData.vue'
import AdminTasks from '@/pages/admin/Admin_Tasks.vue'
import AdminSVGCodeEditor from '@/pages/admin/Admin_SVGCodeEditor.vue'
import AdminQRGenerator from '@/pages/admin/Admin_QRGenerator.vue'
import AdminNotification from '@/pages/admin/Admin_Notification.vue'
import AdminHelpCenter from '@/pages/admin/Admin_HelpCenter.vue'
import AdminUsersList from '@/pages/admin/Admin_Users_List.vue'
import AdminEmailsList from '@/pages/admin/Admin_Emails_List.vue'
import AdminESILGenerator from '@/pages/admin/Admin_ESILGenerator.vue'
import AdminTemplateCreator from '@/pages/admin/Admin_TemplateCreator.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'admin',
      component: Admin,
      // redirect: { name: 'dashboard' },
      meta: {
        title: 'Admin Area',
        label: 'admin',
        description: 'This is where the magic happens 🦄'
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: AdminDashboard,
          props: true,
          meta: {
            title: 'Dashboard',
            label: 'Dashboard',
            description: 'Dashboard Overview'
          }
        },
        {
          path: 'users-list',
          name: 'users-list',
          component: AdminUsersList,
          props: true,
          meta: {
            title: 'Users',
            label: 'Users',
            description: 'List users'
          }
        },
        {
          path: 'emails-list',
          name: 'emails-list',
          component: AdminEmailsList,
          props: true,
          meta: {
            title: 'Emails',
            label: 'Emails',
            description: 'List Emails'
          }
        },
        {
          path: 'asids',
          name: 'manage-asids',
          component: AdminAsidList,
          props: true,
          meta: {
            title: 'Asid',
            label: 'Asid',
            description: 'Manage Asids'
          }
        },
        {
          path: 'batches',
          name: 'manage-batches',
          component: AdminBatchList,
          props: true,
          meta: {
            title: 'Batch',
            label: 'Batch',
            description: 'Manage Batches'
          }
        },
        {
          path: 'batches/:id',
          name: 'batches-single',
          component: AdminBatchSingle,
          props: true,
          meta: {
            title: 'Batch',
            label: 'Batch',
            description: 'Manage Batches'
          }
        },
        {
          path: 'tenants',
          name: 'tenant-list',
          component: AdminTenantList,
          props: true,
          meta: {
            title: 'Tenants',
            label: 'Tenants',
            description: 'Manage Tenants'
          }
        },
        {
          path: 'tenants/:id',
          name: 'tenant-single',
          component: AdminTenantSingle,
          props: true,
          meta: {
            label: 'Tenant',
            description: 'Manage a Tenant'
          }
        },
        {
          path: 'mockdata',
          name: 'mockdata',
          component: AdminMockData,
          props: true,
          meta: {
            label: 'MockData',
            description: 'Create Sample Data'
          }
        },
        {
          path: 'tasks',
          name: 'tasks',
          component: AdminTasks,
          props: true,
          meta: {
            label: 'Tasks',
            description: 'Admin Tasks'
          }
        },
        {
          path: 'helpcenter',
          name: 'helpcenter',
          component: AdminHelpCenter,
          props: true,
          meta: {
            label: 'Help Center',
            description: 'Admin Help Center'
          }
        },
        {
          path: 'SVGCodeEditor',
          name: 'SVGCodeEditor',
          component: AdminSVGCodeEditor,
          props: true,
          meta: {
            label: 'SVGCodeEditor',
            description: 'Admin SVGCodeEditor'
          }
        },
        {
          path: 'qr-generator',
          name: 'qr-generator',
          component: AdminQRGenerator,
          props: true,
          meta: {
            label: 'QRGenerator',
            description: 'Admin QRGenerator'
          }
        },
        {
          path: 'esil-generator',
          name: 'esil-generator',
          component: AdminESILGenerator,
          props: true,
          meta: {
            label: 'ESIL Generator',
            description: 'Admin ECHO ScanInterface Language Generator'
          }
        },
        {
          path: 'template-creator',
          name: 'template-creator',
          component: AdminTemplateCreator,
          props: true,
          meta: {
            label: 'TemplateCreator',
            description: 'Admin Template Creator'
          }
        },
        {
          path: 'notification',
          name: 'notification',
          component: AdminNotification,
          props: true,
          meta: {
            label: 'Notification',
            description: 'Set Maintenance Notifications in Backend and Ppp'
          }
        }
      ]
    }

    // {
    //   path: '/feedback',
    //   name: 'feedback',
    //   // route level code-splitting
    //   // this generates a separate chunk (feedback.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "feedback" */ './views/Feedback.vue')
    // }
  ]
})

router.beforeEach((to: Route, from: Route, next: any) => {
  document.title = process.env.VUE_APP_TITLE_PREFIX + (to.meta.title || 'ECHO PRM Admin')
  next()
})

export default router
