var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('section',{ref:"scan-interface",staticClass:"scan-interface",class:{
      'is-active': _vm.isActivated
    },on:{"click":function($event){_vm.isActivated = true}}},[_c('div',{staticClass:"notification",class:{
        'is-info': _vm.isActivated
      }},[(_vm.message !== '')?_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.message))]):_vm._e(),(!_vm.isActivated)?_c('p',[_vm._v(" Click here to activate the scan interface "),_c('br'),_vm._v("The ECHO scan interface allow to quickly activate and assign ECHO CODEs by using a barcode or data matrix code scanner ")]):_c('p',[_c('span',{staticClass:"blob white"}),_vm._v(" Scan Interface is active. Scan a barcode or data matrix code to perform actions ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.scannerKeyboardTextInput),expression:"scannerKeyboardTextInput"}],ref:"input",staticClass:"scan-input",attrs:{"type":"text"},domProps:{"value":(_vm.scannerKeyboardTextInput)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();},"blur":function($event){_vm.isActivated = false},"input":function($event){if($event.target.composing)return;_vm.scannerKeyboardTextInput=$event.target.value}}}),(_vm.executionLog.length > 0)?_c('div',{staticClass:"action-log"},[_vm._v(" Action Log "),_vm._l((_vm.executionLog),function(executedCmd,i){return _c('div',{key:i,staticClass:"action-log-entry",class:{
            'is-info': executedCmd.type === 'info',
            'is-danger': executedCmd.type === 'error'
          }},[_c('b',[_vm._v(_vm._s(executedCmd.title))]),_vm._v(" : "+_vm._s(executedCmd.message)+" ")])})],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }